@import 'shared.scss';

/* QUESTIONBIPOLARSLIDER */
.questionBipolarSlider {

  // needs a bit more space than other questions (esp. on Xs) to not squish arrowUp button
  padding-top: $spaceXxxs;

  @include basicGrid;

  /* INTERACTION */

  /* MODIFIERS */
  &.closing {
    .sliderRoot, .title, .question, .controls, .helpButton, .hint {
      transform: translateY(-30px);
      opacity: 0;
      @include transition(transform, opacity);
    }
  }

  /* CHILDREN */

  .container {
    grid-column: 4/10;
    text-align: center;

    .questionContainer {
      .title {
        @include type('Label');
        color: $colorGrey1;
      }
      .questions {
        .hint {
          @include type('Hint');
          color: $colorGrey2;
          margin-top: $spaceS;
        }
      }
      .question {
        margin-top: $spaceM;
        @include type('S');
        color: $colorPrimary1;
      }
      button {
        margin-top: $spaceM;
      }

      .controls {

      }
      .helpButton {
        margin-top: space('M');
        button {
          display: block;
          margin: 0 auto;
        }
      }

      .hint {
        @include type('Hint');
        margin-top: space('M');
        .line {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin: 0 4px;
          }
          svg:last-child {
            margin: 0 4px 0 0;
          }
        }
      }

      .sliderRoot {
        margin-top: $spaceXxs;

        .options {
          display: flex;
          justify-content: space-between;
          .option {
            width: calc(50% - #{$spaceXs});
            @include type('Copy');
            color: $colorGrey1;
            text-align: left;
            &:last-child {
              text-align: right;
            }
            &.active {
              color: $colorPrimary1;
            }
          }
        }
      }
    }
  }

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
    }
  }

}
