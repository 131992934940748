@import 'shared.scss';

/* QUESTIONSEGMENTS */
.questionSegments {

  @include basicGrid;

  @include transition(transform, opacity);

  /* INTERACTION */

  /* MODIFIERS */
  &.closing {
    transform: translateY(-30px);
    opacity: 0;
  }

  /* RESPONSIVE */

  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      padding-top: 4px;

      .questionContainer {
        .question, .description {
          margin-top: 32px;
        }
      }
    }
  }

  /* CHILDREN */

  .container {
    grid-column: 4/10;
    text-align: center;
    padding-top: 35px;
    padding-bottom: 21px;

    .label {
      @include type('Label');
      color: $colorGrey1;
    }
    .questionContainer {
      .question {
        @include type('S');
        color: $colorPrimary1;
        margin-top: 48px;
      }
      .description {
        @include type('CopyStrong');
        color: $colorGrey1;
        margin-top: 48px;
      }
      .chooser {
        display: flex;
        margin: 32px auto 0;
        .cell {
          cursor: pointer;
          height: 40px;
          margin-right: 4px;

          background-color: $colorGrey4;

          @include transition(background-color);

          &:first-child {
            border-radius: 22px 0 0 22px;
          }
          &:last-child {
            margin-right: 0;
            border-radius: 0 22px 22px 0;
          }
        }
      }
      .labels {
        @include type('Copy');
        color: $colorGrey1;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
      }
    }
  }

}
