@import 'shared.scss';

$paginationButtonWidth: 40px;

/* Recommendations */
.recommendations {
  margin-top: space('L');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .recommendationsScrollableItems {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: space('Xs');

    // left ButtonCircular component
    & > div:first-child {
      flex-shrink: 0;
      position: absolute;
      left: -(#{$paginationButtonWidth + $spaceXs});
    }

    // right ButtonCircular component
    & > div:last-child {
      flex-shrink: 0;
      position: absolute;
      right: -(#{$paginationButtonWidth + $spaceXs});
    }

    //Scrollable
    & > div:nth-child(2) {
      width: 100%;
    }

    @include mqSmaller($bpM) {
      // left ButtonCircular component
      & > div:first-child {
        left: -(#{$paginationButtonWidth + $spaceXxs});
      }

      // right ButtonCircular component
      & > div:last-child {
        right: -(#{$paginationButtonWidth + $spaceXxs});
      }
    }

    @include mqSmaller($bpS) {
      //ButtonCircular components
      & > div:nth-child(1), & > div:nth-child(3) {
        display: none;
      }
    }
  }

  .title {
    @include type('Xxs');
    text-align: center;

    @include mqSmaller($bpS) {
      text-align: left;
    }
  }

  .recommendation {
    min-width: calc((100% - 2 * #{$spaceXs}) / 3);
    box-sizing: border-box;
    margin-right: space('Xs');
    padding: space('S') space('Xs');

    background-color: $colorPrimary4;
    border: 1px solid $colorGrey4;
    border-radius: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include mqSmaller($bpS) {
      min-width: 80%;
    }

    svg {
      width: 100%;
      height: auto;
    }

    .text {
      margin-top: space('Xs');
      white-space: pre-wrap;
      overflow: hidden;
    }
  }

}
