@import 'shared';

.uploadImage {
  margin-top: space('Xs');
  text-align: center;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    user-select: none;
  }
  .image, .noImage {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin: auto;
  }
  .noImage {
    @include type('S');
    line-height: 75px !important;
    color: $colorPrimary1;
    background-color: $colorPrimary3;
  }
  .upload {
    @include type('Copy');
    margin-top: space('Xs');
    color: $colorPrimary1;
    .validationMessage {
      @include type('Hint');
      color: $colorGrey2;
      margin-top: space('Xs');
    }
    svg {
      path {
        fill: $colorPrimary1;
      }
    }
    span {
      padding-left: space('Xxs');
    }
  }
}
