@import 'shared';

.resizableTextarea {
  position: relative;

  /* LABEL */
  .label {
    position: absolute;

    pointer-events: none;
    color: $colorGrey1;
    @include type('Copy');
    transform: translate(16px, 19px);

    // transition
    @include transition(transform, font-size);
    &.small {
      @include type('Copy');
      transform: translate(16px, 16px);
      + textarea {
        padding-top: 39px;
      }
    }
    &.disabled {
      color: $colorGrey2;
    }
  }

  .closeIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 14px;
    height: 14px;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    @include transition(background-color);
    &:hover {
      background-color: $colorPrimary3;
    }
  }

  textarea, .formattedBlock {
    width: 100%;
    border-radius: 2px;
    background-color: $colorGrey4;
    padding: 16px;
    box-sizing: border-box;
    outline: none;

    border: 1px $colorGrey4 solid;
    border-bottom-width: 1.5px;
    border-radius: 2px;

    @include type('CopyStrong');
    color: $colorGrey1;
    caret-color: $colorPrimary1;

    resize: none;

    overflow: auto;
    height: auto;

    // transitions
    @include transition(color, background-color, box-shadow, border, height);

    &:hover {
      box-shadow: $shadowDark;
      border-color: $colorWhite;
      background-color: $colorWhite;
    }
    &:focus {
      box-shadow: $shadowDark;
      border-bottom-color: $colorPrimary1;
      background-color: $colorWhite;
    }
    &:disabled {
      color: $colorGrey2;
      box-shadow: none;
      border-color: $colorGrey5;
      background-color: $colorGrey5;
    }
  }

  .formattedBlock {
    background: $colorWhite;
    border-color: $colorWhite;
  }

  .label + .formattedBlock {
    padding-top: 39px;
  }

  textarea:focus {
    + .hint {
      display: block;
    }
  }

  .hint {
    display: none;
    margin: space('Xxs') space('Xs') 0 space('Xs');

    @include type('Hint');
    color: $colorGrey2;
  }
}
