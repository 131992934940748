@import 'shared';

.resetPasswordForm {
  width: 100%;
  margin: 0 auto;

  .title {
    @include type('M');
    color: $colorGrey1;
  }

  .subTitle {
    @include type('CopyStrong');
    color: $colorGrey1;
    padding: 16px 0 24px 0;
  }

  .passwordInput {
    margin-top: 16px;
  }

  .button {
    position: relative;
    margin-left: auto;
    margin-right: 0px;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }

  @include mqSmaller($bpM) {
    .bottom {
      flex-direction: column-reverse;
      button {
        align-self: stretch;
      }
      a {
        padding-top: 24px;
      }
    }
  }

  @include mqSmaller($bpS) {
    .title, .subTitle {
      text-align: center;
    }
    .bottom {
      flex-direction: column-reverse;
      button {
        align-self: stretch;
      }
      a {
        padding-top: 24px;
      }
    }
  }
}
