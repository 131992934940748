@import 'shared.scss';

/* LOGO */
.logoInside {
  width: $logoWidth;
  height: $logoHeight;
  padding: $spaceXxs;

  overflow: hidden;

  img {
    display: block;
    height: 100%;
  }

  /* INTERACTION */

  /* MODIFIERS */
  &.sizeL {
    height: $logoHeightLarge;
    width: $logoWidthLarge;
  }
  &.compensatePadding {
    transform: translateX(-#{$spaceXxs});
  }
  &.link {
    cursor: pointer;
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
