@import 'shared.scss';

/* QUESTIONJOBFAMILY */
.questionJobFamily {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
