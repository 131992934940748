@import 'shared';

p {
  margin: 0;
}

.introduction {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: $colorWhite;
  .header {
    @include type('Label');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;
    }
  }
  .content {
    height: calc(100vh - 61px);
    overflow-y: auto;
  }
  .gridContent{
    @include basicGrid;
    margin-top: 96px;
    margin-bottom: space('L') !important;
    .image, .smallImage {
      grid-column: start/9;
      position: relative;
      //16:9 aspect ratio
      padding-top: 56.25%;
      overflow: hidden;
      animation: slide-up $animationDuration $animationFunction;

      @include mqSmaller($bpM) {
        grid-column: start/end;
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
      }

      img {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .smallImage {
      grid-column: start/6;

      @include mqSmaller($bpM) {
        grid-column: start/end;
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
      }
    }

    .text, .bigText {
      grid-column: 9/12;
      margin: auto 0;
      animation: slide-up $animationDuration $animationFunction;

      @include mqSmaller($bpM) {
        grid-column: start/end;
        margin-top: space('S');
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
        margin-top: space('S');
      }

      .title {
        @include type('S');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs')
      }
      button {
        margin-top: space('S');
      }
    }
    .bigText {
      grid-column: 6/12;

      @include mqSmaller($bpM) {
        grid-column: start/end;
        margin-top: space('S');
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
        margin-top: space('S');
      }
    }
  }

  @include mqSmaller($bpM) {
    .header {
      padding: 20px 16px;
    }
    .gridContent {
      margin-top: 0 !important;
      padding-bottom: space('L') !important;
    }
  }

  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .gridContent {
      margin-top: 0 !important;
      margin-bottom: space('Xxl') !important;
    }
  }
}
