@import 'shared.scss';

.wellBeingResult {
  width: 100%;
  color: $colorGrey1;

  .wellBeingIndexPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: space('S');

    @include mqSmaller($bpM) {
      button {
        display: none;
      }
    }
  }

  .wellBeingIndex {
    display: flex;
    .title {
      @include type('Copy');
    }
    .userCount {
      display: block;
      margin-top: space('Xxs');
      @include type('Hint');
    }
    .highlight {
      color: $colorPrimary1;
    }

    .indexContainer {
      display: flex;
      align-items: center;
    }
    .index {
      @include type('Label');
      color: $colorPrimary1;
      padding-left: 12px;
      white-space: nowrap;
    }

    .progress {
      margin-left: space('Xxs');
    }
  }

  .chart {
    .controls {
      display: flex;
      align-items: center;

      & > div {
        flex: 1;

        &:last-child {
          @include type('Copy');
          color: $colorGrey2;
          text-align: right;
          z-index: zIndex('Buffer1');
        }
      }
    }
  }

  .wellBeingModalPreHeader {
    svg {
      path {
        fill: $colorPrimary1;
      }
    }
  }

  .wellBeingModalHeader {
    .index {
      color: $colorPrimary1;
      margin-top: space('Xxs');
    }
  }

  .calloutBlock {
    margin-top: space('Xs');
    text-align: center;
  }

  .progressButton {
    display: none;
    margin-top: space('S');

    button {
      display: block;
      margin-left: auto;
    }
  }

  //COMPACT MODE
  &.compact {
    .wellBeingIndexPanel {
      button {
        display: none;
      }
    }

    .wellBeingIndex {
      width: 100%;
      .title {
        flex: 1;
      }
    }

    .progressButton {
      display: block;
    }
  }

  @include mqSmaller($bpM) {
    .wellBeingIndex {
      width: 100%;
    }

    .progressButton {
      display: block;
    }
  }
}
