@import 'shared.scss';

$introFooterHeight: 76px;

:export {
  introFooterHeight: $introFooterHeight;
}

/* ASSESSMENTPAGEWITHFOOTER */
.assessmentPageWithFooter {

  width: calc(100% + #{2 * $spaceM});

  // breakout
  margin-top: -(space('Xxl'));
  margin-left: -(space('M'));
  margin-right: -(space('M'));


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  @include mqSmaller($bpM) {
    width: calc(100% + #{2 * $spaceXs});
    // breakout
    margin-top: -(space('L'));
    margin-left: -(space('Xs'));
    margin-right: -(space('Xs'));
  }


  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8 !important;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end !important;
    }
  }

  /* CHILDREN */
  .scrollableContent {
    height: calc(100vh - #{$introFooterHeight} - #{$headerHeight});
    overflow-y: auto;
  }

  .grid {
    @include basicGrid;
    // add margin we removed during breakout
    margin-top: space('L') !important;

    // extra padding for footer for iOS 100vh problem
    padding-bottom: calc((#{$introFooterHeight} / 2) + #{$introFooterHeight});
  }

  .container {
    grid-column: 4/10;

    .children {
      position: relative;
    }

    .copyright {
      margin-top: space('L');
      text-align: center;

      @include mqSmaller($bpS) {
        margin-top: space('M');
      }

      span {
        @include type('Hint');
        color: $colorPrimary1;
        cursor: pointer;
        @include transition(color);
        &:hover {
          color: $colorPrimary2;
        }
      }
    }
  }

  // FOOTER
  .footer {
    width: 100%;
    height: $introFooterHeight;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;

    @include type('Label');
    color: $colorPrimary1;
    border-top: 1px solid $colorGrey4;
    background-color: $colorWhite;

    .footerGrid {
      @include basicGrid;
      height: 100%;
    }
    .footerContent {
      grid-column: 4/10;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > button {
        margin-left: auto;
      }

      @include mqSmaller($bpM) {
        grid-column: 2/8;
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
      }
    }
  }

  // MODAL
  .modal {
    position: absolute;
    top: 0;
    margin-top: -$headerHeight;
    width: 100%;
    height: calc(100% + #{$headerHeight});
  }
}
