@import 'shared';

.loginForm {
  width: 100%;
  margin: 0 auto;
  .title {
    @include type('M');
    color: $colorGrey1;
  }
  .subTitle {
    @include type('CopyStrong');
    color: $colorGrey1;
    padding: 16px 0 24px 0;
  }
  .passwordInput {
    margin-top: 16px;
  }
  .button {
    position: relative;
    margin-left: auto;
    margin-right: 0px;
  }
  .bottom {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-between;
    button {
      flex-grow: 1;
      margin-top: 24px;
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 8px;
      margin-right: $spaceS;
      a {
        margin-top: 16px;
      }
      a:nth-child(1) {
        padding-right: 24px;
      }
    }
  }

  @include mqSmaller($bpM) {
    .bottom button {
      width: 100%;
    }
  }

  @include mqSmaller($bpS) {
    .title, .subTitle {
      text-align: center;
    }
    .bottom {
      flex-direction: column-reverse;
      button {
        width: 100%;
        align-self: stretch;
      }
      .links {
        flex-direction: column;
        text-align: center;
        a {
          display: block;
          &:nth-child(1) {
            padding: 0;
          }
        }
      }
    }
  }
}

