@import 'shared.scss';

$minHeight: 750px;

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
}
.exit {
  opacity: 1 !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
}

/* FILTERLIST */
.filterList {
  width: 100%;
}


/* EMPTY */
.emptyTitleImage {
  width: 100%;
  height: auto;
  @include mqSmaller($bpS) {
    width: auto;
    height: 40vh;
    object-fit: cover;
    object-position: 50% 20%;
  }
}
.emptyContent {
  @include basicGridNoMargins;
  box-sizing: border-box;
  padding-bottom: space('M');

  > div {
    grid-column: 1/9;
  }

  .emptyText {
    margin-top: space('Xxs');
    @include type('CopyStrong');
    color: $colorGrey1;
  }

  button {
    margin-top: space('S');
  }
}

@include mqSmaller($bpL) {
  .emptyContent > div {
    grid-column: 3/11;
  }
}
@include mqSmaller($bpM) {
  .emptyContent > div {
    grid-column: 2/8;
  }
}
@include mqSmaller($bpS) {
  .emptyContent > div {
    grid-column: start/end;
  }
}




/* EMPLOYEES */
.titleText {
  grid-column: start/end;
  @include type('Xxs');
}

.xxsTitle {
  @include type('Xxs');
}

.content {
  @include basicGridNoMargins;
}

/* CONTROLS */
.controls {
  grid-column: start/end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: space('Xs');

  z-index: 1;

  @include animationLong(slide-up);
  animation-fill-mode: forwards;

  > div[class*=Input] {
    width: 372px;
  }

  > div:nth-child(2) { // button
    justify-content: flex-end;
  }
  button {
    margin-top: space('Xxxs');
    margin-left: space('Xs');
  }

  > div:last-child { // DropDownSubtle
    justify-content: flex-end;
    flex: 1;
    z-index:2;
  }

  .toggleContainer {
    width: 100%;
    margin-bottom: space('S');
    & > div {
      width: 288px;
    }
  }
}

@include mqLargerEqual($bpM) {
  // correct flyout position
  .controls > div:last-child > div[class*=flyout] {
    margin-top:50px;
  }
}

@include mqSmaller($bpM) {
  .controls:not(.withToggle) {
    flex-wrap: wrap;
    > div:first-child {
      width: auto;
      flex-basis: 50%;
    }
    .secondaryButton {
      flex-basis: 100%;
      text-align: right;
      margin-top: space('S');
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      flex-basis: 50%;
    }
    > div:last-child {
      display: flex;
      justify-content: flex-end;
      margin-top: space('S');
      flex-basis: 100%;
    }
  }
}

@include mqSmaller($bpS) {
  .controls:not(.withToggle) {
    > div:first-child {
      flex-basis: 100%;
    }
    > div:nth-child(2) {
      margin-top: space('S');
      flex-basis: 100%;
    }
    .secondaryButton {
      flex-basis: 100%;
      text-align: right;
      margin-top: space('S');
    }
  }

  .controls.withToggle {
    & > div {
      flex-basis: 100%;
    }
    & > div:last-child {
      margin-top: space('S');
    }
  }
}


/* LOADING */
.loading {
  display: flex;
  grid-column: start/end;
  align-items: center;
  justify-content: center;

  @include transition(opacity);
}

.listContainer {
  grid-column: start/end;
  margin-top: space('S');
  padding-inline-start: 0;

  @include animationLonger(slide-up);
  animation-fill-mode: forwards;
}

/* SKELETON */
.skeleton {
  animation: load-assessment-result 1.5s infinite ease;

  svg {
    display:block;
    width: 100%;
    height: $minHeight;
  }
}


/* LIST */
.list {
  &.hasFullHeight {
    min-height: $minHeight;
  }
}

.listItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  position:relative;
  padding: 14px;  // 16px - 2px compensate overflow: hidden; text cutoff + status circle cutoff
  margin-top: -1px; // allow full border on every item
  height: 74px;
  box-sizing: border-box;
  border: 1px solid $colorGrey4;
  background-color: $colorWhite;

  // transition for hover effect
  @include transition(background-color, border-color);

  //DEACTIVATED STATE
  &.deactivated {
    .colImage > div {
      opacity: 50%;
    }
    .colName > span,
    .colLevel > span,
    .colSince > span,
    .colStatus > span {
      color: $colorGrey2 !important;
    }
  }

  //STATE FOR INVITED EMPLOYEES
  &.invited {
    cursor: default;
    .colArrow {
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      background-color: $colorWhite;
      border-color: $colorGrey4;
    }
  }

  &.notClickable {
    cursor: default;
    .colArrow {
      opacity: 0;
      pointer-events: none;
    }
  }

  /* HOVER */
  &:hover, .active {
    border-color: $colorPrimary3;
    background-color: $colorPrimary4;
    z-index: 1; // make full border visible

    .colArrow svg path {
      fill: $colorPrimary1 !important;
    }

    .colStatus, .colTotal {
      span:nth-child(2) > div > div:last-child { // hover color for ProgressCircle innerColor
        background-color: $colorPrimary4;
      }
    }
  }

  /* ROUNDED CORNERS */
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  /* columns */
  [class*=col] {
    flex-grow: 0; // distribute remaining space
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 1px; // compensate overflow: hidden; text cutoff
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .colImage {
    display: flex;
    flex-grow: 0; // static width
    flex-shrink: 0;
    flex-basis: 46px;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .colName {
    flex-basis: 30%;
    flex-grow: 1;
    overflow: hidden;
    padding-right: space('Xs');
  }
  .colLevel {
    flex-basis: calc(25% - 46px);
    flex-grow: 1;
    padding-right: space('Xs');
  }
  .colSince {
    flex-basis: 25%;
    padding-right: space('Xs');
  }
  .colStatus {
    flex-basis: calc(20% - 13px);
    padding-right: space('Xs');
  }
  .colArrow {
    display: flex;
    flex-grow: 0;
    flex-basis: 12px;
    padding-right: 1px;
    justify-content: center;
    align-items: center;

    svg {
      height: 12px;
    }
  }
}

/* LIST MODIFIERS */
.list.roleStaffing {

  // COLUMN MODIFICATIONS
  [class*=col]:not(.colName):not(.colImage):not(.colTotal):not(.colArrow) {
    display: block !important; // don't hide for responsive ever
    position: relative; // for tooltip positioning.
    overflow: visible;

    flex-grow: 0;
    flex-basis: 36px + space('L');
  }
  .colName {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .colAdditional {
    display: block; // just need any property so it's actually assigned to the div
  }
  .colTotal {
    position: relative; // for tooltip positioning.
    overflow: visible;
    margin-top: 2px; // fix alignment

    flex-grow: 0;
    flex-basis: 60px + space('L') !important;
    width: 60px + space('L') !important; // needed for child's ellipsis not to extend over flex-basis
  }

  // RESPONSIVE
  @include mqSmaller($bpXl) {
    [class*=col]:not(.colName):not(.colImage):not(.colTotal):not(.colArrow) {
      flex-basis: 36px + space('M') !important;
    }
    .colTotal {
      flex-basis: 60px + space('M') !important;
      width: 60px + space('M') !important; // needed for child's ellipsis not to extend over flex-basis
    }
  }
  @include mqSmaller($bpM) {
    [class*=col]:not(.colName):not(.colImage):not(.colTotal):not(.colArrow) {
      flex-basis: 36px + space('S') !important;
    }
    .colTotal {
      flex-basis: 60px + space('S') !important;
      width: 60px + space('S') !important; // needed for child's ellipsis not to extend over flex-basis
    }
  }
  @include mqSmaller($bpS) {
    .listItem {
      flex-wrap: wrap;
      height: 130px;
    }
    .colImage {
      padding-bottom: 16px;
      order: 1;
    }
    .colName {
      padding-bottom: 16px;
      flex-basis: calc(100% - 46px - 13px); // - image & arrow
      flex-shrink: 0;
      order: 2;
    }
    [class*=col]:not(.colName):not(.colImage):not(.colTotal):not(.colArrow) {
      flex-basis: 36px + space('Xs') !important;
    }
    .colArrow {
      padding-bottom: 16px;
      order: 3;
    }
    .colLevel {
      order: 4;
      flex-grow: 1 !important;
    }
    .colSince {
      order: 5;
      flex-grow: 1 !important;
    }
    .colStatus {
      order: 6;
      flex-grow: 1 !important;
    }
    .colAdditional {
      order: 7;
      flex-grow: 1 !important;
    }
    .colTotal {
      order: 8;
      flex-grow: 1 !important;
    }
    .colTotal {
      flex-basis: 45px !important;
      width: 45px !important; // needed for child's ellipsis not to extend over flex-basis
    }
  }
}

/* PAGINATION */
.pagination {
  margin: space('M') 0;
  grid-column: start/end;

  @include transition(opacity);
}

/* EMPTY LIST */
.emptyListResult {
  margin-top: space('M');
  grid-column: start/end;

  .headline {
    @include type('Xxs');
  }
  .text {
    margin-top: space('Xs');
    @include type('CopyStrong');
    color: $colorGrey1;
  }
}

  /* RESPONSIVE */
@include mqSmaller($bpM) {
  .listItem {
    .colTime {
      display: none;
    }
    .colName {
      flex-basis: 37.5%;
    }
    .colCareer {
      flex-basis: calc(32.5% - 46px);
    }
    .colStatus {
      flex-basis: calc(30% - 13px);
    }
  }
}
@include mqSmaller($bpM) {
  .listItem {
    .colCareer {
      display: none;
    }
    .colSince {
      display: none;
    }
    .colName {
      flex-basis: 35%;
    }
    .colStatus {
      flex-basis: calc(35% - 13px - 46px);
    }
  }
}
@include mqSmaller($bpS) {
  .listItem {
    .colCareer, .colSince, .colLevel {
      display: none;
    }
    .colName {
      flex: 1;
    }
    .colStatus {
      flex-basis: 25px;
      padding-right: 8px;
      > span:last-child {
        width: 100%;
        margin-top: 0;
      }
    }

    &.mobileFullMode {
      flex-wrap: wrap;
      height: auto;
      .colName {
        min-width: 100%;
      }
      .colCareer, .colSince, .colLevel, .colStatus {
        display: block;
        flex: 1;
        margin-top: space('Xs');
      }
      .colArrow {
        position: absolute;
        right: 14px;
        top: space('M');
      }
    }
  }
}
