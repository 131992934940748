@import 'shared.scss';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
}
.exitActive, .exitDone {
  display: none;
}

/* COLLAPSIBLENEXT */
.collapsibleNext {

  user-select: none;
  background-color: $colorWhite;


  // MODIFIERS
  // deprecated
  // &.hasBoldHeader > .header > .headers > .headerNormal {
  //   @include type('Label');
  // }
  &.isExpanded {
    & > .header {
      padding-bottom: space('S'); // extra padding to bottom border when opened
      .arrow svg {
        transform: scale(-1);
        path {
          fill: $colorPrimary1;
        }
      }
    }
    & > .header:hover > .headers {
      .headerNormal, .headerSubtle, .headerHighlight {
        color: $colorPrimary2 !important;
      }
      .arrow svg path {
        fill: $colorPrimary2 !important;
      }
    }
  }

  // Border Handling
  // allow borders on both root element and .header
  &, & > .header {
    border: 1px solid $colorGrey3;
    border-width: 0px;
  }
  // initially only set bottom border on header
  & > .header {
    border-bottom-width: 1px;
  }
  // show top border only for .showTopBorder or first-child
  &.showTopBorder > .header,
  &:first-child > .header {
    border-top-width: 1px;
  }

  //show borders from all side
  &.withBorders {
    & > .header {
      border: 1px solid $colorGrey4;
      border-radius: 10px;
    }

    &.isExpanded {
      border: 1px solid $colorGrey4;
      border-radius: 10px;

      & > .header {
        border: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      & > .content {
        border: none;
      }
    }
  }

  // when expanded set bottom border on root element instead of header
  &.isExpanded {
    border-bottom-width: 1px;
    > .header {
      border-bottom-width: 0px;
    }
  }

  // .isExpandable
  &:not(.isExpandable) {
    > .header { // remove arrow
      padding-right: space('Xs');
    }
  }

  // HOVERS
  &.isHovered > .header,
  &.isExpandable > .header:hover {
    cursor: pointer;
    // change border color on .isHovered and :hover
    border-color: $colorPrimary3;

    background-color: $colorPrimary4;

    .headers {
      > .headerNormal, > .headerSubtle, > .headerHighlight {
        color: $colorPrimary1 !important;
      }
      svg path {
        fill: $colorPrimary1;
      }
    }
  }

  //large size
  &.sizeL {
    & > .header {
      padding: space('M');

      @include mqSmaller($bpM) {
        padding: space('S') space('Xs');
      }
    }

    & > .content {
      padding: 0 space('M') space('M');

      @include mqSmaller($bpM) {
        padding: 0 space('Xs') space('S');
      }
    }

    &.isExpanded {
      & > .header {
        padding-bottom: space('S');
      }
    }
  }

  // when there's no top border, show top border and move up 1px on .isHovered and :hover (so we can color surrounding borders on hover even if there's not top border
  &:not(.showTopBorder):not(.withBorders):not(:first-child) {
    &.isHovered > .header, & > .header:hover {
      border-top-width: 1px;
      margin-top: -1px;
    }
  }

  // CHILDREN

  > .header {
    position: relative;
    padding: space('Xs');


    @include transition(color, background-color);

    > .label {
      color: $colorGrey2;
      padding-top: 0;
      padding-bottom: space('Xxs');
    }

    > .headers {
      display: flex;

      > .headerSubtle {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 7px;
        padding-right: space('Xs');
        color: $colorGrey2;
      }

      > .headerHighlight {
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: space('Xs');
        color: $colorPrimary1;
      }

      > .headerNormal {
        flex-grow: 1;
        flex-shrink: 1;
        // ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .headerRight {
        flex-grow: 0;
        flex-shrink: 0;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        // provide larger click area
        margin: - space('Xs');
        margin-left: - space('Xxs');
        padding: space('Xs');

        color: $colorPrimary1;
        text-align: right;

        z-index: 1;

        // info icon
        &.hasInfoIcon {
          cursor: pointer;

          .infoIcon {
            margin-left: space('Xxs');
            display: flex;
            align-items: center;

            svg {
              display: block;
            }
          }

        }

        &:hover {

          .label {
            color: $colorPrimary1;
          }

          .infoIcon svg path {
            fill: $colorPrimary2;
          }
        }
      }

      > .arrow {
        display: flex;
        align-items: center;
        padding-left: space('Xs');

        svg {
          display: block;
          @include transition(transform);

          path {
            @include transition(fill);
          }
        }
      }
    }
  }

  > .content {
    padding: space('S');
    padding-top: 0;
    padding-right: space('M');
  }

}
