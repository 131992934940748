@import 'shared.scss';

/* QUESTIONSLIDER */
.questionSlider {

  @include basicGrid;

  @include transitionLong(transform, opacity);

  /* INTERACTION */

  /* MODIFIERS */
  &.closing {
    transform: translateY(-30px);
    opacity: 0;
  }

  /* RESPONSIVE */

  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8 !important;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end !important;
      padding-top: 4px;
    }
  }

  /* CHILDREN */
  .container {
    grid-column: 4/10;
    text-align: center;

    .questionContainer {
      .question {
        @include type('S');
      }
      .sliderBlock {
        width: 100%;
        margin: 32px auto 0;
        text-align: left;
        .labels {
          display: flex;
          justify-content: space-between;
          @include type('Copy')
        }

        @include mqSmaller($bpS) {
          margin-top: space('S');
        }
      }
      button {
        display: block;
        margin: space('M') auto 0;
      }
    }
  }

}
