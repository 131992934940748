

// The z-index increases from top to bottom
$zLayers: (
  'Buffer1',
  'Buffer2',
  'Buffer3',
  'BelowHeader',
  'HomeHeader',
  'MainNavigation',
  'Standalone',
  'FullscreenOverlay',  // like DialogSequence, AssessmentsNext
                        // and other full screen overlays that might have modals inside
  'Modal',
  'Loading'
);


@function zIndex($layerName) {
  @return z($layerName);
}


@function z($layerName, $searchIn: $zLayers, $layer: null) {

  @for $i from 1 through length($searchIn) {
    $currentElement: nth($searchIn, $i);

    @if ($currentElement == $layerName) {
      @if ($layer == null) {
        $layer: index($searchIn, $layerName);
      }
      @return $layer;
    }
  }

  @if ($searchIn == $zLayers) {
    @error "No z-index found for #{$layerName}. Property omitted.";
  }

  @return null;
}
