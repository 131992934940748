@import 'shared.scss';

.callout {
  .triangleOnTop {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid $colorPrimary4;
    margin: 0 auto;
    &.left {
      margin-left: 20px;
    }
    &.right {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .triangleOnBottom {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 11px solid $colorPrimary4;
    margin: 0 auto;
    &.left {
      margin-left: 20px;
    }
    &.right {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .text {
    @include type('Copy');
    color: $colorGrey1;
    background-color: $colorPrimary4;
    padding: 10px 16px;
    border-radius: 10px;
  }
}
