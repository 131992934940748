@import "shared";

.resetPasswordSuccessForm {
  width: 100%;
  color: $colorGrey1;
  display: flex;
  flex-direction: column;
  @include type('M');
  .paragraph {
    @include type('CopyStrong');
    padding: 24px 0 24px 0;
  }
  button {
    align-self: flex-end;
  }

  @include mqSmaller($bpM) {
    button {
      align-self: stretch;
    }
  }
  @include mqSmaller($bpS) {
    text-align: center;
    button {
      align-self: stretch;
    }
  }
}
