@import 'shared.scss';

/* MAINTENANCE */
.maintenance {
  @include basicGrid;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .container {
    margin-top: 176px;
    grid-column: 5/9;
    display: flex;
    flex-direction: column;
    gap: 24px;
    animation: loginSlideIn .300s ease-out;

    .coffee {
      font-size: 34px;
    }

    .title {
      @include type('M');
    }

    .button {
      align-self: flex-end;
    }
  }

  @include mqSmaller($bpM) {
    .container {
      grid-column: 3/7;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      margin-top: 126px;
    }
  }
}

@keyframes loginSlideIn {
  0% {
    margin-top: 150px;
    opacity: 0;
  }
  100% {
    margin-top: 176px;
    opacity: 1;
  }
}
