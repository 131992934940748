@import 'shared';

.textarea {
  position: relative;

  /* LABEL */
  .label {
    position: absolute;

    pointer-events: none;
    color: $colorGrey1;
    @include type('Copy');
    transform: translate(16px, 16px);

    // transition
    @include transition(transform, font-size);
    &.small {
      @include type('Hint');
      transform: translate(16px, 3px);
      + textarea {
        padding-top: space('S');
      }
    }
    &.disabled {
      color: $colorGrey2;
    }
  }


  textarea {
    width: 100%;
    height: 150px;
    border-radius: 2px;
    background-color: $colorGrey4;
    padding: 16px;
    box-sizing: border-box;
    outline: none;

    border: 1px $colorGrey4 solid;
    border-bottom-width: 1.5px;
    border-radius: 2px;

    @include type('Copy');
    color: $colorGrey1;
    caret-color: $colorPrimary1;

    resize: none;

    // transitions
    @include transition(color, background-color, box-shadow, border-color);

    &:hover {
      box-shadow: $shadowDark;
      border-color: $colorWhite;
      background-color: $colorWhite;
    }
    &:focus {
      box-shadow: $shadowDark;
      border-bottom-color: $colorPrimary1;
      background-color: $colorWhite;
    }
    &:disabled {
      color: $colorGrey2;
      box-shadow: none;
      border-color: $colorGrey5;
      background-color: $colorGrey5;
    }
  }

  .languages {
    position: absolute;
    right: 16px;
    top: 15px;
    @include type('Copy');
    > span:first-child {
      padding-right: 8px;
      border-right: 1px solid $colorGrey3;
    }
    > span:last-child {
      padding-left: 8px;
    }
    span:not(.active) {
      cursor: pointer;
      @include transition(color);
      &:hover {
        color: $colorGrey2;
      }
    }
    span.active {
      color: $colorPrimary1;
      pointer-events: none;
    }
  }

  .hint {
    @include type('Hint');
    color: $colorGrey2;
    margin: space('Xxs') space('Xs') 0 space('Xs');
  }
}
