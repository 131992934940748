@import 'shared.scss';

.skeletonContainer {
  border: 1px solid $colorGrey4;
  border-radius: 10px;
  padding: 22px 32px;
  box-sizing: border-box;
  grid-column: 5/13;
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-column-gap: 17px;
  .left, .right {
    width: 100%;
    height: 383px;
    grid-column: 1/5;
    box-sizing: border-box;
    animation: load-assessment-result 1.5s infinite ease;
    .header {
      height: 34px;
      width: 100%;
      box-sizing: border-box;
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    .subItems {
      margin-top: 46px;
      .subItem {
        height: 14px;
        width: 100%;
        box-sizing: border-box;
        background-color: $colorGrey4;
        border-radius: 12px;
        margin-top: 25px;
      }
    }
  }
  .right {
    grid-column: 5/7;
    .subItems {
      margin-top: 80px;
    }
  }
  @include mqSmaller($bpM) {
    height: 616px;
    padding: 22px 16px 26px 16px;
    grid-column: 5/9;
    grid-template-columns: repeat(4, auto);
    .left {
      grid-column: 1/5;
      .header {
        height: 24px;
      }
      .subItems {
        margin-top: 32px;
        .subItemGroup {
          margin-top: 32px;
          .subItem1, .subItem2 {
            height: 14px;
            width: 70%;
            box-sizing: border-box;
            background-color: $colorGrey4;
            border-radius: 12px;
          }
          .subItem2 {
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    height: 616px;
    padding: 22px 16px 26px 16px;
    grid-column: start/end;
    grid-template-columns: repeat(4, auto);
    .left {
      grid-column: start/end;
      .header {
        height: 24px;
      }
      .subItems {
        margin-top: 32px;
        .subItemGroup {
          margin-top: 32px;
          .subItem1, .subItem2 {
            height: 14px;
            width: 70%;
            box-sizing: border-box;
            background-color: $colorGrey4;
            border-radius: 12px;
          }
          .subItem2 {
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
