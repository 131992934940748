@import "responsive";

/**
  - Montserrat
    - Regular 400
    - Semibold 600
    - Link https://fonts.google.com/specimen/Montserrat?selection.family=Montserrat:400,600
**/

$types: (
  "Xxl",
  "Xl",
  "L",
  "M",
  "S",
  "Xs",
  "Xxs",
  "CopyStrong",
  "Copy",
  "CopySecondary",
  "Hint",
  "Label",
  "LabelDiagram",
  "ButtonL",
  "ButtonM",
  "ButtonS"
);

$family: "Montserrat", "DejaVu Sans", Verdana, -apple-system, sans-serif;

// Xxl
$typeFamilyXxl: $family;
$typeWeightXxl: 600;
$typeSizeXxl: 80px;
$typeLineHeightXxl: 88px;
$typeSizeXxlSmaller: 50px;
$typeLineHeightXxlSmaller: 58px;

// Xl
$typeFamilyXl: $family;
$typeWeightXl: 600;
$typeSizeXl: 60px;
$typeLineHeightXl: 68px;
$typeSizeXlSmaller: 40px;
$typeLineHeightXlSmaller: 48px;

// L
$typeFamilyL: $family;
$typeWeightL: 600;
$typeSizeL: 48px;
$typeLineHeightL: 60px;
$typeSizeLSmaller: 32px;
$typeLineHeightLSmaller: 40px;

// M
$typeFamilyM: $family;
$typeWeightM: 600;
$typeSizeM: 34px;
$typeLineHeightM: 44px;
$typeSizeMSmaller: 26px;
$typeLineHeightMSmaller: 32px;

// S
$typeFamilyS: $family;
$typeWeightS: 600;
$typeSizeS: 24px;
$typeLineHeightS: 32px;
$typeSizeSSmaller: 20px;
$typeLineHeightSSmaller: 28px;

// Xs
$typeFamilyXs: $family;
$typeWeightXs: 600;
$typeSizeXs: 20px;
$typeLineHeightXs: 28px;
$typeSizeXsSmaller: 18px;
$typeLineHeightXsSmaller: 28px;

// Xxs
$typeFamilyXxs: $family;
$typeWeightXxs: 600;
$typeSizeXxs: 18px;
$typeLineHeightXxs: 28px;
$typeSizeXxsSmaller: 16px;
$typeLineHeightXxsSmaller: 24px;

// CopyStrong
$typeFamilyCopyStrong: $family;
$typeWeightCopyStrong: 400;
$typeSizeCopyStrong: 16px;
$typeLineHeightCopyStrong: 24px;
$typeSizeCopyStrongSmaller: 16px;
$typeLineHeightCopyStrongSmaller: 24px;

//CopySecondary
$typeFamilyCopySecondary: $family;
$typeWeightCopySecondary: 400;
$typeSizeCopySecondary: 14px;
$typeLineHeightCopySecondary: 20px;
$typeSizeCopySecondarySmaller: 14px;
$typeLineHeightCopySecondarySmaller: 20px;

// Copy
$typeFamilyCopy: $family;
$typeWeightCopy: 400;
$typeSizeCopy: 14px;
$typeLineHeightCopy: 20px;
$typeSizeCopySmaller: 14px;
$typeLineHeightCopySmaller: 20px;

// Hint
$typeFamilyHint: $family;
$typeWeightHint: 400;
$typeSizeHint: 12px;
$typeLineHeightHint: 16px;
$typeSizeHintSmaller: 12px;
$typeLineHeightHintSmaller: 16px;

// Label
$typeFamilyLabel: $family;
$typeWeightLabel: 600;
$typeSizeLabel: 14px;
$typeLineHeightLabel: 20px;
$typeSizeLabelSmaller: 14px;
$typeLineHeightLabelSmaller: 20px;


// LabelDiagram
$typeFamilyLabelDiagram: $family;
$typeWeightLabelDiagram: 600;
$typeSizeLabelDiagram: 10px;
$typeLineHeightLabelDiagram: 14px;
$typeSizeLabelDiagramSmaller: 10px;
$typeLineHeightLabelDiagramSmaller: 14px;

// ButtonL
$typeFamilyButtonL: $family;
$typeWeightButtonL: 400;
$typeSizeButtonL: 14px;
$typeLineHeightButtonL: 14px;
$typeSizeButtonLSmaller: 14px;
$typeLineHeightButtonLSmaller: 14px;

// ButtonM
$typeFamilyButtonM: $family;
$typeWeightButtonM: 400;
$typeSizeButtonM: 14px;
$typeLineHeightButtonM: 14px;
$typeSizeButtonMSmaller: 14px;
$typeLineHeightButtonMSmaller: 14px;

// ButtonS
$typeFamilyButtonS: $family;
$typeWeightButtonS: 400;
$typeSizeButtonS: 14px;
$typeLineHeightButtonS: 14px;
$typeSizeButtonSSmaller: 14px;
$typeLineHeightButtonSSmaller: 14px;

@mixin type($typeId) {
  @if $typeId == "Xxl" {
    font-family: $typeFamilyXxl;
    font-weight: $typeWeightXxl;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXxl;
      line-height: $typeLineHeightXxl;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXxlSmaller;
      line-height: $typeLineHeightXxlSmaller;
    }
  } @else if $typeId == "Xl" {
    font-family: $typeFamilyXl;
    font-weight: $typeWeightXl;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXl;
      line-height: $typeLineHeightXl;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXlSmaller;
      line-height: $typeLineHeightXlSmaller;
    }
  } @else if $typeId == "L" {
    font-family: $typeFamilyL;
    font-weight: $typeWeightL;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeL;
      line-height: $typeLineHeightL;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLSmaller;
      line-height: $typeLineHeightLSmaller;
    }
  } @else if $typeId == "M" {
    font-family: $typeFamilyM;
    font-weight: $typeWeightM;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeM;
      line-height: $typeLineHeightM;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeMSmaller;
      line-height: $typeLineHeightMSmaller;
    }
  } @else if $typeId == "S" {
    font-family: $typeFamilyS;
    font-weight: $typeWeightS;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeS;
      line-height: $typeLineHeightS;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeSSmaller;
      line-height: $typeLineHeightSSmaller;
    }
  } @else if $typeId == "Xs" {
    font-family: $typeFamilyXs;
    font-weight: $typeWeightXs;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXs;
      line-height: $typeLineHeightXs;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXsSmaller;
      line-height: $typeLineHeightXsSmaller;
    }
  } @else if $typeId == "Xxs" {
    font-family: $typeFamilyXxs;
    font-weight: $typeWeightXxs;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXxs;
      line-height: $typeLineHeightXxs;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXxsSmaller;
      line-height: $typeLineHeightXxsSmaller;
    }
  } @else if $typeId == "CopyStrong" {
    font-family: $typeFamilyCopyStrong;
    font-weight: $typeWeightCopyStrong;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopyStrong;
      line-height: $typeLineHeightCopyStrong;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopyStrongSmaller;
      line-height: $typeLineHeightCopyStrongSmaller;
    }
  } @else if $typeId == "CopySecondary" {
    font-family: $typeFamilyCopySecondary;
    font-weight: $typeWeightCopySecondary;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopySecondary;
      line-height: $typeLineHeightCopySecondary;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopySecondarySmaller;
      line-height: $typeLineHeightCopySecondarySmaller;
    }
  } @else if $typeId == "Copy" {
    font-family: $typeFamilyCopy;
    font-weight: $typeWeightCopy;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopy;
      line-height: $typeLineHeightCopy;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopySmaller;
      line-height: $typeLineHeightCopySmaller;
    }
  } @else if $typeId == "Label" {
    font-family: $typeFamilyLabel;
    font-weight: $typeWeightLabel;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabel;
      line-height: $typeLineHeightLabel;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelSmaller;
      line-height: $typeLineHeightLabelSmaller;
    }
  } @else if $typeId == "Hint" {
    font-family: $typeFamilyHint;
    font-weight: $typeWeightHint;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeHint;
      line-height: $typeLineHeightHint;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeHintSmaller;
      line-height: $typeLineHeightHintSmaller;
    }
  } @else if $typeId == "LabelDiagram" {
    font-family: $typeFamilyLabelDiagram;
    font-weight: $typeWeightLabelDiagram;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabelDiagram;
      line-height: $typeLineHeightLabelDiagram;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelDiagramSmaller;
      line-height: $typeLineHeightLabelDiagramSmaller;
    }

  } @else if $typeId == "ButtonL" {
    font-family: $typeFamilyButtonL;
    font-weight: $typeWeightButtonL;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeButtonL;
      line-height: $typeLineHeightButtonL;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeButtonLSmaller;
      line-height: $typeLineHeightButtonLSmaller;
    }
  } @else if $typeId == "ButtonM" {
    font-family: $typeFamilyButtonM;
    font-weight: $typeWeightButtonM;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeButtonM;
      line-height: $typeLineHeightButtonM;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeButtonMSmaller;
      line-height: $typeLineHeightButtonMSmaller;
    }
  } @else if $typeId == "ButtonS" {
    font-family: $typeFamilyButtonS;
    font-weight: $typeWeightButtonS;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeButtonS;
      line-height: $typeLineHeightButtonS;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeButtonSSmaller;
      line-height: $typeLineHeightButtonSSmaller;
    }
  } @else {
    @error 'type($typeId) invalid typeId';
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blu-link {
  cursor: pointer;
  @include type('Copy');
  color: $colorPrimary1;
  text-decoration: none;

  &:hover {
    color: $colorPrimary2;
  }
}

:export {
  types: $types;

  typeFamilyXxl: $typeFamilyXxl;
  typeWeightXxl: $typeWeightXxl;
  typeSizeXxl: $typeSizeXxl;
  typeLineHeightXxl: $typeLineHeightXxl;
  typeSizeXxlSmaller: $typeSizeXxlSmaller;
  typeLineHeightXxlSmaller: $typeLineHeightXxlSmaller;

  typeFamilyXl: $typeFamilyXl;
  typeWeightXl: $typeWeightXl;
  typeSizeXl: $typeSizeXl;
  typeLineHeightXl: $typeLineHeightXl;
  typeSizeXlSmaller: $typeSizeXlSmaller;
  typeLineHeightXlSmaller: $typeLineHeightXlSmaller;

  typeFamilyL: $typeFamilyL;
  typeWeightL: $typeWeightL;
  typeSizeL: $typeSizeL;
  typeLineHeightL: $typeLineHeightL;
  typeSizeLSmaller: $typeSizeLSmaller;
  typeLineHeightLSmaller: $typeLineHeightLSmaller;

  typeFamilyM: $typeFamilyM;
  typeWeightM: $typeWeightM;
  typeSizeM: $typeSizeM;
  typeLineHeightM: $typeLineHeightM;
  typeSizeMSmaller: $typeSizeMSmaller;
  typeLineHeightMSmaller: $typeLineHeightMSmaller;

  typeFamilyS: $typeFamilyS;
  typeWeightS: $typeWeightS;
  typeSizeS: $typeSizeS;
  typeLineHeightS: $typeLineHeightS;
  typeSizeSSmaller: $typeSizeSSmaller;
  typeLineHeightSSmaller: $typeLineHeightSSmaller;

  typeFamilyXs: $typeFamilyXs;
  typeWeightXs: $typeWeightXs;
  typeSizeXs: $typeSizeXs;
  typeLineHeightXs: $typeLineHeightXs;
  typeSizeXsSmaller: $typeSizeXsSmaller;
  typeLineHeightXsSmaller: $typeLineHeightXsSmaller;

  typeFamilyXxs: $typeFamilyXxs;
  typeWeightXxs: $typeWeightXxs;
  typeSizeXxs: $typeSizeXxs;
  typeLineHeightXxs: $typeLineHeightXxs;
  typeSizeXxsSmaller: $typeSizeXxsSmaller;
  typeLineHeightXxsSmaller: $typeLineHeightXxsSmaller;

  typeFamilyCopyStrong: $typeFamilyCopyStrong;
  typeWeightCopyStrong: $typeWeightCopyStrong;
  typeSizeCopyStrong: $typeSizeCopyStrong;
  typeLineHeightCopyStrong: $typeLineHeightCopyStrong;
  typeSizeCopyStrongSmaller: $typeSizeCopyStrongSmaller;
  typeLineHeightCopyStrongSmaller: $typeLineHeightCopyStrongSmaller;

  typeFamilyCopySecondary: $typeFamilyCopySecondary;
  typeWeightCopySecondary: $typeWeightCopySecondary;
  typeSizeCopySecondary: $typeSizeCopySecondary;
  typeLineHeightCopySecondary: $typeLineHeightCopySecondary;
  typeSizeCopySecondarySmaller: $typeSizeCopySecondarySmaller;
  typeLineHeightCopySecondarySmaller: $typeLineHeightCopySecondarySmaller;

  typeFamilyCopy: $typeFamilyCopy;
  typeWeightCopy: $typeWeightCopy;
  typeSizeCopy: $typeSizeCopy;
  typeLineHeightCopy: $typeLineHeightCopy;
  typeSizeCopySmaller: $typeSizeCopySmaller;
  typeLineHeightCopySmaller: $typeLineHeightCopySmaller;

  typeFamilyHint: $typeFamilyHint;
  typeWeightHint: $typeWeightHint;
  typeSizeHint: $typeSizeHint;
  typeLineHeightHint: $typeLineHeightHint;
  typeSizeHintSmaller: $typeSizeHintSmaller;
  typeLineHeightHintSmaller: $typeLineHeightHintSmaller;

  typeFamilyLabel: $typeFamilyLabel;
  typeWeightLabel: $typeWeightLabel;
  typeSizeLabel: $typeSizeLabel;
  typeLineHeightLabel: $typeLineHeightLabel;
  typeSizeLabelSmaller: $typeSizeLabelSmaller;
  typeLineHeightLabelSmaller: $typeLineHeightLabelSmaller;

  typeFamilyLabelDiagram: $typeFamilyLabelDiagram;
  typeWeightLabelDiagram: $typeWeightLabelDiagram;
  typeSizeLabelDiagram: $typeSizeLabelDiagram;
  typeLineHeightLabelDiagram: $typeLineHeightLabelDiagram;
  typeSizeLabelDiagramSmaller: $typeSizeLabelDiagramSmaller;
  typeLineHeightLabelDiagramSmaller: $typeLineHeightLabelDiagramSmaller;

  typeFamilyButtonL: $typeFamilyButtonL;
  typeWeightButtonL: $typeWeightButtonL;
  typeSizeButtonL: $typeSizeButtonL;
  typeLineHeightButtonL: $typeLineHeightButtonL;
  typeSizeButtonLSmaller: $typeSizeButtonLSmaller;
  typeLineHeightButtonLSmaller: $typeLineHeightButtonLSmaller;

  typeFamilyButtonM: $typeFamilyButtonM;
  typeWeightButtonM: $typeWeightButtonM;
  typeSizeButtonM: $typeSizeButtonM;
  typeLineHeightButtonM: $typeLineHeightButtonM;
  typeSizeButtonMSmaller: $typeSizeButtonMSmaller;
  typeLineHeightButtonMSmaller: $typeLineHeightButtonMSmaller;

  typeFamilyButtonS: $typeFamilyButtonS;
  typeWeightButtonS: $typeWeightButtonS;
  typeSizeButtonS: $typeSizeButtonS;
  typeLineHeightButtonS: $typeLineHeightButtonS;
  typeSizeButtonSSmaller: $typeSizeButtonSSmaller;
  typeLineHeightButtonSSmaller: $typeLineHeightButtonSSmaller;
}
