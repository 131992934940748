@import 'shared.scss';

/* GarminIcon */
.garminIcon {

  /* INTERACTION */

  /* MODIFIERS */
  &.sizeL {
    width: 88px;
    height: 88px;
  }
  &.sizeM {
    width: 44px;
    height: 44px;
  }
  &.sizeS {
    width: 24px;
    height: 24px;
  }

  &.blue {
    & > path {
      fill: $colorPrimary1;
    }
  }
  &.lightBlue {
    & > path {
      fill: rgba(45, 114, 254, 0.4);
    }
  }
  &.lightRed {
    & > path {
      fill: rgba(225, 41, 43, 0.4);
    }
  }
  &.red {
    & > path {
      fill: $colorDanger1;
    }
  }
  &.gray {
    & > path {
      fill: $colorGrey3;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
