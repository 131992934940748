$bps: ("Xl", "L", "M", "S", "Xs");
/*min width*/
$bpXl: 1440px;
$bpL: 1280px;
$bpM: 960px;
$bpS: 760px;
$bpXs: 1px; // below 320px is not officially supported

$gridGap: 16px;
$gridMarginLarge: 60px;
$gridMarginSmall: 16px;


:export {
  bps: $bps;
  bpXl: $bpXl;
  bpL: $bpL;
  bpM: $bpM;
  bpS: $bpS;
  bpXs: $bpXs;
  gridGap: $gridGap;
  gridMarginSmall: $gridMarginSmall;
  gridMarginLarge: $gridMarginLarge;
}


@mixin mqSmaller($bp) {
  @media (max-width: #{$bp - 1}) {
    @content;
  }
}

@mixin mqLargerEqual($bp) {
  @media (min-width: #{$bp}) {
    @content;
  }
}

@mixin gridMargins {
  margin: 0px $gridMarginLarge;

  @include mqSmaller($bpS) {
    margin: 0px $gridMarginSmall;
  }
}

@mixin gridMarginsAsPaddings {
  padding-left: $gridMarginLarge;
  padding-right: $gridMarginLarge;

  @include mqSmaller($bpS) {
    padding-left: $gridMarginSmall;
    padding-right: $gridMarginSmall;
  }
}


@mixin basicGridNoMargins {
  display: grid;
  /*XL */
  $xlColumnWidth: calc((100% - 176px) / 12);
  //@include mediaXl {
  @include mqLargerEqual($bpXl) {
    grid-template-columns: [start] repeat(6, $xlColumnWidth) [mid] repeat(6, $xlColumnWidth) [end];
    grid-column-gap: $gridGap;
  }
  /*L */
  $lColumnWidth: calc((100% - 176px) / 12);
  //@include mediaL {
  @include mqSmaller($bpXl) {
    grid-template-columns: [start] repeat(6, $lColumnWidth) [mid] repeat(6, $lColumnWidth) [end];
    grid-column-gap: $gridGap;
  }
  /*M */
  $mColumnWidth: calc((100% - 176px) / 12);
  //@include mediaM {
  @include mqSmaller($bpL) {
    grid-template-columns: [start] repeat(6, $mColumnWidth) [mid] repeat(6, $mColumnWidth) [end];
    grid-column-gap: $gridGap;
  }
  /*S */
  $sColumnWidth: calc((100% - 112px) / 8);
  //@include mediaS {
  @include mqSmaller($bpM) {
    grid-template-columns: [start] repeat(4, $sColumnWidth) [mid] repeat(4, $sColumnWidth) [end];
    grid-column-gap: $gridGap;
  }
  /*XS */
  $xsColumnWidth: calc((100% - 48px) / 4);
  //@include mediaXs {
  @include mqSmaller($bpS) {
    grid-template-columns: [start] repeat(2, $xsColumnWidth) [mid] repeat(2, $xsColumnWidth) [end];
    grid-column-gap: $gridGap;
  }
}


@mixin basicGrid {
  @include basicGridNoMargins;
  @include gridMargins();
}

