@import 'shared.scss';

$animationBreatheDuration: 6.5s;
$animationBreatheRepeat: 3;
$animationBreatheDelay: 0.75s;

:export {
  animationBreatheDuration: $animationBreatheDuration;
  animationBreatheRepeat: $animationBreatheRepeat;
  animationBreatheDelay: $animationBreatheDelay;
}

/* BREATHE */
.breathe {

  position: relative;
  height: 100%;
  @include basicGridNoMargins;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .content {
    grid-column: 4/10;
  }

  .title {
    text-align: center;
    @include type('S');
    color: $colorGrey1;
  }
  .circle {
    width: 428px;
    height: 428px;
    margin: 24px auto;
    background-color: $colorPrimary3;
    border-radius: 50%;
    transform: scale(0, 0);

    @keyframes scale {
      0% {
        opacity: 0;
        transform: scale(0, 0);
      }
      50% {
        opacity: 1;
        transform: scale(1, 1);
      }
      52% {
        opacity: 1;
        transform: scale(1, 1);
      }
      90% {
        opacity: 0;
        transform: scale(0.32, 0.32);
      }
      100% {
        opacity: 0;
        transform: scale(0, 0);
      }
    }

    animation: scale $animationBreatheDuration $animationBreatheRepeat ease-out;
    animation-delay: $animationBreatheDelay;
  }
  .skip {
    position: absolute;
    bottom: 0px;
    right: space('Xl');

    @include type('Copy');
    color: $colorGrey2;
    cursor: pointer;

    @include transition(color);
    &:hover {
      color: $colorPrimary2;
    }

    @include mqSmaller($bpS) {
      right: space('Xs');
    }
  }

  @include mqSmaller($bpM) {
    .content {
      grid-column: 2/8;
      padding-top: 48px;
      .circle {
        width: 312px;
        height: 312px;
      }
    }
  }
  @include mqSmaller($bpS) {
    .content {
      grid-column: start/end;
      padding-top: 48px;
      .circle {
        width: 288px;
        height: 288px;
      }
    }
  }
}
