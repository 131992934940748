@import 'shared';

.skeletonContainer {
  border: 1px solid $colorGrey4;
  border-radius: 10px;
  padding: 23px 15px 21px 15px;
  box-sizing: border-box;
  grid-column: 5/13;
  .skeletonBlock {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-column-gap: 16px;
    animation: load-assessment-result 1.5s infinite ease;
    .left {
      grid-column: 1/4;
      height: 216px;
      background-color: $colorGrey4;
      border-radius: 10px;
    }
    .right {
      grid-column: 4/9;
      .header {
        height: 24px;
        width: 60%;
        box-sizing: border-box;
        background-color: $colorGrey4;
        border-radius: 12px;
      }
      .subItems {
        .subItemsGroup {
          margin-top: 24px;
          .subItem1, .subItem2 {
            height: 14px;
            width: 40%;
            box-sizing: border-box;
            background-color: $colorGrey4;
            border-radius: 12px;
          }
          .subItem2 {
            width: 60%;
            margin-top: 8px;
          }
        }
      }
    }
  }
  @include mqSmaller($bpM) {
    padding: 23px 16px;
    grid-column: 5/9;
    grid-template-columns: repeat(4, auto);
    .skeletonBlock {
      grid-template-columns: repeat(4, auto);
      .left {
        grid-column: 1/5;
      }
      .right {
        grid-column: 1/5;
        margin-top: 16px;
        .header, .subItem2 {
          width: 100% !important;
        }
        .subItem1 {
          width: 60% !important;
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    padding: 23px 16px;
    grid-column: start/end;
    grid-template-columns: repeat(4, auto);
    .skeletonBlock {
      grid-template-columns: repeat(4, auto);
      .left {
        grid-column: start/end;
      }
      .right {
        grid-column: start/end;
        margin-top: 16px;
        .header, .subItem2 {
          width: 100% !important;
        }
        .subItem1 {
          width: 60% !important;
        }
      }
    }
  }
}
