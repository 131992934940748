@import 'shared.scss';

/* GarminTrend */
.garminTrend {
  display: flex;
  align-items: center;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > span {
    @include type('Label');
    padding-right: space('Xxs');
  }
  & > svg {
    &.positive {
      rect, polygon {
        fill: $colorPrimary1;
      }
    }

    &.slightly-positive {
      transform: rotate(45deg);
      & > g > path {
        fill: rgba(45, 114, 254, 0.4);
      }
    }

    &.slightly-negative {
      transform: rotate(135deg);
      & > g > path {
        fill: rgba(225, 41, 43, 0.4);
      }
    }

    &.negative {
      transform: rotate(180deg);
      & > g > path {
        fill: $colorDanger1;
      }
    }

  }
}
