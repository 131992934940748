@import 'shared.scss';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0;
  transform: translateY(-24px) !important;
}
.enterActive, .enterDone {
  opacity: 1;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  opacity: 1;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  opacity: 0;
  transform: translateY(-24px) !important;
  @include transition(transform, opacity);
}
