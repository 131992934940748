@import 'shared.scss';

/* QUESTIONASPECTS */
.questionAspects {

  margin-top: $spaceS;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;
}
