@import 'shared';

.enter {
  transform: translateX(348px) !important;
}
.enterActive, .enterDone {
  transform: translateX(0) !important;
  @include transition(transform);
}
.exit {
  transform: translateX(0px) !important;
}
.exitActive, .exitDone {
  transform: translateX(348px) !important;
  @include transition(transform);
}

.toast {
  width: 348px;
  position: fixed;
  padding: space('Xs');
  box-sizing: border-box;
  background-color: $colorWhite;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);;
  border: 1px solid $colorGrey4;
  border-radius: 2px;
  bottom: space('M');
  right: 60px;

  z-index: zIndex('Modal');
  .content {
    overflow: hidden;
  }
  .headline {
    @include type('Label');
  }
  .message {
    margin-top: space('Xxs');
    @include type('Copy');
  }
  svg {
    position: absolute;
    right: 16px;
    top: 19px;
    cursor: pointer;
  }

  @include mqSmaller($bpM) {
    width: 312px;
  }
  @include mqSmaller($bpS) {
    width: 288px;
    right: 16px;
  }
}
