@import 'shared';

.assessmentEnd {
  background-color: $colorLight;

  margin: 0 60px;
  text-align: center;

  @include mqSmaller($bpS) {
    margin: 0 space('Xs');
  }

  .graphic {
    font-size: 48px;
    line-height: 48px;

    @include mqSmaller($bpM) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .sTitle {
    @include type('S');
    margin-top: space('Xs');
  }

  .copy {
    @include type('Copy');
    margin-top: space('Xs');
  }

  .buttons {
    margin-top: space('S');
    & > button {
      display: block;
      margin: 0 auto;
    }

    & > button:not(:first-child) {
      margin-top: space('Xs');
    }
  }
}
