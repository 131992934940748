@import 'shared.scss';

.skeletonContainer {
  border: 1px solid $colorGrey4;
  border-radius: 10px;
  padding: 30px 32px 26px 32px;
  box-sizing: border-box;
  grid-column: 5/13;
  .skeletonBlock {
    animation: load-assessment-result 1.5s infinite ease;
    .header {
      width: 100%;
      height: 24px;
      box-sizing: border-box;
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    .subItems {
      width: 100%;
      box-sizing: border-box;
      .subItemsGroup {
        margin-top: 32px;
        .subItem1, .subItem2 {
          height: 14px;
          width: 50%;
          box-sizing: border-box;
          background-color: $colorGrey4;
          border-radius: 12px;
        }
        .subItem2 {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
  @include mqSmaller($bpM) {
    padding: 22px 16px 58px;
    grid-column: 5/9;
  }

  @include mqSmaller($bpS) {
    height: 1348px;
    padding: 22px 16px 58px;
    grid-column: start/end;
  }
}
