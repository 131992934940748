@import 'shared.scss';

$height: 52px;
$heightL: 72px;

.enter {
  pointer-events:none;
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  @include transition(opacity);
}

/* MULTISELECT */
.multiSelect {

  /* INTERACTION */

  /* MODIFIERS */
  &.sizeL {
    .options .option {

      padding: $spaceS;
      min-height: $heightL;

      @include type('CopyStrong');
    }
  }

  &.sizeResponsive {
    @include mqLargerEqual($bpM) {
      .options .option {

        padding: $spaceS;
        min-height: $heightL;

        @include type('CopyStrong');
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */

  .options {
    .option {
      user-select: none;

      box-sizing: border-box;
      padding: $spaceXs;
      margin-bottom: $spaceXs;
      min-height: $height;

      @include type('Copy');

      border: 1px solid $colorGrey4;
      border-radius: 4px;

      @include transition(color, border-color);

      &:hover {
        cursor: pointer;
        color: $colorPrimary2;
        border-color: $colorPrimary2;
        &.active {
          color: $colorPrimary1;
        }
      }
      &.active {
        border-color: $colorPrimary1;
      }

      .inputContainer {
        margin-top: $spaceXs;
        width: 100%;
      }
    }
  }

  .custom {
    display: flex;

    > div:first-child {
      width: calc(65% - #{$spaceXs});
      margin-right: $spaceXs;
    }
    > div:nth-child(2) {
      width: 35%;
    }

    @include mqSmaller($bpM) {
      > div:first-child {
        width: calc(60% - #{$spaceXs});
      }
      > div:nth-child(2) {
        width: 40%;
      }
    }
  }
}
