@import 'shared';

.assessmentEnd {
  background-color: $colorLight;

  margin: 0 60px;
  text-align: center;

  @include mqSmaller($bpS) {
    margin: 0 space('Xs');
  }

  .graphic {
    font-size: 48px;
    line-height: 48px;

    @include mqSmaller($bpM) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .sTitle {
    @include type('S');
    margin-top: space('Xs');
  }

  .copy {
    @include type('Copy');
    margin: space('Xs') auto 0;
    max-width: 650px;
  }

  button {
    display: block;
    margin: space('S') auto 0;
  }
}
