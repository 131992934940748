@import 'shared.scss';

$optionHeight: 40px;

/* questionBubbles */
.questionBubbles {

  padding-bottom: space('L');

  /* ANIMATE */
  &.animate {
    .questionTitle, .questionLabel, .questionDescription, .helpButton, .hint {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option:not(.selected) {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option.selected {
      z-index: 1;
    }

    @keyframes moveUp {
      from {
        opacity: 1;
      }
      to {
        transform: translateY(-30px);
        opacity: 0;
      }
    }
  }

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .questionLabel {
    max-width: 750px;
    margin: auto;
    margin-bottom: space('Xs');

    text-align: center;
    @include type('Label');
  }

  .questionTitle {
    max-width: 750px;
    margin: auto;

    text-align: center;
    @include type('S');

    @include mqSmaller($bpM) {
      max-width: 475px;
    }

    &.isBlue {
      color: $colorPrimary1;
    }
  }

  .questionDescription {
    max-width: 750px;
    margin: auto;
    margin-top: space('Xs');

    text-align: center;
    @include type('CopyStrong');
  }

  .optionsContainer {
    display:flex;
    justify-content: center;

    margin-top: $spaceM;
  }
  .options {

  }
  .option {
    cursor: pointer;

    height: $optionHeight;
    display: flex;
    align-items: center;
    padding: 0 $spaceXs;

    @include type('CopyStrong');

    padding-top: $spaceXxs;
    padding-bottom: $spaceXxs;

    @media (hover: hover) {
      &:hover {
        .checkbox {
          &:after {
            opacity: 1;
            background-color: $colorPrimary3;
          }
        }

        .labelLight {
          color: $colorPrimary1;
        }

        .label {
          color: $colorPrimary1;
        }
      }
    }

    &.selected {
      .checkbox {
        &:after {
          background-color: $colorPrimary1;
          opacity: 1;
        }
      }
    }

  }

  .checkbox {
    $selectedPadding: 4px;
    position: relative;

    height: $optionHeight;
    width: $optionHeight;
    margin-right: $spaceXs;

    border-radius: 50%;

    background-color: $colorGrey4;

    &:after {
      content: ' ';
      position: absolute;

      top: $selectedPadding;
      left: $selectedPadding;
      height: $optionHeight - 2 * $selectedPadding;
      width: $optionHeight - 2 * $selectedPadding;

      border-radius: 50%;
      opacity: 0;
      background-color: $colorGrey4;
      z-index: 1;

      @include transition(opacity, background-color);
    }
  }

  .labelLight {
    width: $spaceXs;
    margin-right: $spaceXs;
    color: $colorGrey2;
  }

  .label {
    @include transition(color);
  }

  .helpButton {
    margin-top: space('M');
    button {
      display: block;
      margin: 0 auto;
    }
  }

  .hint {
    @include type('Hint');
    margin-top: space('M');
    .line1 {
      padding-bottom: 8px;
    }
    .line2 {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: 0 4px;
      }
      svg:last-child {
        margin: 0 4px 0 0;
      }
    }
  }
}
