@import "shared";

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;


  @include type('CopySecondary');
  color: $colorGrey2;
  .right {
    a {
      color: $colorGrey2;
      text-decoration: none;
    }
    *:last-child {
      padding-left: 24px;
    }
  }
  @include mqSmaller($bpS) {
    flex-direction: column;
    padding: 16px 0;
    .right {
      padding-top: 8px;
    }
  }
}
@keyframes footerSlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
