@import 'shared.scss';

/* INPUT CONTAINER */

.inputContainer {
  position:relative;
  width: 100%;

  &.sizeL {
    .label {
      @include type('CopyStrong');
    }
    .input {
      height: $inputHeightL;
      @include type('CopyStrong');
      &::placeholder {
        opacity: 1;
      }
    }
  }
  &.sizeResponsive {
    @include mqLargerEqual($bpM) {
      .label {
        @include type('CopyStrong');
      }
      .input {
        height: $inputHeightL;
        @include type('CopyStrong');
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }
}

/* LABEL */
.label {
  position: absolute;

  width: calc(100% - 16px);

  pointer-events: none;
  color: $colorGrey1;
  @include type('Copy');
  @include ellipsis();

  transform: translate(17px, 15px);
  transform-origin: 0 0;

  // transition
  @include transition(transform, width);
  &.small {
    width: 100%;
    transform: translate(17px, 3px) scale($typeSizeHint / $typeSizeCopy);
  }
  &.disabled {
    color: $colorGrey2;
  }
}



/* INPUT */
.input {
  // remove iOS input top shadow
  appearance:none; // https://stackoverflow.com/questions/23211656/remove-ios-input-shadow

  height: 50px;
  width: 100%;
  min-width: 150px;
  box-sizing: border-box;
  padding: 16px 16px 0px 16px;

  color: $colorGrey1;
  caret-color: $colorPrimary1;
  @include type('Copy');

  border: 1px $colorGrey4 solid;
  border-bottom-width: 2px;
  border-radius: 2px;

  outline: none;

  background-color: $colorGrey4;

  // transitions
  @include transition(color, background-color, box-shadow, border-color);


  &:hover, &.hover {
    box-shadow: $shadowDark;
    border-color: $colorWhite;
    background-color: $colorWhite;
  }
  &:focus, &.focus {
    box-shadow: $shadowDark;
    border-bottom-color: $colorPrimary1;
    background-color: $colorWhite;
  }
  &:disabled {
    color: $colorGrey2;
    box-shadow: none;
    border-color: $colorGrey5;
    background-color: $colorGrey5;
  }
  &[placeholder] {
    text-overflow: ellipsis;
  }
  &.error {
    color: $colorDanger1;
    border-bottom-color: $colorDanger1;
  }
  &.noBorder {
    border-color: rgba(0,0,0,0);
  }
  &.placeholder {
    padding-top: 3px;
  }
  &.hasIcon {
    padding-left: 48px;
  }
  &.hasDomain {
    padding-right: 120px;
  }
  &.hasConfirmButton {
    padding-right: 60px;
  }
}

//HINT
.hint {
  @include type('Hint');
  margin-top: space('Xxs');
  margin-left: space('Xs');
  color: $colorGrey2;
}

//ERROR MESSAGE
.errorMessage {
  @include type('Hint');
  margin-top: space('Xxs');
  margin-left: space('Xs');
  color: $colorDanger1;
}

//DOMAIN
.domain {
  position: absolute;
  right: 15px;
  top: 15px;
  @include type('Copy')
}

/* ICON */
.icon {
  position: absolute;
  top: 15px;
  left: 17px;
  width: 22px;
  fill: red !important;
}

.confirmButton {
  position: absolute;

  width: 40px;
  height: 40px;
  right: space('Xs');
  top: 5px;

  border-radius: 20px;

  svg {
    margin-top: 13px;
    margin-left: 14px;
  }

  svg path {
    fill: $colorPrimary1 !important;
  }

  @include transition(background-color, opacity);

  &:hover {
    cursor: pointer;
    background-color: $colorPrimary4;
  }

  &.hide { // we're using hide here instead of BluCSSTransition to be able to onClick before the buttons disappears
    opacity: 0;
  }

  &.error {

    svg path { fill: $colorGrey2 !important; }

    &:hover {
      background-color: $colorGrey4;
    }
  }
}

.bigButton {
  cursor: pointer;
  position: absolute;

  width: 36px;
  height: 36px;
  right: space('Xs');
  top: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;

  background-color: $colorPrimary1;

  @include transition(background-color, opacity);

  &:hover {
    background-color: $colorPrimary2;
  }

  &.hide {
    opacity: 0;
  }
  &.disabled {
    background-color: rgba($colorPrimary1, 0.1);
  }
}

.password {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
  padding-left: $spaceXs;

  svg g path {
    stroke: $colorGrey2;
  }

  @include transition(stroke);

  &.visible {
    svg g path {
      stroke: $colorPrimary1;
    }
  }

  &:hover {
    svg g path {
      stroke: $colorPrimary1;
    }
  }
}
