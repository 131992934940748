@import 'shared.scss';

/* PROGRESSCIRCLE */
.progressCircle {
  $circleSize: 16px;
  $insetSize: 12px;

  $circle-background: $colorGrey4;
	$circle-color: $colorPrimary1;
	$inset-color: $colorWhite;
  $transition-length: 1s;

  display: inline-block;

	width:  $circleSize;
  height: $circleSize;
  margin-right: $circleSize / 2;

  border-radius: 50%;
  background-color: $circle-background;

  transform: translateY(3px);
  @include transition(background-color);

  // &.sizeM { // not fully implemented
  //   $circleSize: 30px;
  //   $insetSize: 24px;

  //   width: $circleSize;
  //   height: $circleSize;
  //   margin-right: $circleSize / 2;


  //   .circle {
  //     .mask, .fill {
  //       width: $circleSize;
  //       height: $circleSize;
  //     }
  //     .mask {
  //       clip: rect(0px, $circleSize, $circleSize, $circleSize/2);
  //       .fill {
  //         clip: rect(0px, $circleSize/2, $circleSize, 0px);
  //         background-color: $circle-color;
  //       }
  //     }
  //   }

  //   .inset {
  //     width: $insetSize;
  //     height: $insetSize;
  //     margin-left: ($circleSize - $insetSize)/2;
  //     margin-top:  ($circleSize - $insetSize)/2;
  //   }
  // }

  &.loading {
    animation: rotate 1.5s infinite ease;
  }
  &.yellowDiff {
    background-color: $colorSecondary1;
  }

	.circle {
		.mask, .fill {
			width:    $circleSize;
			height:   $circleSize;
			position: absolute;
			border-radius: 50%;
		}
		.mask, .fill {
			transition: transform $transition-length;
			border-radius: 50%;
		}
		.mask {
			clip: rect(0px, $circleSize, $circleSize, $circleSize/2);
			.fill {
				clip: rect(0px, $circleSize/2, $circleSize, 0px);
				background-color: $circle-color;
			}
		}
	}

	.inset {
		position:    absolute;
		width:       $insetSize;
		height:      $insetSize;
		margin-left: ($circleSize - $insetSize)/2;
		margin-top:  ($circleSize - $insetSize)/2;

		background-color: $inset-color;
    border-radius: 50%;

    @include transition (background-color);

    svg {
      transform: translate(-1px, -1px);
    }
	}

  &.gray {
    .circle {
      .mask .fill {
        background-color: $colorGrey2;
      }
    }
    .inset {
      svg g path {
        fill: $colorGrey2;
      }
    }
  }

  &.sizeM {
    $circleSize: 40px;
    $insetSize: 36px;

    width: $circleSize;
    height: $circleSize;
    margin-right: 0;

    .circle {
      .mask, .fill {
        width: $circleSize;
        height: $circleSize;
      }

      .mask {
        clip: rect(0px, $circleSize, $circleSize, $circleSize/2);

        .fill {
          clip: rect(0px, $circleSize/2, $circleSize, 0px);
          background-color: $circle-color;
        }
      }
    }

    .inset {
      width: $insetSize;
      height: $insetSize;
      margin-left: ($circleSize - $insetSize)/2;
      margin-top: ($circleSize - $insetSize)/2;

      @include type('Label');
      line-height: $insetSize !important;
      text-align: center;

      svg {
        vertical-align: middle;
      }

      img.wellBeing {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }

  &.sizeXm {
    $circleSize: 60px;
    $insetSize: 54px;

    width: $circleSize;
    height: $circleSize;
    margin-right: 0;

    .circle {
      .mask, .fill {
        width: $circleSize;
        height: $circleSize;
      }
      .mask {
        clip: rect(0px, $circleSize, $circleSize, $circleSize/2);
        .fill {
          clip: rect(0px, $circleSize/2, $circleSize, 0px);
          background-color: $circle-color;
        }
      }
    }

    .inset {
      width: $insetSize;
      height: $insetSize;
      margin-left: ($circleSize - $insetSize)/2;
      margin-top:  ($circleSize - $insetSize)/2;

      @include type('Label');
      line-height: $insetSize !important;
      text-align: center;

      svg {
        vertical-align: middle;
      }
      img.wellBeing {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }

  &.sizeL {
    $circleSize: 72px;
    $insetSize: 68px;

    width: $circleSize;
    height: $circleSize;
    margin-right: 0;

    .circle {
      .mask, .fill {
        width: $circleSize;
        height: $circleSize;
      }
      .mask {
        clip: rect(0px, $circleSize, $circleSize, $circleSize/2);
        .fill {
          clip: rect(0px, $circleSize/2, $circleSize, 0px);
          background-color: $circle-color;
        }
      }
    }

    .inset {
      width: $insetSize;
      height: $insetSize;
      margin-left: ($circleSize - $insetSize)/2;
      margin-top:  ($circleSize - $insetSize)/2;

      @include type('Label');
      line-height: $insetSize !important;
      text-align: center;

      img.wellBeing {
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
  }

  &.sizeXl {
    $circleSize: 80px;
    $insetSize: 76px;

    width: $circleSize;
    height: $circleSize;
    margin-right: 0;

    .circle {
      .mask, .fill {
        width: $circleSize;
        height: $circleSize;
      }
      .mask {
        clip: rect(0px, $circleSize, $circleSize, $circleSize/2);
        .fill {
          clip: rect(0px, $circleSize/2, $circleSize, 0px);
          background-color: $circle-color;
        }
      }
    }

    .inset {
      width: $insetSize;
      height: $insetSize;
      margin-left: ($circleSize - $insetSize)/2;
      margin-top:  ($circleSize - $insetSize)/2;

      @include type('Label');
      line-height: $insetSize !important;
      text-align: center;

      img.wellBeing {
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
  }

	$i: 0;
	$increment: 180deg / 100;
	@for $i from 0 through 100 {
		&[data-progress='#{$i}'] {
			.circle {
				.mask.full, .fill {
					transform: rotate($increment * $i);
				}
				.fill.fix {
					transform: rotate($increment * $i * 2);
				}
			}
		}
  }
}
