@import 'shared.scss';

/* CARD */
.card {
  width: 100%;
  box-sizing: border-box;

  padding: space('M');
  padding-bottom: space('L');

  border: 1px solid $colorGrey4;
  border-radius: $borderRadius;

  background-color: $colorWhite;

  @include transition(border-color);

  // MODIFIERS
  &.hasFullHeight {
    box-sizing: border-box;
    height: 100%;
  }
  &.hasNoPaddings {
    padding: 0;
  }
  &.hasPaddingsSmall {
    padding: space('Xs');
    padding-top: space('S');
    padding-bottom: space('M');
  }
  &.hasPaddingsForModal {
    padding: space(S) space('M') space('M');
  }
  &.hasGrabCursor {
    cursor: grab !important;
    // give precedence over hasHover pointer cursor
  }

  &.hasHover, &.hasHoverBlue {
    cursor:pointer;
    position: relative;

    &:after {
      pointer-events:none;
      content: '';
      position: absolute;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      border-radius: $borderRadius;
      box-shadow: $shadowDark;
      opacity: 0;
      @include transition(opacity);
    }

    &.hasHoverBlue:after {
      box-shadow: $shadowBlue;
    }

    &:hover:after {
      opacity: 1;
    }

  }
  &.hasBreakout {
    padding: 0;
  }
  &.hasBorderBlue {
    border-color: $colorPrimary1;
  }
  &.hasBackgroundGrey {
    background-color: $colorGrey5;
  }
  &.hasBackgroundTransparent {
    background-color: transparent;
  }

  // RESPONSIVE
  @include mqSmaller($bpM) {
    padding: space('Xs');
    padding-top: space('S');
    padding-bottom: space('M');

    &.hasPaddingsForModal {
      padding: space(S) space('M') space('M');
    }
  }

  @include mqSmaller($bpS) {
    &.hasPaddingsForModal {
      padding-left: space('Xs');
      padding-right: space('Xs');
    }
  }
}