@import 'shared.scss';

/* RMPRESULTTABLE */
.rmpResultTable {
  margin-top: space('S');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .row {
    padding: 16px 0;
    border-top: 1px solid $colorGrey3;
    &:last-child {
      border-bottom: 1px solid $colorGrey3;
    }
    .label {
      @include type('Label');
    }
    .value {
      @include type('Copy');
      margin-top: space('Xxs');
    }
  }
}
