@import 'shared';

.workPreferencesResult {
  background-color: $colorWhite;

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .container {
    width: 100%;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    padding: 32px 32px 48px 32px;
    box-sizing: border-box;
  }
  .rows {
    margin-top: 4px;
    .item {
      @include type('Copy');
      .header {
        width: 100%;
        box-sizing: border-box;
        padding: 24px 0 16px;
        text-align: center;
      }
      .titles {
        @include type('Hint');
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        margin-top: space('Xxs');
        color: $colorGrey2;
        div:last-child {
          text-align: right;
        }
        .blue {
          color: $colorPrimary1;
        }
      }
    }
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid $colorGrey3;
      padding-top: 8px;
      padding-bottom: 8px;
      .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        .left {
          flex: 1;
        }
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          .points {
            width: 28px;
            margin-left: 16px;
          }
          .name {
            padding-left: 8px;
          }
        }
      }
      .body {
        padding: 0 32px;
        .sub-item-row {
          display: flex;
          align-items: center;
          padding-bottom: 24px;
          .right {
            margin-left: 16px;
          }
          &:first-child {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('M');
  }

  @include mqSmaller($bpM) {
    .container {
      padding: 24px 18px 32px;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      padding: 24px 16px 32px
    }
  }
}
