@import 'shared.scss';

.filterListSkeleton {
  @include basicGrid;
  margin-top: space('L');

  .gridContent {
    grid-column: start/end;
    animation: load-assessment-result 1.5s infinite ease;
  }

  .header {
    > div {
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    > div:first-child {
      width: 60%;
      height: 24px;
    }
    > div:not(:first-child) {
      height: 14px;
      margin-top: 8px;
    }
    > div:nth-child(2) {
      width: 45%;
      margin-top: 26px;
    }
    > div:last-child {
      width: 65%;
    }
  }

  .body {
    margin-top: 30px;
    border: 1px solid $colorGrey4;
    border-radius: 10px;

    box-sizing: border-box;
    padding: space('M');

    @include mqSmaller($bpS) {
      padding: space('M') space('Xs');
    }

    .bodyBlock {
      margin-top: space('M');
      &:first-child {
        margin-top: 0;
      }
      > div {
        height: 14px;
        background-color: $colorGrey4;
        border-radius: 12px;
      }
      > div:first-child {
        width: 50%;
      }
      > div:last-child {
        margin-top: space('Xxs');
        width: 100%;
      }
    }
  }

  @include mqSmaller($bpS) {
    margin-top: space('M') !important;
  }
}
