@import 'shared.scss';

.toggle {
  position: relative;
  width: 48px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  .toggleCheckbox {
    display: none;
  }
  .toggleLabel {
    display: block;
    width: 48px;
    height: 16px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 4px;
    .toggleInner::before, .toggleInner::after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
    }
    .toggleInner::before {
      content: '';
      background-color: $colorGrey4;
    }
    .toggleInner::after {
      content: '';
      background-color: $colorGrey4;
    }
    .toggleSwitch {
      display: block;
      width: 24px;
      height: 24px;
      background-color: $colorGrey2;
      position: absolute;
      top: 0;
      right: 24px;
      border-radius: 50%;
      transition: all $animationDuration $animationFunction;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);;
    }
    &:hover {
      .toggleSwitch {
        background-color: $colorGrey1;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    .toggleCheckbox:checked + .toggleLabel .toggleSwitch {
      background-color: $colorPrimary3;
    }
    .toggleSwitch {
      background-color: $colorGrey3;
      box-shadow: none;
    }
  }

  .toggleCheckbox:checked + .toggleLabel .toggleInner {
    margin-left: 0;
  }
  .toggleCheckbox:checked + .toggleLabel .toggleSwitch {
    right: 0px;
    background-color: $colorPrimary2;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  }
  .toggleCheckbox:checked + .toggleLabel {
    &:hover {
      .toggleSwitch {
        background-color: $colorPrimary1;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
