@import 'shared';

.radioButton {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  padding-left: 44px;
  color: $colorGrey1;
  @include type('Copy');
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .name {
    display: block;
    padding-top: 9px;
  }
  .checkmark {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 36px;
    width: 36px;
    background-color: $colorGrey4;
    border: 1px solid $colorGrey4;
    border-radius: 50%;
    @include transition(background-color, border);
    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      left: 14px;
      top: 14px;
      width: 8px;
      height: 8px;
      background-color: $colorPrimary1;
      border-radius: 50%;
      @include transition(opacity);
    }
  }

  &.checked {
    .name {
      color: $colorPrimary1;
    }
    .checkmark {
      display: block;
      background-color: $colorGrey4;
      border: 1px solid $colorGrey4;
      &:after {
        opacity: 1;
        @include transition(opacity);
      }
    }
  }
  &:hover {
    .name {
      color: $colorPrimary1;
      @include transition(color);
    }
    .checkmark {
      background-color: $colorWhite;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      @include transition(background-color, box-shadow);
    }
  }
  &:focus {
    .checkmark {
      background-color: red;
    }
  }
  &.error {
    .checkmark {
      box-sizing: border-box;
      border-color: $colorDanger1;
      &:after {
        background-color: $colorDanger1;
      }
    }
    .name {
      color: $colorDanger1;
    }
  }
  &.disabled {
    pointer-events: none;
    .name {
      color: $colorGrey2;
    }
    .checkmark {
      background-color: $colorGrey5;
      border-color: $colorGrey5;
      box-shadow: none;
      &:after {
        background-color: $colorPrimary3;
      }
    }
  }
}
