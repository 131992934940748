@import 'shared.scss';

/* QUESTIONCAREERLEVEL */
.questionCareerLevel {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
