@import 'shared';

.profilePanel {
  height: calc(100vh - 146px);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 62px 24px 84px;
  text-align: center;
  .avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto;

    color: $colorPrimary1;
    @include type('S');
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;

    border-radius: 50%;
    border: 1px solid $colorPrimary4;
    background-color: $colorPrimary4;

    line-height: 80px !important;

    // transition
    @include transition(border-color);

    &:hover {
      border-color: $colorPrimary1;
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .name {
    @include type('Xxs');
    color: $colorGrey1;
    padding-top: 16px;
  }
  .occupation {
    @include type('Copy');
    color: $colorGrey1;
    padding-top: 4px;
  }
  .buttons {
    margin-top: 16px;
    button {
      display: block;
      margin: 16px auto 0;
    }
  }
  @keyframes slide-from-right {
    0% {
      right: -317px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
}
