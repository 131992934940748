@import "shared";

.logoutSuccessForm {
  @include basicGrid;

  .container {
    margin-top: 176px;
    grid-column: 5/9;
    animation: loginSlideIn .300s ease-out;

    @include type('M');

    &.balanced_you {
      margin-top: 126px;
      .poweredBy {
        padding-bottom: space('S');
      }
    }

    .text {
      margin-top: space('M');
    }

    button {
      margin-top: 32px;
    }
  }

  @include mqSmaller($bpM) {
    .container {
      grid-column: 3/7;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      text-align: center;
      margin-top: 92px;
      &.balanced_you {
        margin-top: 88px;

        .poweredBy {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
