@import 'shared.scss';

.surveyFloatingMenu {
  position: fixed;
  right: 8px;
  bottom: 16px;
  z-index: zIndex('Buffer3');

  @include mqSmaller($bpS) {
    right: 16px;
  }
  .icon, .closeIcon {
    width: 44px;
    height: 44px;
    background-color: $colorWhite;
    border: 1px solid $colorGrey4;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include transition(background-color);
    &:hover {
      background-color: $colorPrimary3;
    }
  }
  .surveyMenu {
    display: flex;
    animation: fade-in $animationDuration $animationFunction;
    .menuItems {
      > div {
        display: block;
        height: 44px;
        box-sizing: border-box;
        padding: 12px 24px;
        border-radius: 22px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        color: $colorPrimary1;
        background-color: $colorWhite;
        text-decoration: none;
        cursor: pointer;
        @include transition(background-color);
        &:hover {
          background-color: $colorPrimary3;
        }
      }
      div:not(:first-child) {
        margin-top: space('Xxs');
      }
    }
    .closeIcon {
      margin-left: space('Xxs');
      margin-top: auto;
    }
  }
}
