@import 'shared';

.link {
  @include type('Copy');
  color: $colorPrimary1;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  &:hover {
    color: $colorPrimary2;
  }
  &:focus {
    color: $colorPrimary2;
    text-decoration: underline;
  }
  &.inactive {
    color: $colorGrey2;
    pointer-events: none;
  }
  &.secondary {
    @include type('CopySecondary');
    color: $colorGrey2;
    &:hover {
      color: $colorPrimary2;
    }
    &:focus {
      color: $colorPrimary2
    }
    &.inactive {
      color: $colorGrey3;
      pointer-events: none;
    }
  }
  &.anchor {
    color: $colorPrimary1;
    svg {
      margin-left: space('Xxs');
    }
    &:hover {
      color: $colorPrimary2;
      svg polygon {
        fill: $colorPrimary2;
      }
    }
    &:focus {
      color: $colorPrimary2;
      svg polygon {
        fill: $colorPrimary2;
      }
    }
    &.inactive {
      color: $colorPrimary3;
      pointer-events: none;
      svg polygon {
        fill: $colorPrimary3
      }
    }
  }
}
