@import 'shared.scss';

$markerWidth: 4px;
$markerHeight: 20px;

/* TEAMCLIFTONRESULT */
.teamCliftonResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    .title, .pills {
      padding: $spaceS $spaceXs 0 $spaceXs;
    }
    .pills {
      padding: 0 $spaceXs 0 $spaceXs;
    }
    .missingStrengths {
      padding: 0 $spaceXs 0 $spaceXs;
      margin-bottom: $spaceM;
    }
  }

  /* CHILDREN */
  .title {
    @include type('Label')
  }
  .pills {
    margin-top: $spaceXs;
  }
  .strengths {
    .strength {
      padding: $spaceXs;
      border-top: 1px solid $colorGrey3;

      &:first-child {
        $animationDuration: 300ms;
        @include animationDurationOverride($animationDuration, fadeLeft);
        > *:nth-child(2) {
          @include animationDurationOverride($animationDuration + 250ms, fadeLeft);
        }
        > *:nth-child(3) {
          @include animationDurationOverride($animationDuration + 500ms, fadeLeft);
        }
        > *:nth-child(4) {
          @include animationDurationOverride($animationDuration + 750ms, fadeLeft);
        }
        > *:nth-child(n + 5) {
          @include animationDurationOverride($animationDuration + 1050ms, fadeLeft);
        }
      }
      &:nth-child(2) {
        $animationDuration: 550ms;
        @include animationDurationOverride($animationDuration, fadeLeft);
        > *:nth-child(2) {
          @include animationDurationOverride($animationDuration + 250ms, fadeLeft);
        }
        > *:nth-child(3) {
          @include animationDurationOverride($animationDuration + 500ms, fadeLeft);
        }
        > *:nth-child(4) {
          @include animationDurationOverride($animationDuration + 750ms, fadeLeft);
        }
        > *:nth-child(n + 5) {
          @include animationDurationOverride($animationDuration + 1050ms, fadeLeft);
        }
      }
      &:nth-child(3) {
        $animationDuration: 800ms;
        @include animationDurationOverride($animationDuration, fadeLeft);
        > *:nth-child(2) {
          @include animationDurationOverride($animationDuration + 250ms, fadeLeft);
        }
        > *:nth-child(3) {
          @include animationDurationOverride($animationDuration + 500ms, fadeLeft);
        }
        > *:nth-child(4) {
          @include animationDurationOverride($animationDuration + 750ms, fadeLeft);
        }
        > *:nth-child(n + 5) {
          @include animationDurationOverride($animationDuration + 1050ms, fadeLeft);
        }
      }
      &:nth-child(4) {
        $animationDuration: 1050ms;
        @include animationDurationOverride($animationDuration, fadeLeft);
        > *:nth-child(2) {
          @include animationDurationOverride($animationDuration + 250ms, fadeLeft);
        }
        > *:nth-child(3) {
          @include animationDurationOverride($animationDuration + 500ms, fadeLeft);
        }
        > *:nth-child(4) {
          @include animationDurationOverride($animationDuration + 750ms, fadeLeft);
        }
        > *:nth-child(n + 5) {
          @include animationDurationOverride($animationDuration + 1050ms, fadeLeft);
        }
      }
      &:nth-child(n + 5) {
        $animationDuration: 1200ms;
        @include animationDurationOverride($animationDuration, fadeLeft);
        > *:nth-child(2) {
          @include animationDurationOverride($animationDuration + 250ms, fadeLeft);
        }
        > *:nth-child(3) {
          @include animationDurationOverride($animationDuration + 500ms, fadeLeft);
        }
        > *:nth-child(4) {
          @include animationDurationOverride($animationDuration + 750ms, fadeLeft);
        }
        > *:nth-child(n + 5) {
          @include animationDurationOverride($animationDuration + 1050ms, fadeLeft);
        }
      }

      .label {
        position: relative;
        padding-left: $markerWidth + $spaceXxs;

        @include type('Label');

        &:before {
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          width: $markerWidth;
          height: $markerHeight;
          background-color: $colorGrey3;
        }

        &.executing:before {
          background-color: $colorCliftonExecuting;
        }
        &.influencing:before {
          background-color: $colorCliftonInfluencing;
        }
        &.relationship:before {
          background-color: $colorCliftonRelationship;
        }
        &.strategic:before {
          background-color: $colorCliftonStrategic;
        }
      }

      .employee {
        display: flex;
        align-items: center;
        margin-top: $spaceXs;
        height: 24px;

        .avatar {
          flex-basis: 24px;
        }
        .name {
          flex-grow: 1;
          padding-left: $spaceXxs;
        }
        .rank {
          flex-basis: 20px;

          color: $colorPrimary1;
        }

      }
    }
  }
  .missingStrengths {
    padding-top: $spaceXs;

    border-top: 1px solid $colorGrey3;

    @include type('Label');

    .list {
      margin-left: $spaceS;
      margin-top: $spaceXxs;

      @include type('Copy');
    }

  }
}
