@import 'shared.scss';

/* LISTNEXTCELL */
.listNextCell {
  position: relative; // for tooltip positioning.

  overflow: visible; // for tooltip

  /* INTERACTION */
  &:hover {
    .tooltip {
      display:block;
    }
    .tooltip + .texts .label {
      color: $colorPrimary1;
    }
  }

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  // TOOLTIP
  .texts {

    > span {
      display: block;

      // restrict width & show ellipsis
      width: 100%;

      // ellipsis
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include type('Hint');

      &.label {
        margin-bottom: 5px;
        color: $colorGrey2;
        @include type('Hint');
      }
      &.identifier {
        margin-bottom: 4px;
        @include type('Label');
      }
      &.value {
        height: 20px;
        line-height: 20px;
      }
    }

    // move to right when we have no label
    & .value:only-child {
      text-align: right;
    }

    // move ProgressCircle up when there's a label
    .label + .value [class*=ProgressCircle_progressCircle] {
      margin-top: -2px;
      transform: translateY(4px);
    }

    & .identifier:only-child {
      margin-bottom: 0;
    }

  }


  // TOOLTIP
  .tooltip {
    display: none;
    position: absolute;
    top: -#{space('M')};
    left: -#{space('L')};
    // right: 0px;
    padding: 4px 8px;

    @include type('Hint');

    white-space: nowrap;

    /* colorWhite */
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    /* shadow1 */

    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    z-index: 1;
  }

  // HIGHLIGHT
  .highlight {
    color: $colorPrimary1 !important;
  }


}
