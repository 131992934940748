@import 'shared.scss';

/* QUESTIONPOLICY */
.questionPolicy {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

  .checkBox {
    margin-top: 24px;
    label {
      @include type('CopyStrong');
      > span:first-child {
        margin-left: 10px;
        width: calc(100% - 46px);
        color: $colorGrey1 !important;
        .policy {
          color: $colorPrimary1 !important;
          cursor: pointer;
        }
      }
    }
  }
}
