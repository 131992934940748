@import 'shared';

body {
  overflow-y: auto;
  overflow-x: hidden;
}

.overview {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$spaceM});
  background-color: $colorGrey6;
  padding-bottom: space('M');

  .welcome {
    @include basicGrid;
    margin-top: space('M');
    height: 349px;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
    padding-left: space('M');
    box-sizing: border-box;
    // animation: slide-up $animationDuration $animationFunction;
    background-color: white;
    background-size: contain;

    @include mqSmaller($bpM) {
      background-color:rgba(0, 0, 0, 0);
    }

    .welcomeAltImage {
      display: none;
      border: 1px solid $colorGrey4;
      border-radius: 10px;
      width: 100%;
      grid-column: 1/13;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      background-color: white;
    }
    .content {
      grid-column: 1/5;
      margin: auto 0;
      .title {
        @include type('S');
        color: $colorGrey1;
      }
      .description {
        @include type('Copy');
        color: $colorGrey1;
        margin-top: space('Xs');
      }
      button {
        margin-top: space('S');
      }
    }
  }
  .teasers {
    @include basicGrid;
    margin-top: space('L') !important;
    margin-right: 0 !important; // breakt out to the right
    margin-left: 0px !important;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100%;
    .title {
      @include type('Xxs');
      color: $colorGrey1;
      grid-column: 1/13;
      animation: slide-up $animationDuration $animationFunction;
      margin-left: 60px;
    }
    .titleWithScrollable {
      grid-column: start/end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: slide-up $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      margin-right: 60px;

      @include type('Xxs');
      color: $colorGrey1;

      @include mqSmaller($bpS) {
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
    }
    .descriptionBlock {
      grid-column: start/end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: slide-up $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      margin-right: 60px;
      margin-top: space('Xs');
      .description {
        @include type('Copy');
        color: $colorGrey1;
      }
    }
    .subTeasers {
      grid-column: 1/13;
      animation: slide-up $animationDuration $animationFunction;
      max-width: 100%;

      margin: space('Xxs') 60px 0 60px;
      @include transition(margin-left);

      .myProfileTeaser {
        margin-top: space('Xs');
      }
    }
  }

  .teaser {
    min-width: 100%;
    margin-right: space('Xs');
    & > div:first-child {
      height: 100%;
      & > div:first-child {
        height: 100%;
        & > div:first-child {
          height: 100%;
        }
      }
    }
  }

  .onboardingTeasersContainer {
    $navWidth: 40px;

    display: flex;
    width: 100%;

    .onboardingTeasersNav {
      width: $navWidth;
      display: flex;
      align-items: center;
      > * {
        margin-top: (-40px - $spaceXs) / 2; // compensate for pagination
      }
    }
    .onboardingTeasers {
      padding: 0px $spaceXs;
      box-sizing: border-box;
      width: calc(100% - 2 * #{$navWidth});
    }

    @include mqSmaller($bpL) {
      .onboardingTeasersNav {
        display: none;
      }
      .onboardingTeasers {
        padding: 0;
        width: 100%;
      }
    }
  }


  .orgDevelopmentItems {
    @include basicGrid;
    margin-top: space('L');
    .title {
      @include type('Xxs');
      color: $colorGrey1;
      grid-column: start/end;
      animation: slide-up $animationDuration $animationFunction;
    }
    .description {
      grid-column: start/end;
      @include type('Copy');
      margin-top: space('Xxs');
    }
    .wellbeing {
      grid-column: start/5;
      margin-top: 17px;
      overflow: hidden;

      @include mqSmaller($bpS) {
        grid-column: start/end;
      }
    }
    .orgMindJourneyScore {
      grid-column: 5/end;
      margin-top: 17px;

      padding: 26px 35px 29px;
      box-sizing: border-box;
      background-color: $colorWhite;
      border: 1px solid $colorGrey4;
      border-radius: 10px;

      display: flex;
      align-self: baseline;

      @include mqSmaller($bpM) {
        flex-direction: column;
      }

      @include mqSmaller($bpS) {
        grid-column: start/end;
        flex-direction: column;

        padding: 15px 20px;
      }

      .upperBlock {
        .xxsTitle {
          @include type('Xxs');
          color: $colorGrey1;
          animation: slide-up $animationDuration $animationFunction;
        }

        .copyDescription {
          @include type('Copy');
          color: $colorGrey1;
          animation: slide-up $animationDuration $animationFunction;

          margin-top: space('Xxs');
        }

        .blocks {
          .smallBlock {
            padding: space('Xs') 0;
            border-top: 1px solid $colorGrey3;
            border-bottom: 1px solid $colorGrey3;
            margin-top: space('Xxs');

            .title {
              @include type('Label');
            }

            &:last-child {
              margin-top: 0;
              border-top: none;
            }
          }
        }
      }

      .mindfulnessJourneyScore {
        margin-left: 75px;

        @include mqSmaller($bpM) {
          margin-left: 0;
          margin-top: space('L');
        }
      }
    }

    &.wellBeingHidden {
      .orgMindJourneyScore {
        grid-column: start/9;

        @include mqSmaller($bpS) {
          grid-column: start/end;
        }
      }
    }

    @include mqSmaller($bpS) {
      margin-top: space('L');
    }
  }
  .other {
    @include basicGrid;
    margin-top: space('L');
    margin-right: 0 !important; // breakt out to the right
    margin-bottom: space('M');
    margin-left: 0px !important;
    max-width: 100%;
    overflow: hidden;
    .titleBlock {
      grid-column: 1/13;
      display: flex;
      justify-content: space-between;
      animation: slide-up $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      .title {
        @include type('Xxs');
        color: $colorGrey1;
        grid-column: 1/13;
      }
    }
    .actions {
      display: flex;
      margin-left: 0;
      grid-column: 1/13;
      animation: slide-up $animationDuration $animationFunction;
      @include transition(margin-left);
      max-width: 100%;
      overflow: auto;
      margin-left: 40px;
      padding-left: 20px;
      padding-bottom: 24px;
      .action {
        //calculation of width for each action teaser
        //108 = 60 + 48, where 60 - margin on the right of actions container, and 48 - margins between teasers
        min-width: calc((100% - 108px) / 4);
        max-width: calc((100% - 108px) / 4);
        border: 1px solid $colorGrey4;
        border-radius: 10px;
        margin-top: space('S');
        margin-right: space('Xs');
        cursor: pointer;
        box-sizing: border-box;

        background: $colorWhite;

        @include transition(box-shadow);
        &:hover {
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
        &:focus {
          outline: none;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
        .imageBlock {
          width: 100%;
          position: relative;
          //16:9 aspect ratio
          padding-top: 56.25%;
          overflow: hidden;
          img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .text {
          padding: 8px 28px 32px;
          .title {
            @include type('Label');
            color: $colorPrimary1;
            text-align: center;
          }
          .description {
            @include type('Copy');
            color: $colorGrey1;
            margin-top: space('Xxs');
            text-align: center;
          }
        }
      }
    }

    @include mqSmaller($bpS) {
      margin-top: space('L');
    }

    .actions::-webkit-scrollbar {
      display: none;
    }
  }

  .pagingButtons, .scrollingButtons {
    display: flex;

    & > div:first-child {
      margin-right: space('Xxs');
    }

    .left, .right {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border: 1px solid $colorGrey3;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(border);
      &.active {
        border-color: $colorPrimary1;
        @include transition(border-color, background-color);
        svg {
          path {
            fill: $colorPrimary1;
          }
        }
      }
      &.active:hover {
        background-color: $colorPrimary3;
        border-color: $colorPrimary2;
        svg {
          path {
            fill: $colorPrimary2;
          }
        }
      }
      svg {
        path {
          fill: $colorGrey3;
        }
      }
    }
    .left {
      margin-right: space('Xxs');
    }
  }
  @include mqSmaller($bpM) {
    .welcome {
      background-image: none !important;
      padding-left: 0;
      border: none;
      grid-column: 1/9;
      height: auto;
      .welcomeAltImage {
        display: block;
        height: 208px;
        grid-column: 1/9;
      }
      .content {
        grid-column: 1/9;
        margin-top: space('Xs');
      }
    }
    .teasers {
      grid-column: 1/9;
      .title {
        grid-column: 1/9;
      }
      .descriptionBlock {
        grid-column: start/end;
        .description {
          padding-right: 75px;
        }
      }
      .subTeasers {
        grid-column: 1/9;
      }
    }
    .other {
      .titleBlock {
        grid-column: 1/9;
      }
      .actions {
        grid-column: 1/9;
        .action {
          //calculation of width for each action teaser
          //92 = 60 + 32, where 60 - margin on the right of actions container, and 32 - margins between actions
          min-width: calc((100% - 96px) / 3);
          max-width: calc((100% - 96px) / 3);
        }
      }
    }
  }
  @include mqSmaller($bpS) {
    .welcome {
      background-image: none !important;
      padding-left: 0;
      border: none;
      grid-column: start/end;
      height: auto;
      margin-top: space('Xs');
      .welcomeAltImage {
        display: block;
        height: 216px;
        grid-column: start/end;
        background-size: cover;
      }
      .content {
        grid-column: start/end;
        margin-top: space('Xs');
      }
    }
    .teasers {
      grid-column: start/end;
      .title {
        grid-column: start/end;
        margin-left: 16px;
      }
      .descriptionBlock {
        grid-column: start/end;
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
      .subTeasers {
        grid-column: start/end;
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
    }
    .other {
      .titleBlock {
        margin-left: 16px;
      }
      .actions {
        grid-column: start/end;
        margin-left: 0;
        padding-left: 16px;
        .action {
          min-width: calc(100% - 148px);
          max-width: calc(100% - 148px);
        }
      }
    }
    .pagingButtons, .scrollingButtons {
      display: none;
    }
  }
}
