@import 'shared';

.assessmentTeaser {
  .container {
    background-color: $colorWhite;

    width: 100%;
    box-sizing: border-box;
    padding: 24px 32px;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(8, calc((100% - 112px) / 8));
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    .imageBlock {
      grid-column: 1/4;
      text-align: center;
    }
    .textBlock {
      grid-column: 1/9;
      .description {
        @include type('Copy');
        margin-top: 8px;
        color: $colorGrey1;
      }
      .bottomContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-top: 24px;
        .scopeInfo {
          @include type('Label');
        }
      }
    }
    &.imageVisible {
      padding: 24px 0 32px;
      .textBlock {
        grid-column: 4/9;
        padding-right: 32px;
        .bottomContainer {
          flex-direction: column-reverse;
          align-items: baseline;
          .scopeInfo {
            margin-top: 16px;
          }
        }
      }
    }

    @include mqSmaller($bpM) {
      grid-column: 5/9;
      padding: 24px 16px 32px 16px;
      grid-template-columns: repeat(4, auto);
      .imageBlock {
        grid-column: 1/5;
      }
      .textBlock {
        grid-column: 1/5 !important;
        .bottomContainer {
          flex-direction: column-reverse;
          align-items: baseline;
          margin-top: 16px;
          .scopeInfo {
            margin-top: 16px;
          }
        }
      }
      &.imageVisible {
        padding: 0;
        .textBlock {
          padding: 8px 16px 32px;
        }
      }
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      padding: 24px 16px 32px 16px;
      grid-template-columns: repeat(4, auto);
      .imageBlock {
        grid-column: start/end;
      }
      .textBlock {
        grid-column: start/end !important;
        .bottomContainer {
          flex-direction: column-reverse;
          align-items: baseline;
          margin-top: 16px;
          .scopeInfo {
            margin-top: 16px;
          }
        }
      }
      &.imageVisible {
        padding: 0;
        .textBlock {
          padding: 8px 16px 32px;
        }
      }
    }
  }
}
