@import 'shared';

.survey {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: $colorWhite;
  color: $colorGrey1;
  .header {
    @include type('Label');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      @include type('Copy');
      color: $colorPrimary1;
      cursor: pointer;
      &:hover {
        color: $colorPrimary2;
      }
    }
  }
  .content {
    height: calc(100vh - 61px);
    overflow-y: auto;
  }
  .gridContent {
    @include basicGrid;
    margin-bottom: space('L');
    .main {
      grid-column: 4/10;
      color: $colorGrey1;
      .surveyImage {
        //16:9 aspect ration
        padding-top: 56.25%;
        position: relative;
        svg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .title {
        @include type('M');
        color: $colorPrimary1;
        margin-top: space('Xs');
        text-align: center;
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs');
        text-align: center;
      }
      .block {
        margin-top: space('L');
        .blockTitle {
          @include type('S');
        }
        > div:last-child {
          margin-top: space('S');
        }
        .circles {
          display: flex;
          justify-content: center;
          margin-top: space('M');
          .circleBlock {
            width: 40px;
            margin-right: space('Xs');
            .circle {
              width: 40px;
              height: 40px;
              background-color: $colorGrey4;
              border-radius: 50%;
              cursor: pointer;
              @include transition(background-color);
              &:hover {
                background-color: $colorPrimary3;
              }
              &.active {
                background-color: $colorPrimary1;
              }
            }
            .number {
              @include type('Copy');
              color: $colorGrey2;
              margin-top: space('Xxs');
              text-align: center;
            }

            @include mqSmaller($bpM) {
              width: 20px;
              margin-right: space('Xxs');
              .circle {
                width: 20px;
                height: 20px;
              }
            }

            @include mqSmaller($bpS) {
              width: 20px;
              margin-right: space('Xxs');
              .circle {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
      button {
        display: block;
        margin-left: auto;
        margin-top: space('M');
      }
      //FINAL PAGE
      .finalPage {
        margin-top: 100px;
        text-align: center;
        .emoji {
          font-size: 48px;
          line-height: 48px;
          @include mqSmaller($bpS) {
            font-size: 32px;
            line-height: 32px;
          }
        }
        .finalPageTitle {
          @include type('S');
          margin-top: space('Xs');
        }
        button {
          margin: 28px auto auto;
        }

        @include mqSmaller($bpS) {
          margin-top: 62px;
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    .gridContent {
      .main {
        grid-column: 2/8;
      }
    }
  }
  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .gridContent {
      margin-bottom: space('L') !important;
      .main {
        grid-column: start/end;
      }
    }
  }
}
