@import 'shared.scss';

/* QUESTIONREQUIREMENTS */
.questionRequirements {


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
