@import 'shared.scss';

/* LOGOUPLOAD */
.logoUpload {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .preview  {
    margin-top: $spaceS;
    width: $logoWidth;
    height: $logoHeight;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
    }
  }

  .delete {
    margin-top: $spaceS;
  }
}
