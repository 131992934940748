@import 'shared';

.sliderRoot {
  position: relative;
  margin: auto;


  // MODIFIERS
  &.sizeL {
    height: 41px; //40px + 1px
  }
  &.sizeS {
    height: 25px;//24px + 1px
  }
  // fix value / thumb overlap issues when values get too close
  &.hasValueOverlapRight {
    .thumb:nth-last-child(2) {
      z-index: z('Buffer3') !important;
    }
  }
  &.hasValueOverlapLeft {
    .thumb:nth-last-child(1) {
      z-index: z('Buffer3') !important;
    }
  }

  // CHILDREN
  .circles {
    position: absolute;
    height: 100%;
    width: 100%;

    $i: 0;
    $step: 11.03%;
    @for $i from 0 through 10 {
      .circle:nth-child(#{$i + 1}) {
        position: absolute;
        top: calc(50% - 2.5px);
        width: 4px;
        height: 4px;
        background-color: $colorGrey3;
        left: $step * $i;
        border-radius: 50%;
        z-index: zIndex('Buffer1');
      }
    }
  }
  .verticalLine {
    position: absolute;
    height: calc(100% - 1px);
    width: 1px;
    left: 50%;
    background-color: $colorGrey3;
    z-index: zIndex('Buffer1');
  }
  .rmpLines {
    .line {
      position: absolute;
      height: calc(100% - 1px);
      width: 2px;
      background-color: $colorGrey3;
      z-index: zIndex('Buffer1');
    }
    .center {
      left: 50%;
    }
    .left1 {
      left: 29%;
      height: 24px;
      top: 8px;
    }
    .left2 {
      left: 7.5%;
      height: 13px;
      top: 13.5px;
    }
    .right1 {
      left: 71%;
      height: 24px;
      top: 8px;
    }
    .right2 {
      left: 92.5%;
      height: 13px;
      top: 13.5px;
    }
  }
  .slider {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }
  .track {
    position: relative;
    height: 2px;
    background-color: $colorGrey3;
    &.blue {
      height: 2px;
      top: 0;
      background-color: $colorGrey3;
      border-radius: 20px;
    }
  }
  .thumb, .thumb2 {
    background-color: $colorPrimary1;
    cursor: pointer;
    top: 1px;
    height: 100%;
    border-radius: 50%;
    line-height: 48px;
    z-index: zIndex('Buffer2');
    svg {
      position: absolute;
      top: 13px;
      left: 4px;
      path {
        fill: $colorPrimary1;
      }
    }
    svg:last-child {
      right: 4px;
      left: unset;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  .thumb2 {
    background-color: $colorWhite;
    box-sizing: border-box;
    border: 1px solid $colorPrimary3;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    &:hover {
      border: 1px solid $colorPrimary1;
    }
  }

  &.sizeL {
    //TRACK OF SIZE L
    .track {
      top: 19px;
      &.blue {
        top: 0;
        border-top: 19px solid $colorPrimary3;
        border-bottom: 19px solid $colorPrimary3;
      }
    }

    //THUMBS OF SIZE L
    .thumb, .thumb2 {
      width: 40px;
      height: calc(100% - 1px);
    }
  }

  &.sizeS {
    //TRACK OF SIZE S
    .track {
      top: 11px;
      &.blue {
        top: 0;
        border-top: 11px solid $colorPrimary3;
        border-bottom: 11px solid $colorPrimary3;
      }
    }

    //THUMBS OF SIZE S
    .thumb, .thumb2 {
      width: 24px;
      height: calc(100% - 1px);
    }

    //RMP LINES OF SIZE S
    .rmpLines {
      .left1 {
        left: 29%;
        height: 16px;
        top: 4px;
      }
      .left2 {
        left: 7.5%;
        height: 8px;
        top: 8px;
      }
      .right1 {
        left: 71%;
        height: 16px;
        top: 4px;
      }
      .right2 {
        left: 92.5%;
        height: 8px;
        top: 8px;
      }
    }
  }

}
