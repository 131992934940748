@import 'shared';

.competenciesResult {
  background-color: $colorWhite;

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: space('M') space('M') 0;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }

    @include mqSmaller($bpM) {
      padding: space('S') space('Xs') 0;
    }
  }

  .container {
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    box-sizing: border-box;

    padding-bottom: space('L');

    @include mqSmaller($bpM) {
      padding-bottom: space('M');
    }
  }

  .rows {
    margin-top: space('Xs');
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid $colorGrey3;
      padding-top: 8px;
      padding-bottom: 8px;
      .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        .left {
          flex: 1;
        }
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          .points {
            width: 28px;
            margin-left: 16px;
            .blue {
              color: $colorPrimary1;
            }
          }
          .name {
            padding-left: 8px;
          }
        }
      }
      .body {
        padding: 0 32px;
        .subItemRow {
          display: flex;
          align-items: center;
          padding-bottom: 24px;
          .right {
            margin-left: 16px;
          }
          &:first-child {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');

    @include mqSmaller($bpM) {
      margin-right: space('Xs');
      margin-left: space('Xs');
    }
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;

    margin: space('Xs') space('M') 0;

    @include mqSmaller($bpM) {
      margin: space('Xs') space('Xs') 0;
    }
  }

  @include mqSmaller($bpM) {
    overflow-x: hidden;
    .rows {
      .row {
        position: relative;
        padding: 0 0 8px;
        .competenciesCollapsibleHeader {
          div:last-child {
            svg {
              position: absolute;
              right: 16px;
              top: 19px;
            }
          }
        }
        .header {
          flex-direction: column-reverse;
          align-items: flex-start !important;
          position: relative;
          padding-right: space('Xxs');
          .left {
            margin-top: 10px;
          }
          .right {
            width: 100%;
            flex-direction: row-reverse;;
            justify-content: space-between;
            margin-left: 0;
            .name {
              padding-left: 0;
            }
          }
        }
        .body {
          width: 100%;
          box-sizing: border-box;
          padding: 0 45px 0 32px;
          .subItemRow {
            flex-direction: column-reverse;
            .right {
              width: 100%;
              margin-left: 0;
              .competenciesInfoTitle {
                margin-left: 0;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    .rows {
      .row {
        position: relative;
        padding: 8px 0 16px;
        .competenciesCollapsibleHeader {
          div:last-child {
            svg {
              position: absolute;
              right: 16px;
              top: 27px;
            }
          }
        }
        .header {
          flex-direction: column-reverse;
          align-items: flex-start !important;
          position: relative;
          padding-right: space('Xs');
          .left {
            margin-top: 10px;
          }
          .right {
            width: 100%;
            flex-direction: row-reverse;;
            justify-content: space-between;
            margin-left: 0;
            .name {
              padding-left: 0;
            }
          }
        }
        .body {
          width: 100%;
          box-sizing: border-box;
          padding: 0 34px;
          .subItemRow {
            flex-direction: column-reverse;
            .right {
              width: 100%;
              margin-left: 0;
              .competenciesInfoTitle {
                margin-left: 0;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
}
