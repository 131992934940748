@import 'shared.scss';

$loaderHeight: 24px;

/* LOADINGHINT */
.loadingHint {
  display: flex;
  min-height: $loaderHeight;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .loader {
    width: $loaderHeight;
    height: $loaderHeight;

    align-self: flex-start;

    @include animationLonger(rotate);
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  .hint {
    align-self: center;

    padding-left: $spaceXxs;
    padding-top: $spaceXxxs;
    min-height: $loaderHeight;
    @include type('Hint');
  }
}
