@import 'shared.scss';

/* QUESTIONINDUSTRY */
.questionIndustry {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
