@import 'shared.scss';

.enter {
  pointer-events:none;
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
}
.exitActive, .exitDone {
  display: none;
}
