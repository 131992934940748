@import 'shared';

.big5Report {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $colorLight;
  left: 0;
  top:0;
  z-index: zIndex('Modal');

  animation: slide-up .3s ease-in-out;

  .header {
    @include type('Label');
    color: $colorPrimary1;
    height: 60px;
    padding: 20px 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      cursor: pointer;
    }
  }
  .bodyContainer {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .body {
    @include basicGrid;
    .main {
      grid-column: 4/10;
      padding: 48px 0;
      .title {
        @include type('M');
        color: $colorGrey1;
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        .content {
          @include type('Copy');
          color: $colorPrimary1;
          margin-right: 24px;
          margin-top: 16px;
          cursor: pointer;
          text-decoration: none;
          svg {
            margin-left: 8px;
          }
        }
      }
      .dimension {
        margin-top: 48px;
        .title {
          @include type('S');
          color: $colorGrey1;
        }
        .description {
          @include type('Copy');
          color: $colorGrey1;
          margin-top: 16px;
        }
        .score {
          margin-top: 24px;
          .row1, .row2 {
            @include type('Copy');
            display: flex;
            justify-content: space-between;
            .text {
              color: $colorGrey1;
            }
            .number {
              color: $colorPrimary1;
            }
          }
          svg {
            margin-top: 8px;
          }
          .row2 {
            @include type('Hint');
            color: $colorGrey2;
          }
        }
        .callOutContainer {
          margin-top: space('Xs');
        }
        .facets {
          margin-top: 32px;
          .title {
            @include type('Xs');
            color: $colorGrey1;
          }
          .facet {
            margin-top: 32px;
            .title {
              @include type('Xxs');
              color: $colorGrey1;
            }
            .description {
              @include type('Copy');
              color: $colorGrey1;
              margin-top: 8px;
            }
            .score {
              margin-top: 24px;
              .row1, .row2 {
                @include type('Copy');
                display: flex;
                justify-content: space-between;
                .text {
                  color: $colorGrey1;
                }
                .number {
                  color: $colorPrimary1;
                }
              }
              svg {
                margin-top: 8px;
              }
              .row2 {
                @include type('Hint');
                color: $colorGrey2;
              }
            }
          }
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    .body {
      .main {
        grid-column: 2/8;
      }
    }
  }
  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .body {
      .main {
        grid-column: start/end;
      }
    }
  }
}

