@import 'shared';

.enter, .enterActive {
  opacity: 0 !important;
}
.enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit, .exitActive, .exitDone {
  opacity: 0!important;
}

.userInvitation {
  .header {
    @include type('Label');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;
    }
  }
  .bodyContainer {
    height: calc(100vh - 61px);
    overflow-y: auto;
  }
  .body {
    @include basicGrid;
    .main {
      grid-column: 4/10;
      padding-top: space('L');
      padding-bottom: space('L');
      animation: slide-up $animationDuration $animationFunction;
      .title {
        @include type('M');
        color: $colorGrey1;
      }
      .emails {
        margin-top: space('S');
        .description {
          @include type('Copy');
          color: $colorGrey1;
        }
        .error {
          @include type('Copy');
          color: $colorDanger1;
          margin-top: space('Xs');
        }
        .hotChips {
          margin-top: space('S');
        }
      }
      .userGroups {
        margin-top: space('S');;
        .description {
          @include type('Copy');
          color: $colorGrey1;
        }
        .select {
          margin-top: space('Xs');
          .option {
            .optionTitle {
              @include type('Copy');
            }
            .optionDescription {
              @include type('Hint');
              color: $colorGrey2;
              margin-top: space('Xxs');;
              white-space: pre-wrap;
            }
          }
        }
      }
      .customBlock {
        @include type('Copy');
        color: $colorGrey1;
        margin-top: space('S');
        padding: 18px 0;
        border-top: 1px solid $colorGrey4;
        border-bottom: 1px solid $colorGrey4;
        .visible {
          display: flex;
          justify-content: space-between;
        }
        .customEmail {
          margin-top: space('S');
          opacity: 1;
          animation: fade-in $animationDuration $animationFunction;
          .title {
            @include type('Copy');
            color: $colorGrey1;
          }
          .textarea {
            margin-top: space('Xs');
          }
        }
      }

      .assessments {
        margin-top: space('M');
        .xxsTitle {
          @include type('Xxs');
        }
        .list {
          & > label {
            margin-top: space('Xs');
          }
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: space('Xs');
        button {
          margin-top: space('Xs');
          margin-right: space('Xs');
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    .body {
      .main {
        grid-column: 2/8;
      }
    }
  }
  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .body {
      .main {
        grid-column: start/end;
        .emails {
          .emailInputs {
            .input {
              width: 100%;
            }
          }
        }
        .buttons {
          flex-direction: column;
          button {
            align-self: baseline;
          }
        }
      }
    }
  }
}
