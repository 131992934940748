@import 'shared.scss';

/* QUESTIONLOCATION */
.questionLocation {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
