@import 'shared';

/* ASSESSMENTREGISTRATION */
.assessmentRegistration {
  text-align: left;

  .xxsTitle {
    @include type('Xxs');
  }

  .assessmentLanguage {
    margin-top: 16px;
    .label {
      @include type('Copy');
      color: $colorGrey1;
      padding-bottom: 8px;
    }
    .languageSelect {
      width: 50%;
    }
  }

  .staticInfo {
    margin-top: 32px;
    .label {
      @include type('Label');
      color: $colorGrey1;
    }
    .description {
      @include type('Copy');
      color: $colorGrey1;
      margin-top: 8px;
    }
    .columns {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .left, .right {
        width: 49%;
        .select, .input {
          margin-bottom: 16px;
        }
      }
    }
    .mandatory {
      @include type('Copy');
      color: $colorGrey1;
    }
    .checkboxes {
      margin-top: 24px;
      .checkbox {
        margin-bottom: 16px;
        .policy {
          color: $colorPrimary1;
          cursor: pointer;
        }
        label {
          span:first-child {
            width: calc(100% - 46px);
          }
        }
      }
    }
    button {
      display: block;
      margin-top: 8px;
      margin-left: auto;
    }
    .copyright {
      margin-top: space('S');
      text-align: center;
      span {
        @include type('Hint');
        color: $colorPrimary1;
        cursor: pointer;
        @include transition(color);
        &:hover {
          color: $colorPrimary2;
        }
      }
    }
  }

  .title {
    @include type('M');
    color: $colorPrimary1;
    text-align: center;
  }
  .description {
    @include type('CopyStrong');
    color: $colorGrey1;
    text-align: left;
    margin-top: space('Xs');
    margin-bottom: space('S');
  }
}

.registrationPending {
  .clockEmoji {
    font-size: 32px;
  }
  .sTitle {
    @include type('S');
    margin-top: space('Xs');
  }
  .copy {
    @include type('Copy');
    margin-top: space('S');
  }
  button {
    margin-top: space('S');
  }
}
