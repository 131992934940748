@import 'shared';

.myProfileTeaser {
  .content {
    display: flex;
    overflow: hidden;

    //IMAGE BLOCK
    .imageBlock {
      flex: 1;
      text-align: center;
      position: relative;

      img {
        display:block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    //TEXT BLOCK
    .textBlock {
      flex: 1;
      box-sizing: border-box;
      margin-left: space('Xs');
      padding: space('M') space('S') space('L') 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .xxsTitle {
        @include type('Xxs');
      }

      .copy {
        @include type('Copy');
        margin-top: space('Xxxs');
        white-space: pre-wrap;
      }

      button {
        margin-top: space('Xs');
      }

      .label {
        @include type('Label');
        margin-top: space('Xs');
      }
    }

    @include mqSmaller($bpM) {
      .textBlock {
        padding: space('Xs') space('Xs') space('M');
      }
    }

    @include mqSmaller($bpS) {
      flex-direction: column;

      .textBlock {
        margin-left: 0;
        padding: space('Xs') space('Xs') space('M');
      }
    }
  }
}
