@import 'shared.scss';


// forwards page transitions
$pageForwardsInDuration: 400ms;
$pageForwardsInDelay: 150ms;
$pageForwardsOutDuration: 200ms;

$pageBackwardsInDuration: 600ms;
$pageBackwardsInDelay: 600ms;
$pageBackwardsOutDuration: 600ms;

:export {
  pageForwardsInDuration: $pageForwardsInDuration;
  pageForwardsInDelay: $pageForwardsInDelay;
  pageForwardsOutDuration: $pageForwardsOutDuration;
  pageBackwardsInDuration: $pageBackwardsInDuration;
  pageBackwardsInDelay: $pageBackwardsInDelay;
  pageBackwardsOutDuration: $pageBackwardsOutDuration;
}


// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
  @include transitionLong(opacity);
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transitionLong(opacity);
}
.exit {
  opacity: 1 !important;
  @include transitionLong(opacity);
}
.exitActive, .exitDone {
  opacity: 0 !important;
  @include transitionLong(opacity);
}

/* ASSESSMENTNEXT */
.assessmentNext {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  z-index: z('FullscreenOverlay');

  background-color: rgba(215,215,215,0.3);
  backdrop-filter: blur(10px);

  animation: show $animationDurationLong;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes moveUp {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(-30px);
      opacity: 0;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */
  &.hide {
    pointer-events: none;
    animation: hide $animationDurationLong;
    animation-delay: $animationDurationLong * 2;
    animation-fill-mode: both;
  }

  /* RESPONSIVE */

  /* CHILDREN */

  // OVERLAY
  .overlay {
    height: 100%;

    background-color: white;

    opacity: 0;
    animation: show $animationDurationLong;
    animation-delay: $animationDuration;
    animation-fill-mode: both;

    &.hide {
      pointer-events: none;
      animation: hide $animationDurationLong;
      animation-delay: $animationDurationLong + $animationDuration;
      animation-fill-mode: both;
    }
  }

  // HEADER
  .header {
    position: relative;

    $borderHeight: 1px;

    display: flex;
    align-items: center;
    height: $headerHeight;

    border-bottom: $borderHeight solid $colorGrey4;
    background-color: white;

    z-index: z('HomeHeader');

    transform: translateY(-#{$headerHeight + $borderHeight});

    animation: headerIn $animationDurationLong;
    animation-delay: $animationDurationLong;
    animation-fill-mode: both;



    @keyframes headerIn {
      from {
        transform: translateY(-#{$headerHeight} + $borderHeight);
      }
      to {
        transform: translateY(0px);
      }
    }
    @keyframes headerOut {
      from {
        transform: translateY(0px);
      }
      to {
        transform: translateY(-#{$headerHeight} + $borderHeight);
      }
    }

    &.hide {
      pointer-events: none;
      animation: headerOut $animationDurationLong;
      animation-delay: $animationDuration;
      animation-fill-mode: both;
    }

  }

  // PROGRESS BAR
  .progressBarContainer {
    position: relative;

    z-index: 1; // let assessmentContent vanish under it

    @include transitionLong(margin);
    animation: progressIn $animationDurationLong;
    animation-delay: $animationDurationLong + $animationDuration / 2;
    animation-fill-mode: both;


    @keyframes progressIn {
      from {
        opacity: 0;
        transform: translateY(-#{$headerHeight});
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes progressOut {
      from {
        transform: translateY(0px);
      }
      to {
        opacity: 0;
        transform: translateY(-#{$headerHeight});
      }
    }

    &.hide {
      animation: progressOut $animationDurationLong;
      animation-delay: $animationDuration;
      animation-fill-mode: both;
    }

    &.hideInBetween {
      margin-top: -#{$progressBarHeight};
      margin-bottom: $progressBarHeight; // no content jump for everything belwo
    }


    .progressBar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $progressBarHeight;
    }
  }

  // ASSESSMENT CONTENT
  .assessmentContent {
    position: relative;

    min-height: calc(100vh - #{$headerHeight});
    padding-top: $progressBarHeight;
    text-align: center;

    overflow: hidden;
    opacity: 0;

    animation: contentIn $animationDurationLong;
    animation-delay: $animationDurationLong + $animationDuration;
    animation-fill-mode: both;

    @keyframes contentIn {
      from {
        opacity: 0;
        transform: translateY(#{$headerHeight});
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes contentOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    &.canScroll {
      overflow: auto;
    }

    &.hide {
      pointer-events: none;
      animation: contentOut $animationDurationLong;
    }
  }

  // BACK ARROW
  .backArrowContainer, .forwardArrowContainer {
    position: absolute;

    display: flex;
    justify-content: center;

    width: 100%;
    top: 28px;

    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;

    z-index: 1;

    @include transitionLong(opacity);
    // transition-delay: $animationDurationLong;

    &.hideButton {
      pointer-events:none;
      opacity: 0;
      // transition-delay: 0ms;
    }
    &.hideImmediately {
      transition-duration: 0ms;
    }
    &.preAnimation {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }

    @include mqSmaller ($bpM) {
      top: 8px;
    }

    .arrow {
      -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
      background-color: white;

      @include iconWithBackgroundGrey;
    }

  }

  .forwardArrowContainer {
    position: relative;
    top: -$spaceXs;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;

    @include mqSmaller($bpS) {
      bottom: space('Xs');
    }

    span {
      @include type('Hint');
      color: $colorPrimary1;
      cursor: pointer;
      @include transition(color);
      &:hover {
        color: $colorPrimary2;
      }
    }

    @include mqSmaller($bpS) {
      position: relative;
      margin-top: space('Xxs');
    }
  }

  // PAGE
  .page {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;

    box-sizing: border-box;
    top: 0;
    width: 100%;
    padding: $spaceXxl $spaceM $spaceM $spaceM;

    // MODIFIERS
    &.begin {
      &:nth-child(2) {
        opacity: 0;
      }
    }

    &.forwards {
      &.in {
        animation: pageForwardsIn $pageForwardsInDuration;
        animation-delay: $pageForwardsInDelay;
        animation-fill-mode: both;
      }
      &.out {
        pointer-events: none;
        animation: pageForwardsOut $pageForwardsOutDuration;
        animation-fill-mode: both;
      }
    }
    &.backwards {
      &.in {
        animation: pageBackwardsIn $pageBackwardsInDuration ease-out;
        animation-delay: $pageBackwardsInDelay;
        animation-fill-mode: both;
      }
      &.out {
        pointer-events: none;
        animation: pageBackwardsOut $pageBackwardsOutDuration;
        animation-fill-mode: both;
      }
    }


    @keyframes pageForwardsIn {
      from {
        opacity: 0;
        transform: translateY(30px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes pageForwardsOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes pageBackwardsIn {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes pageBackwardsOut {
      from {
        opacity: 1;
        transform: translateY(0px);
      }
      to {
        opacity: 0;
        transform: translateY(30px);
      }
    }


    // RESPONSIVE
    @include mqSmaller($bpM) {
      padding: $spaceL $spaceXs $spaceXs $spaceXs;
    }


    .pageContent {
      width: 100%;
      min-height: 100%;
      position: relative;

      padding-bottom: space('L');
    }

  }

}
