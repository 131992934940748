@import 'shared';


.wellbeingSuccessContent {
  text-align: center;
}

.wellBeingHint {
  //TITLE
  .collapsiblePanelTitle {
    @include type('Hint');
    color: $colorGrey2;
    cursor: pointer;
    @include transition(color);

    svg {
      margin-left: space('Xxs');
      vertical-align: middle;
      transform: scale(1);

      @include transition(transform)
    }

    &.active {
      color: $colorPrimary1;
      svg {
        transform: scale(-1);
        path {
          fill: $colorPrimary1;
        }
      }
    }
  }
  //BODY
  .collapsiblePanelBody {
    @include type('Hint');
    margin-top: space('Xs');

    animation: fade-in $animationDurationLong $animationFunction;
  }
}

.wellBeingContent {
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .title {
    @include type('Xxs');
  }

  .emojis {
    display: flex;
    justify-content: center;
    margin-top: space('S');

    svg {
      margin-left: space('S');
      cursor: pointer;
      flex-shrink: 0;
      @include transition(fill);

      &.active {
        path {
          fill: $colorPrimary1;
        }
      }

      &:hover {
        path {
          fill: $colorPrimary1;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .personalNotice {
    margin-top: space('Xs');
    @include type('Copy');
    textarea {
      margin-top: space('Xs');
    }
    button {
      margin-top: space('S');
      display: block;
      margin-left: auto;
    }
  }

  @include mqSmaller($bpS) {
    .emojis {
      svg {
        margin-left: 14px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
