@import 'shared';

.completeProfile {
  @include basicGrid;
  background-color: white;

  .container {
    grid-column: 5/9;
    margin-top: 128px;
    margin-bottom: 48px;
    .title {
      @include type('S');
      color: $colorGrey1;
      text-align: center;
    }
    .description {
      @include type('Copy');
      color: $colorGrey1;
      margin-top: 16px;
      text-align: center;
    }
    .infoBlock1 {
      margin-top: 24px;
      & > * {
        margin-top: 16px;
      }
    }
    .infoBlock2 {
      margin-top: 24px;
      .title {
        @include type('Label');
        text-align: left;
      }
      .selects {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        .select {
          width: calc((100% / 2) - 8px);
        }
      }
    }
    .checkBox {
      margin-top: 24px;
      label {
        span:first-child {
          width: calc(100% - 46px);
          color: $colorGrey1 !important;
          .policy {
            color: $colorPrimary1 !important;
            cursor: pointer;
          }
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      button {
        margin-top: 24px;
      }
      .logOut {
        @include type('Copy');
        color: $colorPrimary1;
        cursor: pointer;
        margin-top: 24px;
      }
    }
  }

  @include mqSmaller($bpM) {
    .container {
      grid-column: 3/7;
      .buttons {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      .buttons {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }
}
