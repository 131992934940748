@import 'shared';

.userIndividualSettings {
  @include basicGridNoMargins;
  .left {
    grid-column: start/5;
    .buttons {
      margin-top: space('S');
      display: flex;
      button {
        margin-right: space('Xs');
      }
    }
  }
  .right {
    grid-column: 6/12;
    color: $colorGrey1;
    .title {
      @include type('Xxs');
    }
    .description {
      @include type('Copy');
      margin-top: space('Xs');
    }
    .userGroups {
      margin-top: space('Xs');
      .title {
        @include type('Label');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xxs');
      }
      .selectTitle {
        @include type('Copy');
        margin-top: space('Xs');
      }
      .select {
        margin-top: space('Xs');
        .option {
          .optionTitle {
            @include type('Copy');
            color: $colorGrey1;
          }
          .optionDescription {
            @include type('Hint');
            color: $colorGrey2;
            margin-top: space('Xxs');;
            white-space: pre-wrap;
          }
        }
      }
    }

    .assessments {
      margin-top: space('L');
      .xxsTitle {
        @include type('Xxs');
      }
      .copy {
        @include type('Copy');
        margin-top: space('Xs');
      }
      .list {
        margin-top: space('Xs');
        .listItem {
          padding: space('Xs');
          border-bottom: 1px solid $colorGrey4;

          @include type('Copy');

          &:first-child {
            border-top: 1px solid $colorGrey4;
          }

          .row1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .row2 {
            width: 90%;
            margin-top: space('Xxs');

            color: $colorGrey2;
          }
        }
      }
    }

    .teams {
      margin-top: space('M');
      .title {
        @include type('Label');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xxs');
      }
      button {
        margin-top: space('S');
      }
    }
  }

  .toastTitle {
    @include type('Label');
    color: $colorGrey1;
    margin-right: 32px;
    overflow: hidden;
  }
  .toastDescription {
    @include type('Copy');
    color: $colorGrey1;
    margin-top: space('Xxs');
  }

  @include mqSmaller($bpM) {
    .right {
      grid-column: 5/end;
    }
  }
  @include mqSmaller($bpS) {
    .right {
      grid-column: start/end;
      margin-top: space('M');
    }
  }
}
