@import 'shared.scss';

$rowHeight: 45px;
$barHeight: 37px;
$markerWidth: 30px;
$labelMaxWidth: 160px;
$paddingBottom: 20px;
$lineWidth: 1px;

/* TEAMDIAGRAMBIPOLARNEXT */
.teamDiagramBipolarNext {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */


  // LABELS
  .labels {
    margin-top: $spaceS;
    margin-bottom: $spaceXxs;

    display: flex;

    @include type('Hint');
    color: $colorGrey2;

    > div {
      flex-grow: 1;
    }

    .labelLeft {
      text-align: left;
    }
    .labelCenter {
      text-align: center;
    }
    .labelRight {
      text-align: right;
    }
  }


  // DIAGRAM CONTAINER
  .diagramContainer {
    position: relative;


    // GRID
    .grid {
      position: absolute;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .line {
        position: absolute;
        top: 0;
        left: 0;

        width: 1px;
        height: 100%;

        background-color: $colorGrey4;
      }

      .lineCenter {
        position: absolute;
        top: 0;
        left: 0;

        margin-left: calc((100% - 1px) / 2);
        width: 1px;
        height: 100%;

        background-color: $colorPrimary3;
      }
    }


    // BAR CONTAINER
    .barContainer {
      cursor: pointer;

      position: relative;
      height: $rowHeight;
      width: 100%;

      display: flex;

      z-index: 1;

      &:last-child {
        height: $barHeight;
      }

      // INTERACTION
      &:hover {
        .barHover {
          transform: scale(1);
        }
        .markerLabel {
          color: $colorPrimary1;
        }
        .markerMovable [class*=ImgPin] svg path {
          fill: $colorPrimary15;
        }
      }

      // CHILDREN
      .markerContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .markerMovable {
        display: flex;

        transform: translateX(- #{$markerWidth / 2});

        @include transition(margin);

        .markerLabel {
          user-select: none;
          flex-shrink: 0;

          box-sizing: border-box;
          height: 100%;
          max-width: $labelMaxWidth;
          padding: 0 $spaceXxs;

          @include type('LabelDiagram');
          line-height: $barHeight !important;
          @include ellipsis();

          @include transition(transform, color);

          &.moveToLeft {
            transform: translateX(calc(-100% - #{$markerWidth}));
          }
        }
      }

      .barLeft, .barRight {
        width: 50%;

      }

      .barLeft {
        display: flex;
        justify-content: flex-end;

        .barHover {
          left: unset !important;
          right: 0 !important;
          transform-origin: 100% 0;
        }
      }

      .bar {
        position: relative;

        height: $barHeight;
        width: 100%;

        display: flex;
        justify-content: flex-end;

        @include transition(width);
      }

      .barFill {
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: $colorPrimary1;
        opacity: 0.05;
        @include transition(width, opacity);
      }

      .barHover {
        pointer-events: none;
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: $colorPrimary1;
        opacity: 0.05;

        transform: scaleX(0);
        transform-origin: 0 0;

        @include transition(transform);
      }

    }


    // RANGE CONTAINER
    .rangeContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .range {
        width: 0%;
        height: 100%;

        border: 1px dashed rgba($colorPrimary1, 0.5);
        border-top: 0;
        border-bottom: 0;

        background-color: rgba($colorPrimary4, 0.8);

        @include transition(width, margin-left);
      }

    }
  }

  .rangeLabel {
    margin-top: $spaceS;
    text-align: right;
    @include type('Copy');

    .rangeLegend {
      display: inline-block;
      width: 30px;
      height: 20px;
      margin-right: $spaceXxs;
      margin-bottom: -5px;

      border: 1px dashed rgba($colorPrimary1, 0.5);
      border-top: 0;
      border-bottom: 0;

      background-color: rgba($colorPrimary4, 0.8);
    }

    .highlight {
      margin-left: $spaceXxxs;
      color: $colorPrimary1;
    }
  }
}
