@import 'shared.scss';

/* CandidateUserGroup */
.candidateUserGroup {
  @include basicGridNoMargins;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    grid-column: start/mid;

    @include mqSmaller($bpM) {
      grid-column: start/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
}
