@import 'shared.scss';

/* STANDALONEWRAPPER */
.standaloneWrapper {

  position: fixed; // to break out of mainLayout
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background-color: white;

  z-index: zIndex('Standalone');
  // overflow-y: auto;



  /* INTERACTION */

  /* MODIFIERS */
  &.compensateHeader {
    .header {
      position:relative;
      z-index:2;
    }
    .content {
      position:relative;
      z-index:1;
      transform: translateY(-#{$headerHeightStandalone});

      @include mqSmaller($bpS) {
        transform: translateY(-#{$headerHeightStandaloneXs});
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
  .header {
    height: $headerHeightStandalone;

    @include mqSmaller($bpS) {
      height: $headerHeightStandaloneXs;
    }
  }
  .content {

    min-height: calc(100% - #{$headerHeightStandalone} - #{$footerHeight});


    @include mqSmaller($bpS) {
      min-height: calc(100% - #{$headerHeightStandaloneXs} - #{$footerHeightXs});
    }
  }

  .footer {
    @include gridMargins;

    height: $footerHeight;

    // same animation as PageWrapper
    @include animation(slide-up);
    animation-fill-mode: forwards;

    @include mqSmaller($bpS) {
      height: $footerHeightXs;
    }
  }
}
