@import 'shared';

.sidePanel {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: rgba(0, 0, 0, 0.1);
  .container {
    position: fixed;
    top: 0;
    right: 0px;
    height: 100vh;
    background-color: $colorWhite;
    box-sizing: border-box;

    &.sizeL {
      width: 720px;
      @include mqSmaller($bpM) {
        width: 640px;
      }
    }

    &.sizeM {
      width: 411px;
    }

    &.sizeS {
      width: 317px;
    }

    .close {
      @include iconWithBackgroundGrey;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: zIndex('Buffer2');

      img {
        margin: auto;
      }
    }
    @include mqSmaller($bpS) {
      width: 100% !important;
    }
  }
}


@keyframes slide-from-right {
  0% {
    right: -317px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes slide-to-right {
  0% {
    right: 0px;
    opacity: 1;
  }
  100% {
    right: -317px;
    opacity: 0;
  }
}
