@import 'shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
  @include transition(transform, opacity);
}

.popover {
  position: relative;
  @include type('Copy');

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  @include mqSmaller($bpS) {
    position: unset;
  }

  .label {
    color: $colorPrimary1;
    @include transition(color);

    &:hover {
      color: $colorPrimary2;
    }
  }

  .menu {
    position: absolute;
    width: 257px;
    box-sizing: border-box;

    padding: 16px 0 14px;
    margin-top: space('Xs');

    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: $colorWhite;
    z-index: zIndex('Buffer3');

    .title {
      @include type('Label');
      color: $colorGrey1;
      padding: 0 16px 16px;
    }
    .menuItem {
      @include type('Copy');
      color: $colorGrey1;
      padding: 16px 16px 14px;
      cursor: auto;

      &.active {
        cursor: pointer;

        // transitions
        @include transition(background-color);

        &:hover {
          background-color: $colorPrimary4;
        }
      }
    }
    @include mqSmaller($bpS) {
      width: 100%;
      left: 0;
    }
  }
}
