@import 'shared.scss';

/* COMPONENT */
.assessmentConfigurationModal {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .list {
    margin-top: space('Xs');
    .listItem {
      padding: space('Xs');
      border-bottom: 1px solid $colorGrey4;

      @include type('Copy');

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      .row1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .row2 {
        width: 90%;
        margin-top: space('Xxs');

        color: $colorGrey2;
      }
    }
  }

}
