@import 'shared.scss';

$navigationHeight: 55px;

/* CandidateExternalProfile */
.candidateExternalProfile {
  padding-top: 65px;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    padding-top: 60px;
  }

  /* CHILDREN */
  .navigation {
    position: sticky;
    top: 65px;

    display: flex;
    box-sizing: border-box;

    padding: space('Xs') $gridMarginLarge 0;
    background-color: $colorWhite;
    border-bottom: 1px solid $colorGrey4;

    z-index: zIndex('Buffer2');
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mqSmaller($bpS) {
      top: 61px;
      padding: space('Xs') $gridMarginSmall 0;
    }

    .item {
      @include type('Copy');
      padding-bottom: space('Xs');
      cursor: pointer;
      white-space: nowrap;

      @include transition(color);

      &:not(:last-child) {
        margin-right: space('S');
      }

      &:hover {
        color: $colorGrey2;
      }

      &.active {
        color: $colorPrimary1;
        border-bottom: 2px solid $colorPrimary1;
        &:hover {
          color: $colorPrimary2;
        }
      }
    }
  }

  .assessmentsResults {
    min-height: calc(100vh - #{$headerHeightStandalone} - #{$navigationHeight});
    max-height: calc(100vh - #{$headerHeightStandalone} - #{$navigationHeight});
    overflow-y: auto;
  }

}
