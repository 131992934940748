@import 'shared';

.assessmentTeaser {
  .content {
    display: flex;
    overflow: hidden;

    //IMAGE BLOCK
    .imageBlock {
      width: 50%;
      text-align: center;

      &:not(.balanced-you) {
        img {
          pointer-events: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      //BALANCED YOU CUSTOMIZATION
      &.balanced-you {
        display: flex;
        align-items: center;
        img {
          pointer-events: none;
          width: 100%;
        }
      }

      @include mqSmaller($bpM) {
        width: 100%;
      }
    }

    //TEXT BLOCK
    .textBlock {
      width: 50%;
      box-sizing: border-box;
      margin-left: space('Xs');
      padding: space('M') space('S') space('L') 0;

      .xxsTitle {
        @include type('Xxs');
        white-space: pre-wrap;
      }

      .copy {
        @include type('Copy');
        margin-top: space('Xxxs');
        white-space: pre-wrap;
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        & > button {
          margin-top: space('Xs');
        }
        & > button:not(:last-child) {
          margin-right: space('Xs');
        }
      }

      .label {
        @include type('Label');
        margin-top: space('Xs');
      }

      @include mqSmaller($bpM) {
        width: 100%;
      }
    }

    @include mqSmaller($bpM) {
      flex-direction: column;

      .textBlock {
        margin-left: 0;
        padding: space('Xs') space('Xs') space('M');
      }
    }

    @include mqSmaller($bpS) {
      flex-direction: column;

      .textBlock {
        margin-left: 0;
        padding: space('Xs') space('Xs') space('M');
      }
    }
  }

  &.fullWidth {
    .content {
      flex-direction: row;

      .textBlock {
        padding-top: 74px;
      }

      @include mqSmaller($bpM) {
        .textBlock {
          padding-top: space('Xl');
        }
      }

      @include mqSmaller($bpS) {
        flex-direction: column;

        .textBlock {
          margin-left: 0;
          padding: space('Xs') space('Xs') space('M');
        }
      }
    }
  }

}
