@import 'shared';

.adminUserGroup {
  @include basicGridNoMargins;
  .main {
    grid-column: start/mid;
    color: $colorGrey1;
    .title {
      @include type('Xxs');
    }
    .description {
      @include type('Copy');
      margin-top: space('Xs');
      .descRow {
        display: flex;
        margin-top: space('Xxs');
        span:last-child {
          padding-left: space('Xxs');
        }
      }
    }
    .rights {
      margin-top: space('M');
      .title {
        @include type('Label');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xxs');
      }
      .rows {
        margin-top: space('Xs');
        .row {
          padding: space('Xs');
          border-bottom: 1px solid $colorGrey4;
          @include type('Copy');
          color: $colorGrey1;
          &:first-child {
            border-top: 1px solid $colorGrey4;
          }
          .line1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .line2 {
            color: $colorGrey2;
            margin-top: space('Xxs');
            padding-right: 100px;
            box-sizing: border-box;
          }
          .line3 {
            margin-top: space('Xs');
          }
        }
      }
    }

    @include mqSmaller($bpM) {
      grid-column: start/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
}
