@import 'shared';

.segmentedSelection {
  width: 100%;
  display: flex;

  .cell {
    //width: calc(100% / 5);
    height: 14px;
    background-color: $colorGrey4;
    border-radius: 2px;
    margin-right: 4px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.grey {
      background-color: $colorGrey4;
    }
    &.blue {
      background-color: $colorPrimary1;
    }
    &.hellBlue {
      background-color: $colorPrimary3;
    }
  }
}
