@import 'shared.scss';

$borderRadius: 12px;

/* SKELETONFLEXIBLE */
.skeletonFlexible {

  opacity: 0;
  animation: fade-in 1.5s infinite ease;




  /* INTERACTION */

  /* MODIFIERS */
  &.fullWidth {
    .bar.short, .bar.shorter {
      width: 100% !important;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */

  svg {
    display:block;
    width: 100%;
    height: 264px;
  }

  .headerGroup {
    margin-bottom: $spaceS;
  }
  .group {
    margin-bottom: $spaceS;

    &:last-child {
      margin-bottom: 0;
    }
    &.xxsMargins {
      margin-bottom: $spaceXxs;
    }
    &.noMargins {
      margin: 0;
    }

  }

  .bar {
    background-color: $colorGrey4;
    border-radius: $borderRadius;
    height: 14px;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &.thick {
      height: 24px;
    }

    &.short {
      width: 66%;
    }
    &.shorter {
      width: 56%;
    }
  }
}
