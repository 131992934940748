@import 'shared.scss';

/* DATA */
.data {

  @include basicIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
