@import 'shared.scss';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0;
  transform: translateY(-5px);
}
.enterActive, .enterDone {
  opacity: 1;
  transform: translateY(0px);
}
.exit {
  opacity: 1;
  transform: translateY(0px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}
.exitActive, .exitDone {
  opacity: 0;
  transform: translateY(-5px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}


$searchResultHeight: 75px;

/* hotChipsPEOPLE */
.hotChipsPeople {


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .label {
    margin-bottom: space('Xs');
    @include type('Label');
  }

  .hint {
    margin-top: space('Xxs');
    margin-left: 2px; // move right so flyout completely overlaps
    @include type('Hint');
    color: $colorGrey2;
  }

  .chips {
    padding-top: space('Xxs');

    > div /*transitinogroup*/ > div /*chips*/ {
      margin-top: space('Xs');
      margin-right: space('Xxs');
    }
  }

  // FLYOUT
  .flyoutContainer {
    position: relative;
  }
  .flyout {
    box-sizing: border-box;
    position: absolute;
    top: space('Xxs') + 2px; // move down so it completely overlaps chips
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: $borderRadius;
    filter: $dropShadow;
    overflow: hidden;
    z-index: zIndex('Buffer3');

    @include animation(slide-up);
  }
  .searchResult, .skeleton {
    box-sizing: border-box;
    height: $searchResultHeight;
    padding: space('S');
  }
  .searchResult {
    display: flex;
    align-items: center;

    border-bottom: 1px solid $colorGrey4;
    background-color: white;

    @include transition(background-color, border-color);

    &:last-child {
      border-bottom: 0;
    }

    &:hover, &.active {
      cursor:pointer;
      border-color: $colorPrimary3;
      background-color: $colorPrimary4;
    }

    .imgCircle {
      flex: 0 0 36px;
      padding-right: space('Xs');
    }
    .labels {
      flex: 1 0;
      min-width: 0px; // for ellipsis to work on flex-child child element (.label & .sublabel)
      .label {
        @include type('Label');
        margin: 0; // override .label on top level
        @include ellipsis();
      }
      .subLabel {
        margin-top: space('Xxxs');
        @include ellipsis();
      }
    }
  }

  .showMoreResults {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;
  }

  .message {
    padding: space('Xs');
  }

  .skeleton {
    height: 100%;
    margin-top: space('Xxxs');
    animation: load-assessment-result 1.5s infinite ease;
    [class*=bar] {
      margin-bottom: space('Xxs');
      height: 14px;
      border-radius: 999px;
      background-color: $colorGrey4;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .bar1 {
      width: 30%;
    }
    .bar2 {
      width: 100%;
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
