@import "shared";

.inputPassword {
  &.disabled {
    pointer-events: none;
  }
  .criteria {
    @include type("Hint");
    width: 100%;
    height: 96px;
    padding-top: 8px;
    display:flex;
    flex-direction: column;
    .criterium {
      height: 20px;
      margin-left: 16px;
      color: $colorDanger1;
      display: inline-flex;
      &:before {
        content: "- ";
      }
      &.correct {
        color: $colorPrimary1;
      }
      .icon{
        margin-top: 1px;
        margin-left: 4px;
      }
    }
  }
}
