@import 'shared';

.segmentedChooser {
  width: 100%;
  display: flex;
  .cell {
    background-color: $colorGrey4;
    margin-right: 4px;
    cursor: pointer;
    width: calc(100% / 5);
    &:first-child {
      border-radius: 22px 0 0 22px;
    }
    &:last-child {
      margin-right: 0;
      border-radius: 0 22px 22px 0;
    }
    &.grey {
      background-color: $colorGrey4;
    }
    &.blue {
      background-color: $colorPrimary1;
    }
    &.hellBlue {
      background-color: $colorPrimary3;
    }
  }
  &.sizeL {
    .cell {
      height: 40px;
    }
  }
  &.sizeM {
    .cell {
      height: 24px;
    }
  }
  &.sizeS {
    .cell {
      height: 14px;
    }
  }
}
