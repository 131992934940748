/**
  SPACES
    - Might upgrade this later with producing rems and setting the root font size responsively
**/

$spaces: ('Xxxs', 'Xxs', 'Xs', 'S', 'M', 'L', 'Xl', 'Xxl');

$spaceXxxs: 4px;
$spaceXxs: 8px;
$spaceXs: 16px;
$spaceS: 24px;
$spaceM: 32px;
$spaceL: 48px;
$spaceXl: 64px;
$spaceXxl: 96px;

$borderRadius: 10px;

@function space($size) {
  @if $size == 'Xxxs' {
    @return $spaceXxxs;
  }
  @else if $size == 'Xxs' {
    @return $spaceXxs;
  }
  @else if $size == 'Xs' {
    @return $spaceXs;
  }
  @else if $size == 'S' {
    @return $spaceS;
  }
  @else if $size == 'M' {
    @return $spaceM;
  }
  @else if $size == 'L' {
    @return $spaceL;
  }
  @else if $size == 'Xl' {
    @return $spaceXl;
  }
  @else if $size == 'Xxl' {
    @return $spaceXxl;
  }
  @else {
    @error 'space($size) invalid $size';
  }
}

:export {
  spaces: $spaces;
  spaceXxxs: $spaceXxxs;
  spaceXxs: $spaceXxs;
  spaceXs: $spaceXs;
  spaceS: $spaceS;
  spaceM: $spaceM;
  spaceL: $spaceL;
  spaceXl: $spaceXl;
  spaceXxl: $spaceXxl;
}
