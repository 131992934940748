@import 'shared.scss';

/* GarminBarDiagram */
.garminBarDiagram {
  width: 100%;
  box-sizing: border-box;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .diagram {
    &.blue {
      fill: $colorPrimary1;
    }
    &.lightBlue {
      fill: rgba(45, 114, 254, 0.4);
    }
    &.lightRed {
      fill: rgba(225, 41, 43, 0.4);
    }
    &.red {
      fill: $colorDanger1;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin-top: space('Xxs');

    @include type('LabelDiagram');
  }
}
