@import 'shared.scss';

/* QUESTIONGENDER */
.questionGender {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
