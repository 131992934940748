@import 'shared';

.infoTitle {
  @include type('Label');
  color: $colorGrey1;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &.regular {
    @include type('Copy');
  }

  &.fullWidth {
    width: 100%;
    span {
      flex: 1;
    }
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
    
    padding-left: 8px;
    cursor: pointer;
  }

  &:hover path {
    @include transition(fill);
    fill: $colorPrimary2 !important;
  }

  @include mqSmaller($bpM) {
    width: 100%;
    justify-content: space-between;
  }

  @include mqSmaller($bpS) {
    width: 100%;
    justify-content: space-between;
  }
}
