@import 'shared.scss';

/* COMMENT */
.comment {
  display: flex;
  padding: space('S') 0;
  border-bottom: 1px solid $colorGrey4;

  // first child is ImgCircle component
  & > div:first-child {
    margin-right: space('Xs');
    flex-shrink: 0;
  }

  /* INTERACTION */

  /* MODIFIERS */
  &.topBorder {
    border-top: 1px solid $colorGrey4;
  }

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    .header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include mqSmaller($bpM) {
        flex-direction: column;
        align-items: flex-start;
      }

      .authorName {
        @include type('Label');
        padding-bottom: space('Xxs');
        padding-right: space('Xs');
      }
      .dateTime {
        @include type('Copy');
        padding-bottom: space('Xxs');

        & > span:first-child {
          padding-right: space('Xs');
        }
      }
    }

    .text {
      // negative values for removing the margins of text editor
      margin-top: -(space('Xs'));
      margin-left: -(space('Xs'));
      margin-bottom: -(space('Xs'));

      @include mqSmaller($bpM) {
        margin-top: -(space('Xxs'));

        margin-left: -68px; //68 = img circle width + padding between + padding-left of text editor
      }
    }
  }
}
