@import 'shared.scss';

/* JOB */
.job {
  @include basicIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
