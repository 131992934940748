@import 'shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
  @include transition(transform, opacity);
}

.container {
  position: relative;
  user-select: none;

  display: flex;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &.alignCenter {
    justify-content: center;
    .flyout {
      left: -11 8px;
    }
  }
  &.alignRight {
    justify-content: flex-end;
    .flyout {
      right: -10px;
    }
    @include mqSmaller($bpS) {
      .flyout {
        right: 0px;
      }
    }
  }

  @include mqSmaller($bpS) {
    position: unset;
  }
}

.dropDownMenu {
  color: $colorPrimary1;
  @include type('Copy');
  height: 36px;
  padding: 10px 20px 14px 20px;
  border-radius: 18px;
  border: 1px solid $colorPrimary1;
  box-sizing: border-box;
  line-height: 10px !important;
  cursor: pointer;

  // transitions
  @include transition(color, background-color, border);

  svg {
    margin-left: 8px;
    // transition
    @include transition(fill, transform);
    path {
      fill: $colorPrimary1
    }
  }
  &:hover {
    color: $colorPrimary2;
    background-color: $colorPrimary3;
    border-color: $colorPrimary3;
    path {
      fill: $colorPrimary2;
    }
  }
  &.open {
    background-color: $colorPrimary3;
    border-color: $colorPrimary3;
  }
  @include mqSmaller($bpS) {
    margin-top: 0;
  }
}

.flyout {
  position: absolute;
  width: 257px;
  box-sizing: border-box;

  padding: 16px 0 14px;
  margin-top: 52px;

  border-radius: 10px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: $colorWhite;
  z-index: 1;

  .title {
    @include type('Label');
    color: $colorGrey1;
    padding: 0 16px 16px;
  }
  .menuItem {
    @include type('Copy');
    color: $colorGrey1;
    padding: 16px 16px 14px;
    cursor: pointer;

    // transitions
    @include transition(background-color);

    &.active {
      background-color: $colorPrimary4;
    }
    &:hover {
      background-color: $colorPrimary4;
    }
  }
  @include mqSmaller($bpS) {
    width: calc(100vh - 32px);
    left: 16px;
  }
}
