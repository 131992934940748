@import 'shared';

.rmpResult {
  background-color: $colorWhite;

  .container {
    width: 100%;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    padding: 32px 32px 48px 32px;
    box-sizing: border-box;
  }

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .pendingText {
    @include type('Copy');
    color: $colorGrey1;
    margin-top: space('S');
  }
  .rows {
    .item {
      @include type('Copy');
      .header {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        padding: space('S') 0 space('Xxs');
        display: flex;
        justify-content: center;
        svg {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      }
      .titles {
        @include type('Hint');
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        margin-top: space('Xxxs');
        color: $colorGrey2;
        .blue {
          color: $colorPrimary1;
        }
      }
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('M');
  }

  button {
    margin-top: 24px;
    display: block;
    margin-left: auto;
  }
  .footerText {
    margin-top: space('S');

    span {
      @include type('Hint');
      color: $colorPrimary1;
      cursor: pointer;
      @include transition(color);

      &:hover {
        color: $colorPrimary2;
      }
    }
  }

  @include mqSmaller($bpM) {
    .container {
      padding: 24px 16px 32px;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      padding: 24px 16px 32px
    }
  }

}

.infoModalResult {
  color: $colorGrey1;
  > div:first-child {
    margin: 0 auto;
  }
  .name {
    @include type('Xxs');
    margin-top: space('Xxs');
    text-align: center;
  }
  .labelDescription {
    @include type('Label');
    margin-top: space('Xs');
    text-align: center;
  }
  .callout {
    @include type('Copy');
    margin-top: space('Xs');
  }
  .reportDiagram {
    margin-top: space('Xs');
    .header {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xs');
      span {
        @include type('Copy');
      }
      span:last-child {
        color: $colorPrimary1;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xxs');
      span {
        @include type('Hint');
        color: $colorGrey2;
      }
      span.blue {
        color: $colorPrimary1;
      }
    }
    svg {
      margin-top: space('Xs');
    }
  }
}
