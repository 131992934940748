@import 'shared.scss';

$scaleMin: 1;
$scaleMax: 10;
$rowHeight: 45px;
$barHeight: 37px;
$barPaddingX: 4px;
$markerWidth: 30px;
$labelMaxWidth: 165px;
$paddingBottom: 20px;

/* teamDiagramBarsNext */
.teamDiagramBarsNext {
  width: 100%;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .legend {
    margin-top: $spaceS - $spaceXxxs;
    margin-bottom: $spaceXxs;
    padding: 0 space('M');

    display: flex;

    .legendItem {
      flex: 1 0 ( 100% / ($scaleMax - $scaleMin));

      text-align: center;
      color: $colorGrey2;
      @include type('Hint');

      transform: translateX(-50%);
    }
  }

  .diagramContainer {
    position: relative;
    padding: 0 space('M');

    @include mqSmaller($bpS) {
      padding: 0 space('Xs');
    }

  }
  .svgContainer {
    position: absolute;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    padding: 0 space('M');

    @include mqSmaller($bpS) {
      padding: 0 space('Xs');
    }

    overflow: visible;
  }
  .svg {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    overflow: visible;

    /* INTERACTION */

    /* MODIFIERS */

    /* RESPONSIVE */

    /* CHILDREN */

    .grid {
      $lineWidth: 1px;
      $scaleStep: 100% / ($scaleMax - $scaleMin);

      @for $counter from 0 through $scaleMax - $scaleMin {
        line:nth-child(#{$counter + 1}) {
          stroke: $colorGrey4;
          stroke-width: 1;
          transform: translate(calc(#{$counter * $scaleStep}));
        }
      }
    }

    .bars {
      rect {
        cursor: pointer;
        fill: $colorPrimary1;

        opacity: 0.0;
        @include transition(transform, opacity);

        &:hover {
          opacity: 0.1;
        }
      }
    }
  }


  // HTML LAYER
  .htmlLayer {
    position: relative;
    height: 100%;
    width: 100%;

    z-index: 1;

    // BARS
    .bars {
      position: relative;
      z-index: 1;
    }
    .barContainer {
      cursor: pointer;
      height: $barHeight;
      padding: $barPaddingX 0;
      width: 100%;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }

      &:hover {
        .barHover {
          transform: scale(1);
        }
        .barLabel {
          color: $colorPrimary1;
        }
        .marker [class*=ImgPin] svg path {
          fill: $colorPrimary15;
        }
      }
    }
    .bar {
      position: relative;
      height: 100%;
      width: 0%;

      display: flex;
      justify-content: flex-end;

      @include transition(width);
    }
    .barFill {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: $colorPrimary1;
      opacity: 0.05;
      @include transition(width, opacity);
    }

    .barHover {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      transform-origin: 0 0;
      background-color: $colorPrimary1;
      opacity: 0.05;

      @include transition(transform);
    }
    .barLabel {
      flex-shrink: 0;
      user-select: none;

      box-sizing: border-box;
      height: 100%;
      max-width: $labelMaxWidth;
      padding: 0 $spaceS;

      @include type('LabelDiagram');
      line-height: $barHeight !important;
      @include ellipsis();

      @include transition(transform, color);


      &.moveToRight {
        transform: translateX(100%);
      }

    }

    .marker {
      user-select: none;
      position: absolute;

      transform: translateX(50%);

      @include transition(margin-left);

      [class*=ImgPin] svg path {
        @include transition(fill);
      }
    }


    // AVERAGE BAR
    .averageBarContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + #{$spaceXxs});


      .averageBar {
        box-sizing: border-box;
        width: 2px;
        height: 100%;

        border: 1px dashed $colorPrimary3;

        @include transition(margin);
      }
    }

    // RANGE AREA
    .rangeContainer {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      .range {
        width: 0%;
        height: 100%;

        border: 1px dashed rgba($colorPrimary1, 0.5);
        border-top: 0;
        border-bottom: 0;

        background-color: rgba($colorPrimary4, 0.8);

        @include transition(width, margin-left);
      }
    }
  }

  // AVERAGE LABEL
  .averageLabel {
    margin-top: $spaceXs;
    text-align: center;
    @include type('Hint');

    .highlight {
      margin-left: $spaceXxxs;
      color: $colorPrimary1;
    }
  }

  // RANGE LABEL
  .rangeLabel {
    margin-top: $spaceS;
    text-align: right;
    @include type('Copy');

    .rangeLegend {
      display: inline-block;
      width: 30px;
      height: 20px;
      margin-right: $spaceXxs;
      margin-bottom: -5px;

      border: 1px dashed rgba($colorPrimary1, 0.5);
      border-top: 0;
      border-bottom: 0;

      background-color: rgba($colorPrimary4, 0.8);
    }

    .highlight {
      margin-left: $spaceXxxs;
      color: $colorPrimary1;
    }
  }

}

:export {
  scaleMin: $scaleMin;
  scaleMax: $scaleMax;
  rowHeight: $rowHeight;
  barHeight: $barHeight;
}
