@import 'shared.scss';

/* START */
$animationBreatheDuration: 6.5s;

.start {
  position: relative;

  @include basicGrid;
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */


  @include mqSmaller($bpM) {
    .content {
      grid-column: 3 / 7 !important;
    }
  }

  @include mqSmaller($bpS) {
    .content {
      grid-column: start / end !important;
    }
    .breatheContainer {
      display: block;
    }
    .breathe {
      width: calc(100vw - 48px) !important;
      height: calc(100vw - 48px) !important;
    }
  }

  /* CHILDREN */
  .breatheContainer {
    position: absolute;
    top: -5vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .breathe {
    height: 65vh;
    width: 65vh;
    border-radius: 50%;
    background-color: $colorPrimary4;
    z-index: -1;

    animation: scale $animationBreatheDuration infinite ease-out;


    @keyframes scale {
      0% {
        opacity: 0.65;
        transform: scale(0.8);
      }
      50% {
        opacity: 1;
        transform: scale(1);
      }
      52% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0.65;
        transform: scale(0.8);
      }
    }
  }



  .content {
    grid-column: 5 / 9;
  }

  .title {
    @include type('M');
    text-align:left;
  }

  .controls, .logout {
    display: flex;
    justify-content: flex-end;
    margin-top: $spaceM;
  }
}
