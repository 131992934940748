@import 'shared.scss';

/* INFO */
.info {

  background-color: $colorGrey6;;

  /* CHILDREN */
  .grid {
    @include basicGrid;
  }
  .content {
    grid-column: start/end;
    margin: space('L') 0;
    margin-top: space('Xxl');
  }
  table {
    td {
      padding-right: 16px;
    }
    td:nth-child(2) {
      color: $colorPrimary1;
    }
  }

  .toastTitle {
    @include type('Label');
    color: $colorGrey1;
    margin-right: 32px;
    overflow: hidden;
  }
  .toastDescription {
    @include type('Copy');
    color: $colorGrey1;
    margin-top: space('Xxs');
  }
  /* INTERACTION */

  /* MODIFIERS */
}
