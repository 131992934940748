.assessmentCheckMark {
  display: flex;
  padding-bottom: 8px;
  .checkMark {
    padding-top: 2px;
  }
  span {
    padding-left: 8px;
  }
}
