@import 'shared';

.content {
  .loginData {
    margin-top: space('S');
    .label {
      @include type('Label');
    }
    .copy {
      @include type('Copy');
      margin-top: space('Xs');
    }
    .inputs > div {
      margin-top: space('Xs');
    }
  }
  .forgotPassword {
    @include type('Copy');
    color: $colorPrimary1;
    margin-top: space('S');
    text-align: right;
  }
}
