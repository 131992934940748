@import 'shared';

.nineLevelsTeamDiagram {
  margin-top: space('S');
}

.copyright {
  margin-top: space('S');

  @include type('Hint');
  color: $colorPrimary1;

  @include transition(color);

  &:hover {
    cursor: pointer;
    color: $colorPrimary2;
  }
}

.nineLevelsModalContent {
  margin-top: space('S');
  .dimensionName {
    @include type('Copy');
    text-align: center;
  }
  .diagram {
    margin-top: space('Xs');
  }
  .footer {
    display: flex;
    justify-content: space-between;

    @include type('Hint');
    color: $colorGrey2;
  }
  .description {
    @include type('Copy');
    margin-top: space('S');
  }
  .blocks {
    margin-top: space('S');
    .block {
      border-top: 1px solid $colorGrey3;
      padding: space('Xs') 0;

      &:last-child {
        border-bottom: 1px solid $colorGrey3;
      }

      .label {
        @include type('Label');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xxs');
      }
    }
  }
}
