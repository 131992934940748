@import 'shared';

.myProfile {
  background: $colorGrey6;

  .gridContainer {
    @include basicGridNoMargins;
  }

  .personalInfo {
    grid-column: 1/5;
    z-index: zIndex('Buffer1');

    .subBlock {
      &:not(.sticky) {
        @include mqSmaller($bpS) {
          margin-top: 0;
        }
      }

      &.sticky {
        position: sticky;
        top: space('L');
      }
    }

    .anchors {
      margin-top: space('M');

      @include animationLonger(slide-up);
      animation-fill-mode: forwards;

      a {
        display: block;
        margin-top: space('Xs');
      }
    }

    .buttons {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: space('S');

      z-index: zIndex('Buffer1');

      @include animationLonger(slide-up);
      animation-fill-mode: forwards;

      button {
        align-self: baseline;
        margin-bottom: space('Xs');
        margin-right: space('Xs');
      }
      button:last-child {
        margin-bottom: 0;
      }
    }
  }

  .assessmentsInfo {
    grid-column: 5/13;
    overflow: hidden;

    @include animationLong(slide-up);
    animation-fill-mode: forwards;

    .wellBeingContainer {
      margin-bottom: space('M');
      .title {
        @include type('Xxs')
      }

      & > div:last-child {
        margin-top: space('Xs');
      }
    }

    .assessmentTeasers {
      padding-bottom: space('M');
    }

    .titleBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .scrollingButtons {
      display: flex;

      & > div:first-child {
        margin-right: space('Xxs');
      }

      @include mqSmaller($bpS) {
        display: none;
      }
    }

    .scrollable {
      margin-top: -(space('Xs'));
    }

    .title {
      @include type('Xxs');
      color: $colorGrey1;
    }

    .assessment, .teaser {
      margin-top: space('Xs');
      & > div:first-child {
        height: 100%;
        & > div:first-child {
          height: 100%;
          & > div:first-child {
            height: 100%;
          }
        }
      }
    }

    .teaser {
      min-width: 100%;
      margin-right: space('Xs');
    }

    @include mqSmaller($bpM) {
      grid-column: 5/9;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }
  }

  .exportView {
    width: 600px;
    max-width: 600px;
    margin: space('M') auto 0;

    // loading indicator
    .loading {
      margin-bottom: space('M');
      margin-top: 0;
      text-align: center;
      & > div {
        justify-content: center;
      }
    }

    & > [id^=export], & > .assessment {
      div, text, circle {
        pointer-events: none;
      }
    }

    & > .assessment {
      margin-top: space('Xs');
    }
  }

  .exportViewFooter {
    width: 600px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    & > button {
      display: block;
      margin-left: auto;
    }
  }

}
