@import "shared";

.button {
  position:relative;
  color: $colorLight;
  border-radius: 22px;
  border: 0;
  background: $colorPrimary1;
  user-select: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  // transitions
  @include transition(background-color, color, border-color);

  // before for the inset white "outline" that should be shown
  &:before {
    content: " ";
    position: absolute;
    pointer-events:none; // make click through
    z-index: zIndex('Buffer2');
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px rgba(0,0,0,0) solid;

    // transitions
    @include transition(border-color);
  }

  // MODIFIERS
  &:disabled {
    pointer-events: none;
  }
  &[active] {
    pointer-events: none;
  }
  &.progressIndicator {
    .content > div {
      z-index: zIndex('Buffer2');
    }
  }

  // CHILDREN
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width:100%;
      text-align: center;
    }
  }

  //PROGRESS INDICATOR
  .progressIndicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorPrimary3;
    z-index: zIndex('Buffer1');

    animation: progress 3s ease-in-out;
  }
}

// sizes
.sizeL {
  height: 44px;
  padding: 14px 24px 16px 24px;
  border-radius: 22px;
  @include type("ButtonL");
  &:before {
    border-radius: 22px;
  }

  &.iconOnly {
    width: 44px;
    padding: 0;

    .content {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
.sizeM {
  height: 40px;
  padding: 13px 22px 15px 22px;
  border-radius: 20px;
  @include type("ButtonM");
  &:before {
    border-radius: 20px;
  }

  &.iconOnly {
    width: 40px;
    padding: 0;

    .content {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
.sizeS {
  height: 36px;
  padding: 10px 20px 14px 20px;
  border-radius: 18px;
  @include type("ButtonS");
  &:before {
    border-radius: 18px;
  }

  &.iconOnly {
    width: 36px;
    padding: 0;

    .content {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.primary {
  color: $colorLight;
  background: $colorPrimary1;
  border: 1px $colorPrimary1 solid;
  &:hover, &.hover {
    border-color: $colorPrimary2;
    background: $colorPrimary2;
  }
  &:focus, &.focus {
    border-color: $colorPrimary2;
    background: $colorPrimary2;
    &:before { // white inset outline
      border-color: $colorWhite;
    }
  }
  &:disabled, &.disabled {
    border-color: $colorPrimary3;
    background: $colorPrimary3;
  }

  .content svg path {
    fill: $colorWhite;
  }
}
.secondary {
  color: $colorPrimary1;
  background-color: $colorWhite;
  border: 1px $colorPrimary1 solid;

  .content svg path {
    fill: $colorPrimary1;
  }

  &:hover, &.hover {
    background: $colorPrimary3;
    border: 1px rgba(0,0,0,0) solid;
  }
  &:focus, &.focus {
    background: $colorPrimary3;
    border: 1px $colorPrimary1 solid;
  }
  &:disabled, &.disabled {
    color: $colorGrey2;
    background-color: $colorWhite;
    border-color: $colorGrey4;

    .content svg path {
      fill: $colorGrey2;
    }
  }
}
.secondary-transparent {
  color: $colorPrimary1;
  background-color: transparent;
  border: 1px $colorPrimary1 solid;

  .content svg path {
    fill: $colorPrimary1;
  }

  &:hover, &.hover {
    background: $colorPrimary3;
    border: 1px rgba(0,0,0,0) solid;
  }
  &:focus, &.focus {
    background: $colorPrimary3;
    border: 1px $colorPrimary1 solid;
  }
  &:disabled, &.disabled {
    color: $colorGrey2;
    border-color: $colorGrey4;

    .content svg path {
      fill: $colorGrey2;
    }
  }
}
.tertiary {
  color: $colorGrey1;
  background-color: $colorWhite;
  border: 1px $colorGrey3 solid;

  .content svg path {
    fill: $colorGrey1;
  }

  &:hover, &.hover {
    background: $colorGrey4;
    border: 1px rgba(0,0,0,0) solid;
  }
  &:focus, &.focus {
    color: $colorGrey1;
    background: $colorGrey4;
    border: 1px $colorPrimary1 solid;
  }
  &:disabled, &.disabled {
    color: $colorGrey2;
    background-color: $colorWhite;
    border: 1px $colorGrey4 solid;

    .content svg path {
      fill: $colorGrey2;
    }
  }

  &.iconOnly {
    .content svg path {
      fill: $colorPrimary1;
    }
  }
}
.cancel {
  color: $colorLight;
  background: $colorDanger1;
  border: 1px $colorDanger1 solid;
  &:hover, &.hover {
    background: $colorDanger2;
  }
  &:focus, &.focus {
    border-color: $colorDanger2;
    background: $colorDanger2;
    &:before {
      border-color: $colorWhite; // white inset outline
    }
  }
  &:disabled, &.disabled {
    border-color: $colorDanger3;
    background: $colorDanger3;
  }

  .content svg path {
    fill: $colorWhite;
  }
}
