@import 'shared.scss';

/* SINGLESELECT */
.singleSelect {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .item {
    position: relative;
    min-height: 54px;

    box-sizing: border-box;
    padding: space('Xs');
    border-bottom: 1px solid $colorGrey4;

    @include type('Copy');
    cursor: pointer;

    background-color: white;
    @include transition(color, background-color);


    &:hover {
      color: $colorPrimary2;
      background-color: $colorPrimary4;
    }
    &:active {
      background-color: $colorPrimary3;
    }

    &:first-child {
      border-top: 1px solid $colorGrey4;
    }
    &.active {
      color: $colorPrimary1;
    }
  }

  .label {
    padding-right: 24px + $spaceXxs * 2;
  }
  .checkmark {
    position: absolute;
    top: 0;
    right: $spaceXxs;
    height: 100%;

    display: flex;
    align-items: center;

    opacity: 0;
    @include transition(opacity);

    &.show {
      opacity: 1;
    }
  }
}
