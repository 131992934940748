@import 'shared.scss';

/* PILL */
.pill {
  border-radius: 12px;
  padding: 2px space('Xxs');
  margin-right: $spaceXxs;
  @include type('Copy');
  color: $colorGrey1;
  background-color: $colorGrey5;

  @include transition(color, background-color);

  &.primary {
    padding: 4px 10px;
    border-radius: 14px;
    &:hover, &.active {
      color: $colorWhite;
      background-color: $colorPrimary1;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor:pointer;
      color: $colorPrimary1;
      background-color: $colorPrimary4;
    }
  }
  &.active {
    color: $colorPrimary1;
    background-color: $colorPrimary4;
  }
}
