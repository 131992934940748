@import 'shared.scss';

/* QUESTIONAGE */
.questionAge {

  @include basicQuestion;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
