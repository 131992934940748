@import "shared.scss";

$rowHeight: 74px;
$cellWidth3Cells: 110px;
$cellWidth3CellsLast: 144px;
$cellWidth3CellsLastXs: 80px;
$cellWidth4Cells: 70px;
$cellWidth4CellsLast: 144px;
$cellWidth4CellsLastXs: 54px;
$cellWidth5Cells: 42px;
$cellWidth5CellsLast: 80px;
$cellWidth5CellsLastXs: 65px;
$cellAvatarWidth: 46px;
$cellArrowWidth: 12px;
$cellOptionWidth: 45px;

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
}
.exitActive, .exitDone {
  display: none;
}


/* LISTNEXTITEM */
.listNextItem {
  background-color: $colorWhite;
  border: 1px solid $colorGrey4;
  margin-top: -1px; // allow full border on every item

  &:first-child {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }
  &:last-child {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }


  .listItem {
    display: flex;
    align-items: center;

    position:relative;
    box-sizing: border-box;
    min-height: $rowHeight;
    padding: 14px;  // 16px - 2px compensate overflow: hidden; text cutoff + status circle cutoff

    // transition for hover effect
    @include transition(background-color, border-color);

    // hover
    &:not(.disabled):hover, .active {
      cursor: pointer;
      border-color: $colorPrimary3;
      background-color: $colorPrimary4;
      z-index: 1; // make full border visible

      // arrow
      .cellArrow svg path {
        fill: $colorPrimary1 !important;
      }

      // hover status
      [class*=cell] {
        [class*=ProgressCircle_progressCircle] > div:last-child { // hover color for ProgressCircle innerColor
          background-color: $colorPrimary4;
          @include transition(background-color);
        }
      }
    }

    /* MODIFIERS */
    &.disabled {
      .cellArrow { visibility: hidden ;}
    }
    // showArrows
    &:not(.showArrow) {
      [class*=cell]:last-child {
        margin-right: 0;
      }
    }
    &.hasTooltips {
      .cell1, .cell2, .cell3, .cell4, .cell5 {
        overflow: visible; // this will break ellipsis
      }
      .cell1 [class*=tooltip] {
        left: - space('S');
      }
      &:not(.layout5Cells) .cell3,
      .layout5Cells .cell5 {
        right: space('L');
      }
    }
    &.showMoreContent {
      padding: space('Xs') space('M');

      @include mqSmaller($bpM) {
        padding: space('Xs');
      }
    }

    /* CELLS */

    // all cells; layout: 3Cells
    [class*=cell] {
      // fixed cell width;
      flex: 0 0;
      order: 2; // for responsive reordering

      // compensate overflow: hidden; text cutoff
      box-sizing: border-box;
      padding: 1px;

      // spacing to other cells; set responsively in RESPONSIVE
      margin-right: space('L');

      // for ellipsis, disabled for tooltips
      overflow: hidden;
    }

    .cell1, .cell2 {
      flex-grow: 1;
      max-width: $cellWidth3Cells;
    }
    .cell3 {
      flex-grow: 1;
      max-width: $cellWidth3CellsLast;
    }

    &.layout5CellsCompactMode {
      [class*=cell] {
        margin-right: space('M');
      }
    }

    &.layout4Cells {
      .cell1, .cell2, .cell3 {
        flex-grow: 1;
        max-width: $cellWidth4Cells;
      }
      .cell4 {
        flex-grow: 1;
        max-width: $cellWidth4CellsLast;
      }
    }

    &.layout5Cells {
      .cell1, .cell2, .cell3, .cell4 {
        flex-grow: 1;
        max-width: $cellWidth5Cells;
      }
      .cell5 {
        flex-grow: 1;
        max-width: $cellWidth5CellsLast;
      }
    }

    // avatar
    .cellAvatar {
      flex-basis: $cellAvatarWidth;

      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-right: space('Xxs') !important;
    }
    // itemIdentifier
    .cellItemIdentifier {
      // should take all available space
      flex-grow: 1;
    }
    // arrow
    .cellArrow {
      flex-basis: $cellArrowWidth;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 1px !important;

      svg {
        height: 12px;
      }
    }

    .cellOptionsParent {
      flex-basis: $cellOptionWidth;
      height: 45px;

      &:focus {
        outline: none;
        border: none;
      }
    }

    .cellOptions {
      width: 100%;
      height: 100%;
      border-radius: 22px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $colorPrimary3;
      }

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $colorBlack;
        }
      }
    }
  }



  /* RESPONSIVE */
  // cell spacings
  @include mqSmaller($bpL) {
    .listItem [class*=cell] {
      margin-right: space('M');
    }
  }
  @include mqSmaller($bpM) {
    .listItem [class*=cell] {
      margin-right: space('S');
    }

    //compact mode for 'layout5Cells'
    .listItem.layout5Cells.layout5CellsCompactMode {
      flex-wrap: wrap;

      // cellItemIdentifier based on showArrow, showAvatar
      .cellItemIdentifier {
        flex-basis: calc(100% - #{space('S')} - #{$cellWidth5CellsLastXs});
        margin-right: space('Xxs');
      }

      &:not(.showArrow).showAvatar .cellItemIdentifier {
        flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellWidth5CellsLastXs});
      }

      &:not(.showArrow).showAvatar.showOptions .cellItemIdentifier {
        flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellOptionWidth} - #{$cellWidth5CellsLastXs});
      }

      &.showArrow:not(.showAvatar) .cellItemIdentifier {
        flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellWidth5CellsLastXs});
      }

      &.showArrow.showAvatar .cellItemIdentifier {
        flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellAvatarWidth} - #{$cellWidth5CellsLastXs});
      }

      &:not(.hasCellLastXs) {
        // cell1, 2, 3, 4, 5
        .cell1, .cell2, .cell3, .cell4 {
          flex-basis: calc(100% / 4 - #{3*space('Xs')}) !important;
          margin-right: space('Xs');
          margin-top: space('Xs');
          max-width: none;
        }

        .cell4 {
          margin-right: 0;
        }

        .cell5 {
          // flex-basis: calc(100%/4 - #{space('Xs')}) !important;
          // max-width: calc(100%/4 - #{space('Xs')}) !important;

          flex-basis: $cellWidth5CellsLastXs;

          // move text e.g. empty "-" to right
          // text-align: center;

          max-width: $cellWidth5CellsLastXs;
          margin-right: 4px;
          margin-top: -2px;

          // remove ellipsis
          white-space: initial;
          overflow: auto;
          text-overflow: clip;
        }
      }

      &.hasCellLastXs {
        // cell1, 2, 3, 4, 5
        .cell1, .cell2, .cell3, .cell4, .cell5 {
          flex-basis: calc(100% / 5 - #{4*space('Xs')}) !important;
          margin-right: space('Xs');
          margin-top: space('Xs');
          max-width: none;
        }

        .cell5 {
          margin-right: 0;
        }

        .cellLastXs {
          flex-basis: $cellWidth5CellsLastXs;
          margin-right: 0;
        }
      }

      .cellAvatar, .cellItemIdentifier, .cellArrow, .cellOptionsParent, .cellLastXs,
      &:not(.layout5Cells):not(.layout4Cells):not(.hasCellLastXs) .cell3,
      &.layout5Cells:not(.hasCellLastXs) .cell5 {
        order: 1;
      }

    }
  }

  @include mqSmaller($bpS) {
    .listItem [class*=cell] {
      margin-right: space('Xs');
    }
  }

  // cell order & widths & different layouts
  @include mqSmaller($bpS) {
    .listItem {
      // make it wrap
      flex-wrap: wrap;

      // widths & layout
      // cellItemIdentifier based on showArrow & showAvatar
      .cellItemIdentifier {
        margin-right: 8px;
        flex-basis: calc(100% - #{space('S')} - #{$cellWidth3CellsLastXs});
      }

      &:not(.showArrow).showAvatar .cellItemIdentifier {
        flex-basis: calc(100% - #{$cellAvatarWidth} - #{space('S')} - #{$cellWidth3CellsLastXs});;
      }

      &:not(.showArrow).showAvatar.showOptions .cellItemIdentifier {
        flex-basis: calc(100% - #{$cellAvatarWidth} - #{$cellOptionWidth} - #{space('S')} - #{$cellWidth3CellsLastXs});;
      }

      &.showArrow:not(.showAvatar) .cellItemIdentifier {
        flex-basis: calc(100% - #{$cellArrowWidth} - #{space('S')} - #{$cellWidth3CellsLastXs});;
      }

      &.showArrow.showAvatar .cellItemIdentifier {
        flex-basis: calc(100% - #{$cellArrowWidth} - #{$cellAvatarWidth} - #{space('S')} - #{$cellWidth3CellsLastXs});
      }

      // cell1, 2, 3 for layout3columns
      .cell1, .cell2 {
        flex-basis: calc(50% - #{space('Xs')}) !important;
        margin-top: space('Xs');
        margin-right: space('Xs');
        max-width: none;
      }

      .cell2 {
        margin-right: 0px;
      }

      &:not(.layout5Cells) .cell3 {
        flex-basis: $cellWidth3CellsLastXs;
        max-width: $cellWidth3CellsLastXs;
        margin-right: 4px;
        margin-top: -2px;
        white-space: initial;
        overflow: auto;
        text-overflow: clip;

        [class*=ProgressCircle_progressCircle] {
          margin-right: 0px;
        }

        // move to right when we have no label
        & .value:only-child, & .tooltip + .value {
          text-align: right;
        }
      }


      // layout4Cells
      &.layout4Cells {

        // cellItemIdentifier based on showArrow, showAvatar
        .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellWidth4CellsLastXs});
        }

        &:not(.showArrow).showAvatar.showOptions .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellWidth4CellsLastXs});
        }

        &:not(.showArrow).showAvatar.showOptions .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellOptionWidth} - #{$cellWidth4CellsLastXs});
        }

        &.showArrow:not(.showAvatar) .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellWidth4CellsLastXs});
        }

        &.showArrow.showAvatar .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellAvatarWidth} - #{$cellWidth4CellsLastXs});
        }

        &:not(.hasCellLastXs) {
          // cell1, 2, 3
          .cell1, .cell2, .cell3 {
            flex-basis: calc(100% / 3 - #{2*space('Xs')}) !important;
            margin-right: space('Xs');
            margin-top: space('Xs');
            max-width: none;
          }

          .cell3 {
            margin-right: 0;
          }

          .cell4 {
            flex-basis: $cellWidth4CellsLastXs;

            // move text e.g. empty "-" to right
            // text-align: center;

            max-width: $cellWidth4CellsLastXs;
            margin-right: 4px;
            margin-top: -2px;

            // remove ellipsis
            white-space: initial;
            overflow: auto;
            text-overflow: clip;
          }
        }
      }


      // layout5Cells
      &.layout5Cells {

        // cellItemIdentifier based on showArrow, showAvatar
        .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellWidth5CellsLastXs});
        }

        &:not(.showArrow).showAvatar .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellWidth5CellsLastXs});
        }

        &:not(.showArrow).showAvatar.showOptions .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellAvatarWidth} - #{$cellOptionWidth} - #{$cellWidth5CellsLastXs});
        }

        &.showArrow:not(.showAvatar) .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellWidth5CellsLastXs});
        }

        &.showArrow.showAvatar .cellItemIdentifier {
          flex-basis: calc(100% - #{space('S')} - #{$cellArrowWidth} - #{$cellAvatarWidth} - #{$cellWidth5CellsLastXs});
        }

        &:not(.hasCellLastXs) {
          // cell1, 2, 3, 4, 5
          .cell1, .cell2, .cell3, .cell4 {
            flex-basis: calc(100% / 4 - #{3*space('Xs')}) !important;
            margin-right: space('Xs');
            margin-top: space('Xs');
            max-width: none;
          }

          .cell4 {
            margin-right: 0;
          }

          .cell5 {
            // flex-basis: calc(100%/4 - #{space('Xs')}) !important;
            // max-width: calc(100%/4 - #{space('Xs')}) !important;

            flex-basis: $cellWidth5CellsLastXs;

            // move text e.g. empty "-" to right
            // text-align: center;

            max-width: $cellWidth5CellsLastXs;
            margin-right: 4px;
            margin-top: -2px;

            // remove ellipsis
            white-space: initial;
            overflow: auto;
            text-overflow: clip;
          }
        }
      }

      &.hasCellLastXs {
        // cell1, 2, 3, 4, 5
        .cell1, .cell2, .cell3, .cell4, .cell5 {
          flex-basis: calc(100% / 5 - #{4*space('Xs')}) !important;
          margin-right: space('Xs');
          margin-top: space('Xs');
          max-width: none;
        }

        .cell5 {
          margin-right: 0;
        }

        .cellLastXs {
          flex-basis: $cellWidth5CellsLastXs;
          margin-right: 0;
        }
      }

      // order
      .cellAvatar, .cellItemIdentifier, .cellArrow, .cellOptionsParent, .cellLastXs,
      &:not(.layout5Cells):not(.layout4Cells):not(.hasCellLastXs) .cell3,
      &.layout5Cells:not(.hasCellLastXs) .cell5,
      &.layout4Cells:not(.hasCellLastXs) .cell4,
      {
        order: 1;
      }
    }
  }

  //SHOW MORE BLOCK
  .showMore {
    display: block;
    padding: 0 space('M') 0;
    margin-bottom: space('S');

    button {
      display: block;
      margin-left: auto;
    }

    @include mqSmaller($bpM) {
      padding-right: space('Xs');
      padding-left: space('Xs');
    }
  }

  //FLYOUT
  .flyout {
    position: absolute;
    width: 224px;
    right: 0;

    margin-top: space('Xxs');
    padding: space('Xs') 0;
    z-index: zIndex('Modal');

    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: $colorWhite;

    @include mqSmaller($bpS) {
      right: space('Xs');
    }
  }

  .menuItem {
    @include type('Copy');
    color: $colorGrey1;
    padding: 16px 16px 14px;
    cursor: pointer;
    overflow: hidden;

    // transitions
    @include transition(background-color);

    &.active {
      background-color: $colorPrimary4;
    }
    &:hover {
      background-color: $colorPrimary4;
    }
  }

}
