@import 'shared.scss';

/* GarminOrgResult */
.garminOrgResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  // Card component
  & > div:first-child {
    padding: space('M') 0 !important;

    @include mqSmaller($bpM) {
      padding: space('S') 0 space('M') !important;
    }
  }

  .infoTitle {
    @include mqSmaller($bpM) {
      justify-content: flex-start;
    }
  }

  .blockWithPaddings {
    padding: 0 space('M');

    @include mqSmaller($bpM) {
      padding: 0 space('Xs');
    }

    & > svg {
      margin-top: space('Xs');
    }
  }

  .energyBlock {
    margin-top: space('Xs');

    .energyHeader {
      display: flex;
      align-items: center;
      & > span {
        @include type('Label');
        padding-left: space('Xxs');
      }
    }
  }

  .noValueCopy {
    @include type('Copy');
    padding: space('Xxs') 0 space('Xs');
  }

  .energyContent {
    display: flex;
    margin-top: space('Xs');

    .left {
      flex-basis: calc(50% - #{$spaceXs});
      max-width: calc(50% - #{$spaceXs});
      & > div {
        @include type('Copy');
      }
      button {
        display: block;
        margin-top: space('S');
        margin-left: auto;
      }
    }

    .right {
      flex-basis: calc(50% - #{$spaceXs});
      max-width: calc(50% - #{$spaceXs});
      margin-left: space('M');

      & > div:not(:first-child) {
        margin-top: space('Xs');
      }
    }

    @include mqSmaller($bpM) {
      flex-direction: column-reverse;

      .left, .right {
        flex-basis: 100%;
        max-width: 100%;
        margin-left: 0;
      }
      .left {
        margin-top: space('Xs');
        & > button {
          margin-top: space('Xs');
        }
      }
    }
  }

  .collapsiblePanels {
    margin-top: space('Xs');

    button {
      margin-top: space('Xs');
    }

    .collapsibleHeader {
      padding-left: space('M');
      padding-right: space('M');

      &:hover {
        background-color: $colorPrimary4;
      }

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }

    .collapsibleInnerHeader {
      display: flex;
      align-items: center;
      span {
        margin-left: space('Xxs');
      }
    }

    & > div > div:last-child {
      padding-left: space('M');
      padding-right: space('M');

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }
  }

  .date {
    @include type('Hint');
    color: $colorPrimary1;
    margin: space('Xs') space('M') 0;

    @include mqSmaller($bpM) {
      margin: space('Xs') space('Xs') 0;
    }
  }

}

.garminOrgResultSkeleton {
  box-sizing: border-box;
  animation: load-assessment-result 1.5s infinite ease;

  .header {
    width: 100%;
    height: 24px;
    background-color: $colorGrey4;
    border-radius: 12px;
  }
  .subHeader {
    width: 80%;
    header:20px;
    background-color: $colorGrey4;
    border-radius: 12px;

    margin-top: space('S');
  }
  .bars {
    margin-top: space('S');
    & > div:first-child {
      width: 100%;
      height: 14px;
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    & > div:last-child {
      width: 75%;
      height: 14px;
      background-color: $colorGrey4;
      border-radius: 12px;

      margin-top: space('Xxs');
    }
  }
}
