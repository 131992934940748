@import 'shared.scss';

/* QUESTIONNAME */
.questionName {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;

}
