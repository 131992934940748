@import 'shared';

.potentialResult {
  background-color: $colorWhite;

  .container {
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
  }

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .rows {
    .row {
      @include type('Copy');
      display: flex;
      align-items: center;
      padding-top: space('S');
      .points {
        margin-left: space('Xs');
        margin-right: space('Xxs');
        color: $colorPrimary1;
        text-align: right;
      }
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('M');
  }

  @include mqSmaller($bpM) {
    .container {
      width: unset;
      padding: 24px 16px 32px 16px;
    }
    .rows {
      .row {
        flex-direction: column-reverse;
        position: relative;
        svg:first-child {
          margin-top: 8px;
        }
        .points {
          position: absolute;
          right: 16px;
          top: 24px;
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    .container {
      width: unset;
      padding: 24px 16px 32px 16px;
    }
    .row {
      flex-direction: column-reverse;
      position: relative;
      svg:first-child {
        margin-top: 8px;
      }
      .points {
        position: absolute;
        right: 16px;
        top: 24px;
      }
    }
  }
}

.infoModalResult {
  color: $colorGrey1;
  > div:first-child {
    margin: 0 auto;
  }
  .name {
    @include type('Xxs');
    margin-top: space('Xxs');
    text-align: center;
  }
  .labelDescription {
    @include type('Label');
    margin-top: space('Xs');
    text-align: center;
  }
  .copy {
    @include type('Copy');
    margin-top: space('S');
  }
  .callout {
    margin-top: space('S');
    .copyStrong {
      @include type('Label');
      margin-top: space('Xs');
    }
  }
  .rows {
    .row {
      padding: 16px 0;
      border-top: 1px solid $colorGrey3;
      &:last-child {
        border-bottom: 1px solid $colorGrey3;
      }
      .label {
        @include type('Label');
      }
      .value {
        @include type('Copy');
        margin-top: space('Xxs');
      }
    }
  }
  .reportDiagram {
    margin-top: space('Xs');
    .header {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xs');
      span {
        @include type('Copy');
      }
      span:last-child {
        color: $colorPrimary1;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xxs');
      span {
        @include type('Hint');
        color: $colorGrey2;
      }
      span.blue {
        color: $colorPrimary1;
      }
    }
    svg {
      margin-top: space('Xs');
    }
  }
}
