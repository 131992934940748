@import 'shared.scss';

/* QUESTIONPICTURE */
.questionPicture {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include basicQuestion;
}
