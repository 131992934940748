/**
  - Named after bootstrap model
    https://getbootstrap.com/docs/4.3/getting-started/theming/#theme-colors
  - Definitions in Wiki
    https://blueexcellence.atlassian.net/wiki/spaces/BQ/pages/884772/UI+Basics+Colors+Typography+UI+Elements+Layout+basics#Colors
  - Mostly sorted from dark to light
**/

$colors: (
  'Primary1', 'Primary2', 'Primary3', 'Primary4',
  'Secondary1', 'Secondary2', 'Secondary3',
  'Danger1', 'Danger2', 'Danger3',
  'Grey1', 'Grey2', 'Grey3', 'Grey4', 'Grey5',
  'Dark', 'Light', 'Turquoise1', 'Turquoise2',
  'Yellow1', 'Yellow2', 'Green1', 'Green2',
  'Orange1', 'Orange2', 'Blue1', 'Blue2',
  'Red1', 'Red2', 'Purple1', 'Purple2'
);

/* PRIMARY */
$colorPrimary1: #2D72FE;
$colorPrimary15: #6395FF;
$colorPrimary2: #245BCB;
$colorPrimary3: #D5E3FF;
$colorPrimary4: #F4F7FE;
$colorPrimary5: #fcfcfc;

/* SECONDARY */
$colorSecondary1: #FECA0C;
$colorSecondary2: #F0BC00;
$colorSecondary3: #FEF5D1;

/* DANGER */
$colorDanger1: #E1292B;
$colorDanger2: #B42122;
$colorDanger3: #F9D4D5;

/* GREYS */
$colorGrey1: #333333;
$colorGrey2: #999999;
$colorGrey3: #D3D3D3;
$colorGrey4: #F2F2F2;
$colorGrey5: #F5F5F5;
$colorGrey6: #FBFBFB;

  /* DARK & LIGHT */
$colorDark: #212121;
$colorLight: #FFFFFF;
$colorWhite: #FFFFFF;
$colorBlack: #000;

/* 9LEVELS COLORS */
$colorTurquoise1: #40B8B0;
$colorTurquoise2: #9FDBD7;

$colorYellow1: #FFDF3F;
$colorYellow2: #FFEF9F;

$colorGreen1: #71BC5F;
$colorGreen2: #B8DDAF;

$colorOrange1: #F4A740;
$colorOrange2: #F9D39F;

$colorBlue1: #4098CE;
$colorBlue2: #9FCBE6;

$colorRed1: #E65A50;
$colorRed2: #F2ACA7;

$colorPurple1: #A05985;
$colorPurple2: #DBC1D1;

/* CLIFTON COLORS */
$colorCliftonPurple: #6B297E;
$colorCliftonOrange: #CF711B;
$colorCliftonBlue: #3F6FC9;
$colorCliftonGreen: #539360;

$colorCliftonExecuting: $colorCliftonPurple;
$colorCliftonInfluencing: $colorCliftonOrange;
$colorCliftonRelationship: $colorCliftonBlue;
$colorCliftonStrategic: $colorCliftonGreen;

@function toTextColor($colorKey) {
  @if $colorKey == 'Primary1'
    or $colorKey == 'Primary2'
    or $colorKey == 'Danger1'
    or $colorKey == 'Danger2'
    or $colorKey == 'Grey1'
    or $colorKey == 'Grey2'
    or $colorKey == 'Dark'
  {
    @return $colorLight;
  }
  @else {
    @return $colorDark;
  }
}

/* SHADOWS */
$shadowNone: 0px 0px rgba($colorWhite, 0);
$shadowDark: 0px 2px 10px rgba(#212121, 0.1);
$shadowBlue: 0px 2px 20px rgba($colorPrimary1, 0.3);

$dropShadow: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));

/* FADES */
@mixin fades ($width, $color: #fbfbfb) {
  &::before, &::after {
    position: absolute;
    content: " ";
    top: 0;
    width: $width;
    height: 100%;
    pointer-events: none;

    z-index: 1;
  }
  // let fades break out
  &::before {
    left:0;
    background: linear-gradient(to right, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }
}


:export {
  colors: $colors;

  colorPrimary1: $colorPrimary1;
  colorPrimary2: $colorPrimary2;
  colorPrimary3: $colorPrimary3;
  colorPrimary4: $colorPrimary4;
  colorPrimary5: $colorPrimary5;

  colorDanger1: $colorDanger1;
  colorDanger2: $colorDanger2;
  colorDanger3: $colorDanger3;

  colorSecondary1: $colorSecondary1;
  colorSecondary2: $colorSecondary2;
  colorSecondary3: $colorSecondary3;

  colorGrey1: $colorGrey1;
  colorGrey2: $colorGrey2;
  colorGrey3: $colorGrey3;
  colorGrey4: $colorGrey4;
  colorGrey5: $colorGrey5;
  colorGrey6: $colorGrey6;

  colorDark: $colorDark;
  colorLight: $colorLight;

  colorTurquoise1: $colorTurquoise1;
  colorTurquoise2: $colorTurquoise2;

  colorYellow1: $colorYellow1;
  colorYellow2: $colorYellow2;

  colorGreen1: $colorGreen1;
  colorGreen2: $colorGreen2;

  colorOrange1: $colorOrange1;
  colorOrange2: $colorOrange2;

  colorBlue1: $colorBlue1;
  colorBlue2: $colorBlue2;

  colorRed1: $colorRed1;
  colorRed2: $colorRed2;

  colorPurple1: $colorPurple1;
  colorPurple2: $colorPurple2;

  shadowDark: $shadowDark;
}
