@import "shared";

.modal {
  position:fixed;
  top:0px;
  left:0px;
  width: 100%;
  height:100vh;
  z-index: zIndex('Loading');
  background-color: rgba(0, 0, 0, 0.1);

  &.opaque {
    background-color: white;

    .modalBackground {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .modalBackground {
    height: 100%;
    width: 100%;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container {
  width: 120px;
  height: 120px;
  // background: rgba(0,0,0,0.5);
}
.spinner {
  > svg {
    animation: rotate 1.5s infinite ease;
  }
}
