@import 'shared.scss';

/* GarminIndividualResult */
.garminIndividualResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  // Card component
  & > div:first-child {
    padding: space('M') 0 !important;

    @include mqSmaller($bpM) {
      padding: space('S') 0 space('M') !important;
    }
  }

  .infoTitle {
    @include mqSmaller($bpM) {
      justify-content: flex-start;
    }
  }

  .blockWithPaddings {
    padding: 0 space('M');

    @include mqSmaller($bpM) {
      padding: 0 space('Xs');
    }

    & > svg {
      margin-top: space('Xs');
    }
  }

  .additionalInfo {
    @include type('Copy');
    color: $colorPrimary1;
    margin-top: space('Xs');

    @include transition(color);
    &:hover {
      cursor: pointer;
      color: $colorPrimary2;
    }
  }

  .collapsiblePanels {
    margin-top: space('Xs');

    button {
      margin-top: space('Xs');
    }

    .collapsibleHeader {
      padding-left: space('M');
      padding-right: space('M');

      &:hover {
        background-color: $colorPrimary4;
      }

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }

    .collapsibleInnerHeader {
      display: flex;
      align-items: center;
      span {
        margin-left: space('Xxs');
      }
    }

    & > div > div:last-child {
      padding-left: space('M');
      padding-right: space('M');

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }
  }

  button {
    display: block;
    margin-left: auto;
    margin-top: space('S');
  }

  .hint {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('Xs');
  }

}

.garminIndividualResultSkeleton {
  box-sizing: border-box;
  animation: load-assessment-result 1.5s infinite ease;

  .header {
    width: 100%;
    height: 24px;
    background-color: $colorGrey4;
    border-radius: 12px;
  }
  .subHeader {
    width: 80%;
    header:20px;
    background-color: $colorGrey4;
    border-radius: 12px;

    margin-top: space('S');
  }
  .bars {
    margin-top: space('S');
    & > div:first-child {
      width: 100%;
      height: 14px;
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    & > div:last-child {
      width: 75%;
      height: 14px;
      background-color: $colorGrey4;
      border-radius: 12px;

      margin-top: space('Xxs');
    }
  }
}
