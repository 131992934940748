@import 'shared';

.profile {
  .imageBlock {
    display: flex;
    .left {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: $colorPrimary4;
      line-height: 75px;
      text-align: center;
      &.halfVisible {
        opacity: 50%;
      }
      img {
        width: 75px;
        height: 74px;
        border-radius: 50%;
      }
      .userName {
        @include type('Xxs');
        color: $colorPrimary1;
        @include ellipsis();
      }
    }
    .right {
      margin-left: space('Xs');
      word-break: break-all;
      .name {
        @include type('Xxs');
        color: $colorGrey1;
      }
      .position, .company, .deactivated, .roles {
        @include type('Copy');
        margin-top: space('Xxxs');
      }
      .roles {
        color: $colorPrimary1;
        cursor: pointer;
        @include transition(color);
        &:hover {
          color: $colorPrimary2;
        }
      }
      .deactivated {
        color: $colorPrimary1;
      }
    }
  }
  .center {
    margin-top: 25px;
    border-bottom: 1px solid $colorGrey3;
    .body {
      padding: 0 space('Xs');
      .rows {
        margin: 9px 0;
        .row {
          padding: space('Xs') space('Xxs');
          border-top: 1px solid #D8D8D8;
          .title {
            @include type('Hint');
            color: $colorGrey2;
          }
          .value {
            @include type('Copy');
            color: $colorGrey1;
            margin-top: space('Xxs');
          }
        }
      }
    }
  }
}
