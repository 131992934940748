@import 'shared';

.signUpSuccessForm {
  width: 100%;
  color: $colorGrey1;
  @include type('M');

  .subTitle {
    @include type('CopyStrong');
    color: $colorGrey1;
    padding: space('Xs') 0 space('Xs') 0;

    p:last-child {
      @include type('Hint');
    }

    a {
      @include type('Hint');
    }
  }

  button {
    display: block;
    margin-left: auto;
  }

  @include mqSmaller($bpS) {
    margin: 0 auto;
    text-align: center;
    button {
      width: 100%;
    }
  }
}
