@import 'shared.scss';

$menuItemHeight: 48px;

:export {
  menuItemHeight: $menuItemHeight;
}

//TODO
ul {
  margin: 0;
  padding: 0;
}

/* MAINNAV */
.mainNav {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-right: 1px solid $colorGrey4;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .header {
    box-sizing: border-box;
    @include headerHeight;

    display: flex;
    justify-content: space-between;
    align-items: center;


    .logo {
      padding-left: 12px;
    }

    .menuCloseIcon {
      @include iconWithBackground($colorGrey4);
      margin-right: 5px;
    }
  }

  .content {
    position: relative;
  }

  .footer {
    position: absolute;
    bottom: $spaceS;
    margin-left: space('Xs');
  }

  .menuItem {
    user-select: none;
    cursor: pointer;

    display: block;
    box-sizing: border-box;
    height: $menuItemHeight;
    padding: 15px 0px 0px 20px;

    text-decoration: none;
    color: $colorGrey1;
    @include type("Copy");

    overflow: hidden;
    background-color: $colorLight;

    @include transition(background-color);

    // &label {
    //   padding: 15px 0px 0px 20px;
    // }
    &:hover {
      background-color: $colorPrimary4;
    }
    &.highlight {
      background-color: $colorPrimary4;
    }
    &.small {
      @include type("Hint");
    }
  }
}
