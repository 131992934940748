@import 'shared.scss';

$optionsMenuSize: 36px;

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
  @include transition(transform, opacity);
}

.optionsMenu {
  position: relative;
  height: 20px;
  cursor: pointer;

  @include transition(fill);


  // INTERACTION
  &:hover {
    path {
      fill: $colorPrimary1;
    }
  }

  // MODIFIERS
  &.withBackground {
    width: $optionsMenuSize;
    height: $optionsMenuSize;
    .background {
      width: 100%;
      height: 100%;
      border: 1px solid $colorPrimary1;
      border-radius: 18px;
      padding: 10px;
      box-sizing: border-box;

      @include transition(background-color);

      &.active {
        background-color: $colorPrimary3;
      }
      &:hover {
        background-color: $colorPrimary3;
      }
    }
    svg {
      width: 14px;
      height: 14px;
      path {
        fill: $colorPrimary1;
      }
    }

    .flyout {
      width: 224px;
      top: space('Xs') + $optionsMenuSize;
      z-index: zIndex('Modal');
    }
  }

  &.withBackgroundLight, &.withBackgroundDark {
    margin-top: -8px;
    margin-right: -8px;
    height: $optionsMenuSize;
    width: $optionsMenuSize;
    border-radius: 18px;

    display: flex;
    justify-content: center;
    align-items: center;


    @include transition(background-color);

    &:hover {
      background-color: $colorPrimary4;
      path {
        fill: $colorPrimary1;
      }
    }
    &.withBackgroundDark:hover {
      background-color: $colorPrimary3;
    }

    //withBackgroundLight, withBackgroundDark
    .flyout {
      top: space('Xs') + $optionsMenuSize;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  .flyout {
    position: absolute;
    width: 257px;
    box-sizing: border-box;

    padding: 16px 0 14px;

    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: $colorWhite;
    z-index: zIndex('Buffer3');

    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }

    .title {
      @include type('Label');
      color: $colorGrey1;
      padding: 0 16px 16px;
    }
    .menuItem {
      @include type('Copy');
      color: $colorGrey1;
      padding: 16px 16px 14px;
      cursor: pointer;
      overflow: hidden;

      // transitions
      @include transition(background-color);

      &.active {
        background-color: $colorPrimary4;
      }
      &:hover {
        background-color: $colorPrimary4;
      }
    }

    @include mqSmaller($bpS) {
      position: absolute;
      width: calc(100vw - #{$spaceM} - #{$spaceXs}) !important;
    }
  }

}
