@import 'shared.scss';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
  transform: translateY(-20px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
  @include transition(transform, opacity);
}

.buttonBlock {
  margin-left: 35px;
  margin-top: 12px;

  // hide white focus border here, looks better without in this case
  button:before {
    display: none !important;
  }

  @include mqSmaller($bpS) {
    margin-top: 0;
  }
}

.quickAccessMenu {
  width: 257px;
  box-sizing: border-box;
  position: fixed;
  padding: 18px 0;
  top: 60px;
  right: 61px;
  border-radius: 10px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: $colorWhite;
  z-index: zIndex('Buffer2');

  &:focus {
    outline: none;
  }

  .menuItem {
    height: 33px;
    overflow: hidden;
    padding: 15px 0px 0px 20px;

    color: $colorGrey1;
    @include type('Copy');
    cursor: pointer;

    @include transition(background-color);

    &:hover {
      background-color: $colorPrimary4;
    }
  }

  @include mqSmaller($bpS) {
    width: calc(100% - 32px);
    box-sizing: border-box;
    top: 127px;
    right: 16px;
  }
}
