@import 'shared.scss';

/* POTENTIAL */
.potential {

  @include basicIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
