@import 'shared.scss';

/* PILLGROUP */
.pillGroup {
  position:relative;
  width: 100%;

  // fades
  &::before, &::after {
    position: absolute;
    content: " ";
    top: 0;
    width: space('Xs');
    height: 100%;
  }
  // let fades break out
  &::before {
    left: - space('Xs');
    background: linear-gradient(to right, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }
  &::after {
    right: - space('Xs');
    background: linear-gradient(to left, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }

  &.fadeWhite {
    &::before {
      left: - space('Xs');
      background: linear-gradient(to right, #fff, #fff 50%, rgba(#fff, 0));
    }
    &::after {
      right: - space('Xs');
      background: linear-gradient(to left, #fff, #fff 50%, rgba(#fff, 0));
    }
  }

  .scroll {
    margin: 0 (- space('Xs'));
    padding: 0 space('Xs');
    // give left and right padding for fades and bottom padding for a bit of air for scrollbar
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .pillContainer {
    display: flex;

    padding-right: $spaceXxs;
  }
}
