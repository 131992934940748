@import 'shared';

.wellBeingMeasurementParent {
  width: 100%;
  height:100vh;
  background-color: $colorLight;
  position: fixed;
  z-index: zIndex('Modal');
  left: 0;
  top:0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
    border-bottom: 1px $colorGrey4 solid;
    animation: slide-down .3s ease-out;
    .title {
      @include type('Label');
      color: $colorPrimary1;
      user-select: none;
    }
    .close {
      cursor: pointer;
    }
  }

  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
  }
}

.wellBeingMeasurement {
  @include basicGrid;
  margin-top: space('Xxl') + 80px; //80px - header height

  @include mqSmaller($bpS) {
    margin-top: space('Xl') + $headerHeight;
  }

  .content {
    grid-column: 5/9;

    @include mqSmaller($bpM) {
      grid-column: 3/7;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
    }

    span {
      @include type('M');
    }
    .title {
      @include type('M');
      margin-top: space('S');
    }
    .description {
      @include type('Copy');
      margin-top: space('Xs');
    }
    button {
      margin-top: space('S');
    }
  }
}

.wellbeingError {
  margin-top: space('Xxl');

  @include mqSmaller($bpS) {
    margin-top: 0;
  }
}
