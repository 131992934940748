@import 'shared';

.checkbox {
  display: block;
  position: relative;
  width: 100%;
  min-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  padding-left: 44px;
  color: $colorGrey1;
  @include type('Copy');
  .name {
    display: block;
    padding-top: 9px;
  }
  .checkmark {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 36px;
    width: 36px;
    background-color: $colorGrey4;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    @include transition(background-color, border);
    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      left: 12px;
      top: 5px;
      width: 9px;
      height: 19px;
      border: solid $colorPrimary1;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      @include transition(opacity);
    }
  }

  &.checked {
    .name {
      color: $colorPrimary1;
    }
    .checkmark {
      display: block;
      background-color: $colorGrey4;
      border: 1px solid $colorGrey4;
      &:after {
        opacity: 1;
        @include transition(opacity);
      }
    }
  }
  &:hover, &:focus {
    outline: none;
    .name {
      color: $colorPrimary1;
      @include transition(color);
    }
    .checkmark {
      background-color: $colorWhite;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      @include transition(background-color, box-shadow);
    }
  }

  &.disabled {
    .name {
      color: $colorGrey2;
    }
    .checkmark {
      background-color: $colorGrey5;
      box-shadow: none;
      &:after {
        border-color: $colorPrimary3;
      }
    }
  }
  &.error {
    .checkmark {
      box-sizing: border-box;
      border-color: $colorDanger1;
      &:after {
        border-color: $colorDanger1;
      }
    }
    .name {
      color: $colorDanger1;
    }
  }
}
