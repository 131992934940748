
// change webkit autocomplete styles
// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $colorPrimary1;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
