@import 'shared';

.diagram {
  margin-top: space('S');
  box-sizing: border-box;
  width: 100%;
}

//REPORT
.infoModalResult {
  margin-top: space('Xs');
  color: $colorGrey1;

  > div:first-child {
    margin: 0 auto;
  }
  .name {
    @include type('Xxs');
    margin-top: space('Xxs');
    text-align: center;
  }
  .labelDescription {
    @include type('Label');
    margin-top: space('Xs');
    text-align: center;
  }
  .callout1 {
    @include type('Copy');
    margin-top: space('Xs');
  }
  .reportDiagram {
    margin-top: space('Xs');
    .header {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xs');
      span {
        @include type('Copy');
      }
      span:last-child {
        color: $colorPrimary1;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xxs');
      span {
        @include type('Hint');
        color: $colorGrey2;
      }
      span.blue {
        color: $colorPrimary1;
      }
    }
    svg {
      margin-top: space('Xs');
    }
  }
  .facets {
    margin-top: space('S');
    .title {
      @include type('Label');
    }
    .facet {
      margin-top: space('S');
      .facetHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include type('Copy');
        color: $colorGrey1;

        span:last-child {
          color: $colorPrimary1
        }
      }
      svg {
        margin-top: space('Xs');
      }
      .facetFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: space('Xxs');

        @include type('Hint');
        color: $colorGrey2;
        text-transform: lowercase;
      }
    }
  }
}
