@import 'shared.scss';

$paginationButtonWidth: 40px;
$paginationMargin: 78px;

$garminIconLargeSize: 88px;
$garminIconMediumSize: 44px;

/* GarminIndividualReport */
.garminIndividualReport {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: $colorWhite;

  min-height: calc(100vh - #{$headerHeight + $footerHeight});

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  //HEADER
  .header {
    @include type('Label');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;
    }
    @include mqSmaller($bpS) {
      padding: 20px 16px;
    }
  }

  .scrollableContent {
    height: calc(100vh - #{$headerHeight});
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  .gridContent {
    @include basicGrid;
    margin-top: space('L') !important;
    margin-bottom: space('L');
    @include mqSmaller($bpS) {
      margin-top: space('M') !important;
      margin-bottom: space('Xxl') !important;
    }
  }

  .mainContentSmall {
    grid-column: 4/10;
    color: $colorGrey1;

    @include mqSmaller($bpM) {
      grid-column: 2/8;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
  .mainContentLarge {
    grid-column: 4/11;
    color: $colorGrey1;

    @include mqSmaller($bpM) {
      grid-column: 2/8;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
  .recommendationsMainContent {
    grid-column: 4/10;
    color: $colorGrey1;

    @include mqSmaller($bpM) {
      grid-column: start/end;
    }
  }

  .general {
    display: flex;
    .text {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: space('Xs');
      overflow: hidden;
    }
    & > svg {
      flex: 1;
    }

    @include mqSmaller($bpS) {
      flex-direction: column-reverse;
      .text {
        margin-top: space('Xs');
      }
      & > svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .trend {
    position: relative;
    display: flex;
    margin-top: space('L');

    & > svg {
      position: absolute;
      left: -(#{$garminIconLargeSize + $spaceXs});

      @include mqSmaller($bpM) {
        left: 0;
      }
      @include mqSmaller($bpS) {
        position: relative;
        left: unset;
      }
    }

    .left {
      width: 50%;

      @include mqSmaller($bpM) {
        margin-left: #{$garminIconMediumSize + $spaceS};
      }
      @include mqSmaller($bpS) {
        margin-top: space('S');
      }
    }

    .right {
      width: 50%;
      margin-left: space('Xs');

      @include mqSmaller($bpM) {
        margin-top: space('Xl');
        margin-left: 0;
      }
      @include mqSmaller($bpS) {
        margin-top: space('S');
        margin-left: 0;
      }

      .trends {
        display: flex;
        flex-wrap: wrap;
        margin-top: space('Xs');
        & > div:first-child {
          margin-right: space('Xs');
        }
      }

      .insight {
        width: 100%;
        margin-top: space('S');
        padding: space('Xs');
        box-sizing: border-box;

        border-radius: 8px;
        @include type('Copy');
        overflow: hidden;

        &.blue {
          background-color: $colorPrimary3;
        }
        &.red {
          background-color: $colorDanger3;
        }
      }
    }

    @include mqSmaller($bpM) {
      flex-direction: column;
      .left, .right {
        width: 100%;
      }
    }

  }

  .statistics {
    margin-top: space('L');

    .table {
      margin-top: space('Xs');

      .tHeader {
        display: flex;

        .tHeaderItem {
          @include type('Label');

          &:first-child {
            flex: 1;
          }

          &:not(:first-child) {
            width: 64px;
            max-width: 64px;
            margin-left: space('Xxs');

            white-space: pre-wrap;
          }
        }
      }

      .tRows {
        min-width: 100%;
        box-sizing: border-box;
        margin-right: space('Xs');

        &:last-child {
          margin-right: 0;
        }

        .tRow {
          display: flex;
          padding: space('Xs') 0;
          border-bottom: 1px solid $colorGrey3;

          &:first-child {
            border-top: 1px solid $colorGrey3;
          }

          & > span {
            flex: 1;
          }

          & > svg {
            margin-right: space('L');
            flex-shrink: 0;

            &:last-child {
              margin-right: 40px;
            }
          }

          @include mqSmaller($bpS) {
            & > span {
              width: 72px;
              @include ellipsis();
            }
          }
        }
      }

      // Scrollable controls
      & > div:last-child {
        & > div:last-child {
          margin-top: space('Xs');
        }
      }
    }
  }

  .legal {
    margin-top: space('L');
    & > div {
      margin-top: space('Xs');
    }
  }

}
