@import 'shared';

html {
  scroll-behavior: smooth;
}

.organisationSettings {
  grid-column: 1/13;
  @include basicGridNoMargins;
  .main {
    grid-column: 1/7;
    .title {
      @include type('Xxs');
      color: $colorGrey1;
    }
    .description {
      @include type('Copy');
      color: $colorGrey1;
      margin-top: space('Xs');
    }
    .content {
      margin-top: space('M');

      a {
        display: block;
        margin-top: space('Xs');
      }
    }

    .error {
      @include type('Copy');
      color: $colorDanger1;
    }
    .paddingTopS {
      padding-top: space('S');
    }

    .registration, .logo {
      margin-top: space('L');
      .title {
        @include type('Xxs');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs');
      }
      .domains {
        margin-top: space('S');
        .domainsTitle {
          @include type('Label');
        }
      }
    }

    .modules, .assessments {
      margin-top: space('L');
      .title {
        @include type('Xxs');
        color: $colorGrey1;
      }
      .description {
        @include type('Copy');
        color: $colorGrey1;
        margin-top: space('Xs');
      }
      .rows {
        margin-top: space('Xs');
        .row {
          padding: space('Xs');
          box-sizing: border-box;
          border-top: 1px solid $colorGrey4;
          border-bottom: 1px solid $colorGrey4;
          @include type('Copy');
          color: $colorGrey1;
          &:last-child {
            border-top: none;
          }
          .row1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .row2 {
            color: $colorGrey2;
            margin-top: space('Xxs');
            padding-right: 100px;
            box-sizing: border-box;
          }
          .row3 {
            margin-top: space('Xs');
          }
        }
      }
    }

  }

  @include mqSmaller($bpS) {
    .main {
      grid-column: start/end;
    }
  }
}
